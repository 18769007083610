import { useUser } from '@clerk/clerk-react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { emailPattern } from '../../../../utils/String';
import Switch from '../../../functionals/Switch/Switch';
import Input from '../../../inputs/TextInput/TextInput';
import { InputContainer } from '../../../inputs/TextInput/TextInputElements';
import {
  TwoColumnGridContainer,
  TwoColumnGridWithMargin,
} from '../../Containers/GridContainer/TwoColumnsGridContainer/TwoColumnsGridContainer';
import { ErrorText } from '../../texts/TextsElements';
import {
  InputWithError,
  StyledForm,
  StyledPhoneInput
} from './CompanyDataFormElements';

const CompanyDataForm = forwardRef(
  ({ tmpUserRef, submitForm, submitRef, formWidth, resetForm }, ref) => {
   
    const [hasCompany, setHasCompany] = useState(false)




    const {
      register,
      handleSubmit,
      formState: { errors },
      control,
      setValue,
      
    } = useForm({
      defaultValues: {
        name: '',
        iva: '',
        phone: '',
        email: '',
      },
      mode: 'all',
    });

   
    useEffect(() => {
      window.addEventListener('error', (e) => {
        if (
          e.message ===
          'ResizeObserver loop completed with undelivered notifications.'
        ) {
          const resizeObserverErrDiv = document.getElementById(
            'webpack-dev-server-client-overlay-div'
          );
          const resizeObserverErr = document.getElementById(
            'webpack-dev-server-client-overlay'
          );

          if (resizeObserverErr) {
            resizeObserverErr.setAttribute('style', 'display: none');
          }
          if (resizeObserverErrDiv) {
            resizeObserverErrDiv.setAttribute('style', 'display: none');
          }
        }
      });
    }, []);

    useEffect(() => {
      if (tmpUserRef?.current?.company) {
        setHasCompany(true)
        setValue('name', tmpUserRef.current?.company?.name);
        setValue('iva', tmpUserRef.current?.company?.iva);
        setValue('phone', tmpUserRef.current?.company?.phone);
        setValue('email', tmpUserRef.current?.company?.email);
        
      }
      
    }, [setValue, tmpUserRef, resetForm]);

    
    return (
      <StyledForm
        ref={ref}
        $width={formWidth}
        onSubmit={handleSubmit(async (data) => {
        
       
         
          submitForm({company: data});
        })}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Switch labelLeft={"Hai un'azienda?"} checked={hasCompany} onChange={()=>{setHasCompany(!hasCompany)}} />
          {hasCompany && <TwoColumnGridWithMargin>
          
            <TwoColumnGridContainer>      
              <InputWithError>
                <Input
                  type='text'
                  placeholder='Denominazione'
                  {...register('name', {
                    required: 'La denominazione è obbligatorio',
                  })}
                  disabled={!hasCompany}
                  
                  $active={!!errors?.name?.message}
                />
                
                
                {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
              </InputWithError>
              <InputWithError>
                <Input
                  type='text'
                  placeholder='P.IVA'
                  {...register('iva', {
                    required: 'La partita IVA è obbligatorio',
                  })}
                  disabled={!hasCompany}
                  $active={!!errors?.iva?.message}
                />
                {errors.iva && (
                  <ErrorText>{errors.iva.message}</ErrorText>
                )}
              </InputWithError>
              <InputWithError>
                <InputContainer>
                  <Controller
                    control={control}
                    name='phone'
                    rules={{
                      required: 'Il numero di telefono è obbligatorio',
                      validate: (value) =>
                        isValidPhoneNumber(value) ||
                        'Inserisci un numero di telefono valido',
                    }}
                    disabled={!hasCompany}
                    render={({ field }) => (
                      <StyledPhoneInput
                        {...field}
                        value={field.value}
                        defaultCountry='IT'
                        placeholder='Numero di telefono aziendale'
                        onChange={(phone) => field.onChange(phone)}
                        $active={!!errors?.phone?.message}
                      />
                    )}
                  />
                </InputContainer>

                {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
              </InputWithError>
              <InputWithError>
              <Input
                  type='email'
                  placeholder='Email aziendale'
                  {...register('email', {
                    required: "E' obbligatorio inserire una mail aziendale",
                    pattern: {
                      value: emailPattern,
                      message: errors?.email?.message,
                    },
                  })}
                  disabled={!hasCompany}
                  $active={!!errors?.email?.message}
                />
                {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
              </InputWithError>
              
            </TwoColumnGridContainer>
          </TwoColumnGridWithMargin>}
          <button ref={submitRef} type='submit' style={{ display: 'none' }} />
        </div>
      </StyledForm>
    );
  }
);

export default CompanyDataForm;
