import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PasswordInputContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 20px;
  background-color: #f0efff;
  width: 100%;
  height: 50px;
  font-size: 16px;
  position: relative;
  margin: 10px 0;
`;

export const Input = styled.input`
  background-color: #f0efff;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: normal;
  text-align: left;
  color: #a7a3ff;
  outline: none;
  &::placeholder {
    color: #a7a3ff;
  }
  &:focus {
    border: 1px solid #a7a3ff;
  }
  ${({ $active }) =>
    $active &&
    `
    border: 1px solid #bf1650;
    background: #FFE5E5;
    &::placeholder {
      color: #F00;
    }
  `}
`;
export const TogglePasswordVisibilityButton = styled(FontAwesomeIcon)`
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 10px;
`;
