import { AWS } from "../config/URLs";

async function newMusician(user, jwtToken, errF, succF) {
  const requestObject = {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
    body: JSON.stringify(user),
  };

  fetch(AWS + "/musicians/new", requestObject)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((res) => {
      succF(res);
    })
    .catch((err) => errF(err));
}

async function getMusician(user) {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      //Authorization: jwtToken,
    }),
  };

  try {
    let response = await fetch(AWS + "/musicians/get/" + user, requestObject);
    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getMusicians() {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      //Authorization: jwtToken,
    }),
  };

  try {
    let response = await fetch(AWS + "/musicians", requestObject);
    if (response.ok) {
      const result = await response.json();
      console.log("RESULT: ",result)
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function updateMusician(data, jwtToken, errF, succF) {
  const requestObject = {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
    body: JSON.stringify(data),
  };

  fetch(AWS + "/musicians/update", requestObject)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((res) => {
      succF(res);
    })
    .catch((err) => errF(err));
}

async function validateMusician(user, jwtToken) {
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwtToken,
      }),
    };

    let response = await fetch(
      AWS + "/musicians/" + user + "/validate",
      requestObject
    );
    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function disableMusician(user, jwtToken) {
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwtToken,
      }),
    };

    let response = await fetch(
      AWS + "/musicians/" + user + "/disable",
      requestObject
    );
    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

const uploadImage = async (image, name) => {
  try {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("name", name);

    const config = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(
      "https://www.wespread.it/tmp/eventia/uploadImg.php",
      config
    );

    if (response.ok) {
      const data = await response.json();
      console.log(data);
      return data;
    } else {
      throw new Error("Errore prima");
    }
  } catch (error) {
    console.error("Errore:", error);
    throw error;
  }
};

export {
  newMusician,
  getMusician,
  getMusicians,
  updateMusician,
  validateMusician,
  disableMusician,
  uploadImage,
};
