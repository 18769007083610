import styled from 'styled-components';

export const VideoDropzoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  border-radius: 30px;
  background-color: #f0efff;
  color: #333;
  cursor: pointer;
  transition: border-color 0.3s ease;
  min-height:400px;
  &:hover {
    border-color: #007bff;
  }
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Aggiunta ombra */
  position: relative;
`;

export const CentralContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

`

export const SquareBox = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 30px;
  border: 2px dashed black; /* Bordo nero tratteggiato */
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.div`
  font-size: 48px;
  margin-bottom: 10px;
`;

export const Description = styled.p`
  margin-top: 10px;
  font-size: 14px;
`;

export const SuccessMessage = styled.p`
  margin-top: 10px;
  font-size: 14px;
  color: green; /* Colore del messaggio di successo */
`;

export const VideoPreview = styled.video`
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 30px;

`;


export const Overlay = styled.div`
position: absolute;
top: 0;
right: 0;
color: white;
padding: 5px 10px;
border-bottom-left-radius: 10px;
z-index: 1; /* Imposta un indice Z maggiore per sovrapporre all'elemento video */
`;