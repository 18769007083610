import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  justify-items: center;

  @media (min-width: 1300px) {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }
`;

export const CardContainer = styled.div`
  width: 250px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 10px;
  margin-block: 10px;
`;

// Define some styles for the profile picture
export const ProfilePicture = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 20px;
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${({$color}) => $color || '#333'};
  margin-bottom: 10px;
  font-size: 20px;
  cursor: pointer;
`;

// Define some styles for the name
export const Name = styled.h4`
  color: #333;
  margin-block: 10px;
  font-style: normal;
  font-weight: 500;
`;

export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-block: 5px;
`;

export const RatingIcon = styled(FontAwesomeIcon)`
  color: #f1c40f;
  width:20px;
  margin-right: 5px;
`;

export const RatingText = styled.span`
  color: #333;
  font-size: 16px;
  margin-right: 5px;
`;

// Define some styles for the instruments
export const Instruments = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  flex-wrap: wrap;
`;

// Define some styles for each instrument icon
export const InstrumentIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-inline: 10px;
  padding-bottom: 10px;
`;

// Define some styles for the genres
export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;


  @media (max-width: 768px) {
    display: ${({$display}) => $display || 'block'};
    justify-content: center;
  }
`;

// Define some styles for each genre tag
export const Tag = styled.span`
  color: ${({$color}) => $color || '#a7a3ff'};
  border-radius: 10px;
  background-color: ${({$bgColor}) => $bgColor || '#f0efff'};
  padding: 5px 10px;
  margin: 5px;
  font-size: 15px;
  display: flex;
  align-items: center;
  cursor: ${({$cursor}) => $cursor || 'initial'};
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
`;

export const TagIcon = styled.img`
  width: ${({$width}) => $width || '16px'};
  height: ${({$height}) => $height || '16px'};
  padding-right: ${({$pr}) => $pr || '5px'};
  padding-top: ${({$pt}) => $pt || '3px'};
`;