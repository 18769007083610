import { useState } from 'react';
import { HeaderContainer, HeaderContent, TabText } from './TabBarElements';

export const TabBar = ({ tabs, onClick, position, top }) => {
  const lastString = window.location.pathname.split('/')[2];
  console.log('lastString: ', lastString);

  const [selectedTabId, setSelectedTabId] = useState(
    lastString ? lastString : tabs[0].id
  );

  return (
    <HeaderContainer $position={position} $top={top}>
      <HeaderContent>
        {tabs.map((x, i) => (
          <TabText
            key={x.id}
            $isSelected={selectedTabId === x.id}
            onClick={() => {
              setSelectedTabId(x.id);
              onClick(x.id);
            }}
          >
            {x.label}
          </TabText>
        ))}
      </HeaderContent>
    </HeaderContainer>
  );
};

export default TabBar;
