





import React, { useState } from 'react';

import BillingAccountForm from './BillingAccountForm';

import Popup from '../Popup/Popup';

import SecondaryButton from '../../presentationals/buttons/SecondaryButton/SecondaryButton';





const StripePayment = ({
    disabled,
    productId,
    userId,
    jwtToken,
    subscriptionName,
    subscriptionPrice
}) => {

    const [open, setOpen] = useState(false);


    
    return(
        <div style={{width:"100%"}}>
            {open && <Popup
                title={''}
                content='Contenuto del Popup'
                confirmButtonLabel=''
                onClose={()=>{setOpen(false)}}
                textAlign='center'
                disabled={false}
            >
                <BillingAccountForm 
                    userId={userId}
                    jwtToken={jwtToken}
                    subscriptionId={productId}
                    subscriptionName={subscriptionName}
                    subscriptionPrice={subscriptionPrice}
                />
            </Popup>}
            {!open && <SecondaryButton style={{width:"100%", marginTop:10}} onClick={()=>setOpen(true)} disabled={disabled}> 
                Fai un upgrade al tuo piano
                </SecondaryButton>}
        </div>
    )

}

export default StripePayment;


