import styled from 'styled-components';

export const PlaceSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 20px;
  background-color: transparent;
  width: 100%;
  min-width: ${({ $minWidth }) => !!!$minWidth && '200px'};
  height: 50px;
  font-size: 16px;
  position: relative;
  margin: 10px 0;
`;

export const PlaceSelectorBox = styled.input`
  background-color: ${({ $bgColor }) => $bgColor || 'transparent'};
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: normal;
  text-align: left;
  font-family: Raleway Regular;
  color: ${({ $color }) => ($color ? $color : '#9a84bc')};
  outline: none;
  border: ${({ $border }) => ($border ? $border : '1px solid transparent')};
  &::placeholder {
    color: ${({ $color }) => ($color ? $color : '#9a84bc')};
    font-family: Raleway Regular;
  }
  &:focus {
    border: none; 
    // ${({ $border }) => ($border ? $border : '1px solid #9a84bc')};
  }
  ${({ $active }) => $active && `
    border: 1px solid #bf1650;
    background: #FFE5E5;
    &::placeholder {
      color: #F00;
      font-family: Raleway Regular;
    }
  `}
`;

export const SuggestionsList = styled.ul`
  position: absolute;
  top: 60px; /* Adjust the top position according to your design */
  left: 0;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #a7a3ff;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index:999;
`;

export const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  font-family: 
  border-radius: 20px;
  font-family: Raleway Regular;
  &:hover {
    background-color: #f0efff;
  }
`;