import { useSignUp } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TitledContainer from "../../components/presentationals/Containers/TitledContainer/TitledContainer";
import { HorizontalLayout } from "../../components/presentationals/Containers/layouts/HorizontalLayout/HorizontalLayout";
import SelectableSquareButton from "../../components/presentationals/buttons/SelectableSquareButton/SelectableSquareButton";
import SignupForm from "../../components/presentationals/forms/SignupForm/SignupForm";
import { MUSICIAN, PLANNER } from "../../config/profile/Roles";
import { Heading, Subheading } from "../../screens/home/HomeElements";
import errorMessages from "../../utils/ClerkUtilities/ErrorsMessages";
import HSplitScreen from "../../components/presentationals/Containers/layouts/HSplitScreen/HSplitScreen";
import Images from "../../utils/Images";

export default function SignUpForm() {
  const { isLoaded, signUp } = useSignUp();
  const [errorMsg, setErrorMsg] = useState("");

  const [type, setType] = useState(null);
  const navigate = useNavigate();

  

  useEffect(()=>{

    const urlParams = new URLSearchParams(window.location.search);
    const tParam = urlParams.get('t');
    if (tParam)
      setType(tParam)

  },[])

  // start the sign up process.
  const signUpPlanner = async ({ email: emailAddress, password, confirmPassword }) => {
    if (!isLoaded) {
      return;
    }

    if (password !== confirmPassword) {
      setErrorMsg(errorMessages.form_passwords_do_not_match);
      return;
    }

    try {
      let user = await signUp.create({
        emailAddress,
        password,
      });

      console.log("User signd up: ", user);

      // send the email.
      await signUp.prepareEmailAddressVerification({ strategy: "email_code" });

      // change the UI to our pending section.
      navigate("/auth/confirm?type=" + PLANNER, {
        replace: true,
      });
    } catch (err) {
      setErrorMsg(errorMessages[err.errors[0].code]);
    }
  };

  const signUpMusician = async ({ email: emailAddress, password, confirmPassword }) => {
    if (!isLoaded) {
      return;
    }

    try {
      let user = await signUp.create({
        emailAddress,
        password,
      });

      // send the email.
      await signUp.prepareEmailAddressVerification({ strategy: "email_code" });

      // change the UI to our pending section.
      navigate("/auth/confirm?type=" + MUSICIAN, {
        replace: true,
      });
    } catch (err) {
      setErrorMsg(errorMessages[err.errors[0].code]);
    }
  };

  // This verifies the user using email code that is delivered.

  return (
    <>
      <TitledContainer centered >
        <div style={{ width: "100%", display: 'flex', alignItems:'center', justifyContent:'center', flexWrap: 'wrap', alignItems: 'center' }}>
            <Subheading style={{ margin: '0', display: 'flex', alignItems: 'center' }}>
              Prendi posto in <img src={Images.logo.orchestra} style={{ height: '30px', marginLeft: '5px' }} />:
            </Subheading>
            <Subheading style={{ margin: '0', marginLeft: '10px' }}>
              sostieni la musica!
            </Subheading>
        </div>
        <HSplitScreen
                    width='100%'
                    outerMargin='0'
                    
                    left={<>
                    <SelectableSquareButton
                                width={"80%"}
                                selected={type === PLANNER}
                                // imageSrc={Images.icons.planner}
                                label={"Sei un "+PLANNER.toLowerCase()+"?"}
                                onClick={() => setType(PLANNER)}
                    />
                    </>}
                    right={<><SelectableSquareButton
                      width={"80%"}
                      selected={type === MUSICIAN}
                      // imageSrc={Images.icons.musician}
                      label={"Sei un "+MUSICIAN.toLowerCase()+"?"}
                      onClick={() => setType(MUSICIAN)}
                    />
                    
                    </>}
                  />
        
        
      </TitledContainer>
      <div>
        {type === MUSICIAN && (
          <SignupForm
            onSignin={() => navigate("/auth/signin")}
            handleSignUpSubmit={signUpMusician}
            errorMsg={errorMsg}
          />
        )}
        {type === PLANNER && (
          <SignupForm
            onSignin={() => navigate("/auth/signin")}
            handleSignUpSubmit={signUpPlanner}
            errorMsg={errorMsg}
          />
        )}
      </div>
    </>
  );
}
