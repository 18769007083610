import Images from '../../../utils/Images';
import HorizontalBar from '../bars/HorizontalBar/HorizontalBar';
import { Title1, SmallText, TextLink } from '../texts/TextsElements';
import { DesktopDiv } from './FooterElements';

export const Footer = (props) => {

  
  return (
    <HorizontalBar
      spaceBetween={true}
      margin='1% 5%'
      bgColor='white'
      color='black'
      direction='column'
      mediaQuery='720'
      padding='20px'
    >
      <DesktopDiv>
        <img src={Images.logo.orchestra} style={{ height: '20px' }} />
      </DesktopDiv>
      <SmallText $textColor={"#000"}>© 2024 Orchestra S.R.L. C.F. 13632910967 - P.IVA IT13632910967</SmallText>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
      >
        {/* <TextLink href={"/cookies"} target={"_blank"}  $textColor={"#000"} $textAlign='center'>Cookies</TextLink> */}
        <TextLink href={"/terminiCondizioni"} target={"_blank"}  $textColor={"#000"} $textAlign='center'>Termini e condizioni</TextLink>
        <TextLink href={"/datiPersonali"} target={"_blank"}  $textColor={"#000"} $textAlign='center'>Informativa sul trattamento dei dati personali</TextLink>
      </div>
    </HorizontalBar>
  );
};

export default Footer;
