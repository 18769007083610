import { ClerkProvider } from "@clerk/clerk-react";
import "./App.css";

import MainRouter from "./MainRouter";
import { StateProvider } from "./stores/StateProvider";
import rootReducer from "./stores/reducers/Index";
import InitialState from "./stores/states/InitialState";

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

function App() {
  return (
    <div className="App">
      <StateProvider reducer={rootReducer} initialState={InitialState}>
        <ClerkProvider publishableKey={clerkPubKey}>
          <MainRouter />
        </ClerkProvider>
      </StateProvider>
    </div>
  );
}

export default App;
