import HorizontalBar from '../../bars/HorizontalBar/HorizontalBar';
import {
  AbsoluteContainer,
  Container,
  Content,
} from './TitledContainerElements';
import { Title1, Text, Header1 } from '../../texts/TextsElements';

const TitledContainer = ({
  header,
  title,
  subtitle,
  sideComponent,
  centered,
  textAlign,
  fullWidth,
  children,
  absoluteContainer,
  margin,
  titleMargin,
  contentDisplay,
  mobileAlign,
  textColor,
  mainPadding,
  boxShadow,
  mobileMargin,
  bkgColor
}) => (
  <Container $bkgColor={bkgColor} $centered={centered} $margin={margin} $mobileAlign={mobileAlign} $mobileMargin={mobileMargin} $padding={{mainPadding}} $boxShadow={boxShadow}>
    
    {header && <Header1 $textColor={textColor}>{header}</Header1>}
    {sideComponent && (
      <HorizontalBar padding={"0px 0px 30px 0px"} spaceBetween={true}>
        <Title1 $textColor={textColor} $textAlign={textAlign} $margin={titleMargin}>
          {title}
        </Title1>
        {sideComponent}
      </HorizontalBar>
    )}
    {absoluteContainer ? (
      <AbsoluteContainer>
        {!sideComponent && (
          <Title1 $textColor={textColor} $textAlign={textAlign} $margin={titleMargin}>
            {title}
          </Title1>
        )}
        {subtitle && <Text $textColor={textColor} $textAlign={textAlign}>{subtitle}</Text>}
      </AbsoluteContainer>
    ) : (
      <>
        {!sideComponent && (
          <Title1 $textColor={textColor} $textAlign={textAlign} $margin={titleMargin}>
            {title}  
          </Title1>
        )}
        {subtitle && <Text $textColor={textColor} $textAlign={textAlign}>{subtitle}</Text>}
      </>
    )}
    <Content $display={contentDisplay} $fullWidth={fullWidth}>
      {children}
    </Content>
  </Container>
);

export default TitledContainer;
