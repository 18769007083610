import { AWS } from "../config/URLs";

async function newRequest(request, jwtToken) {
  const requestObject = {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
    body: JSON.stringify(request),
  };

  const response = await fetch(AWS + "/requests/new", requestObject);
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
}

async function getRequest(id, jwtToken) {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
  };

  const response = await fetch(AWS + "/requests/" + id, requestObject);
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
}

async function getRequests(jwtToken) {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
  };

  const response = await fetch(AWS + "/requests", requestObject);
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
}

async function acceptRequest(id, jwtToken) {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
  };

  const response = await fetch(
    AWS + "/requests/" + id + "/accept",
    requestObject
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
}

async function rejectRequest(id, jwtToken) {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
  };

  const response = await fetch(
    AWS + "/requests/" + id + "/reject",
    requestObject
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
}

async function confirmRequest(id, jwtToken) {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
  };

  const response = await fetch(
    AWS + "/requests/" + id + "/confirm",
    requestObject
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
}

async function cancelRequest(id, jwtToken) {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
  };

  const response = await fetch(
    AWS + "/requests/" + id + "/cancel",
    requestObject
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
}

async function addPreventive(requestId, preventive, extra, jwtToken) {
  const requestObject = {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
    body: JSON.stringify({ preventive: preventive, extra: extra }),
  };

  const response = await fetch(
    AWS + "/requests/" + requestId + "/preventive",
    requestObject
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
}

export {
  newRequest,
  getRequest,
  getRequests,
  acceptRequest,
  rejectRequest,
  cancelRequest,
  addPreventive,
  confirmRequest,
};
