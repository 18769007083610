import {
  useClerk,
  useUser,
} from "@clerk/clerk-react";
import Slider from 'rc-slider';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Popup from '../../components/functionals/Popup/Popup';
import { TwoColumnGridAnnouncementContainer, TwoColumnGridContainer, TwoColumnGridWithMargin } from '../../components/presentationals/Containers/GridContainer/TwoColumnsGridContainer/TwoColumnsGridContainer';
import HorizontalBar from '../../components/presentationals/bars/HorizontalBar/HorizontalBar';
import DeletableButton from '../../components/presentationals/buttons/DeletableButton/DeletableButton';
import SecondaryButton from '../../components/presentationals/buttons/SecondaryButton/SecondaryButton';
import AnnouncementCard from "../../components/presentationals/cards/AnnouncementCard/AnnouncementCard";
import { GridContainer, TagIcon } from '../../components/presentationals/cards/MainCard/MainCardElements';
import SelectableCard from '../../components/presentationals/cards/SelectableCard/SelectableCard';
import {
  SmallText, Text
} from '../../components/presentationals/texts/TextsElements';
import { AnnouncementStates } from "../../config/AnnouncementConsts";
import { ADMIN } from '../../config/profile/Roles';
import genres from '../../data/genres';
import musicalInstruments from '../../data/instruments';
import Announcement from "../../models/Announcement";
import { newAnnouncement } from "../../remote/announcements";
import { useStateValue } from '../../stores/StateProvider';
import SignInPopup from "../../userManagement/signIn/signInPopup";
import getToken from '../../userManagement/utilities';
import { haversineDistance } from '../../utils/Geolocalization';
import Loader from "../../utils/Loader";

function Applications({applications}) {
  const [{userData}] = useStateValue();
  const [place, setPlace] = useState('');
  const [reset, setReset] = useState(false);

  const [date, setDate] = useState('');
  const [loadingAnnouncements,setLoadingAnnouncements] = useState(true)
  const [adding,setAdding] = useState(false)

  const [unfilterData,setUnfilterData] = useState();

  const [dateR, setDateR] = useState('');

  const [maxDistance, setMaxDistance] = useState(80);

  const [data, setData] = useState([]);
  const [closeData, setCloseData] = useState([]);

  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showClosest, setShowClosest] = useState(false)

  const [newAnnouncementPopup, setNewAnnouncementPopup] = useState(false)

  const [actionOpen,setActionOpen] = useState(-1)
  
  const announcementFormRef = useRef()
  const announcementSubmitFormRef = useRef()

  const dateRef = useRef();
  dateRef.current = date;
  const dateRRef = useRef();
  dateRRef.current = dateR;
  const placeRef = useRef();
  placeRef.current = place;

  const [notActiveFilter, setNotActiveFilter] = useState(
    userData?.role == ADMIN
  );

  const { session } = useClerk();
  const { isSignedIn } = useUser();


  const updateData = async () => {

    // let t = await getToken(session)
    // let announcements = await getMyApplications(t);
  
    
    let announcements = applications

    console.log('Applications', announcements);
    setUnfilterData(announcements);
    
    const urlParams = new URLSearchParams(window.location.search);
    const placeParam = urlParams.get('place');
    const dateParam = urlParams.get('date');

    let p = null;

    if (placeParam) {
      p = JSON.parse(decodeURIComponent(placeParam));
      setPlace(p);
    }

    if (dateParam) {
      setDate(dateParam);
      setDateR(new Date(dateParam));
    }

    

    if (placeParam && p != null) {
      setData(
        announcements.filter(
          (x) =>{

            console.log("X.LOCATION: ",x.location.raw.osm_id);
            console.log("p.raw.osm_id: ",p.raw.osm_id)
            return x.location &&
            (x.location.raw.osm_id === p.raw.osm_id ||
              filterCloseCities(x.location, p, 0, 10))
            
          }
        )
      );

      setCloseData(
        announcements.filter(
          (x) =>
            x.location &&
            x.location.raw.osm_id !== p.raw.osm_id &&
            filterCloseCities(x.location, p,10,maxDistance)
        )
      );
    } else {
      setData(
        announcements.map((x) => {
          x.close = false;
          return x;
        })
      );
      setCloseData([]);
    }
    setLoadingAnnouncements(false)
  };

 
  
  

  useEffect(() => {
    updateData();
  }, [window.location.href,applications]);

  useEffect(() => {
    if(reset){
      updateData();
      setReset(false)
    }
  }, [reset]);

  useEffect(()=>{
    if (unfilterData && place){
      setCloseData(
        unfilterData.filter(
          (x) =>
            x.location &&
            x.location.raw.osm_id !== place.raw.osm_id &&
            filterCloseCities(x.location, place,10,maxDistance)
        )
      );
      }
  },[maxDistance])


  useEffect(() => {
    if (isSignedIn) setShowLoginPopup(false);
  }, [isSignedIn]);

  const filterCloseCities = (cityA, cityB,minD,maxD) => {
   
    const distance = haversineDistance(cityA.y, cityA.x, cityB.y, cityB.x);

    // Considera gli utenti entro 10 km dalla città target
    return distance>=minD && distance <= maxD;
  };

  const navigate = useNavigate();

  const [limit, setLimit] = useState(6);

  const [instrumentPopup, setInstrumentPopup] = useState(false);

  const [genrePopup, setGenrePopup] = useState(false);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedInstruments, setSelectedInstruments] = useState([]);
  const heightMarks = {
    10: '10Km',
    
    20: '20Km',
    30: '30Km',

    40: '40Km',

    50: '50Km',

    60: '60Km',

    70: '70Km',

    80: '80Km'
  };


  const filterDataByGenres = (item) => {
    if (selectedGenres.length > 0) {
      return item.genres.find((x) => selectedGenres.includes(x.id));
    }

    return true;
  };

  const filterDataByInstruments = (item) => {
    if (selectedInstruments.length > 0) {
      return item.instruments.find((x) => selectedInstruments.includes(x.id));
    }

    return true;
  };

  const filterData = (item) => {
    return (
      
      filterDataByGenres(item) &&
      filterDataByInstruments(item)
    );
  };

 
  const toggleInstrument = (instrument) => {
    console.log('Instrument: ', instrument);

    let s = selectedInstruments;

    console.log('s: ', s);

    if (selectedInstruments.includes(instrument))
      s = selectedInstruments.filter((x) => x !== instrument);
    else if (selectedInstruments.length<5) {
      s = selectedInstruments.concat([instrument]);
    }

    console.log('S: ', s);

    setSelectedInstruments(s);
  };

  const toggleGenre = (genre) => {
    let s = selectedGenres;

    console.log('s: ', s);

    if (selectedGenres.includes(genre))
      s = selectedGenres.filter((x) => x !== genre);
    else if (selectedGenres.length<3){
      s = selectedGenres.concat([genre]);
    }

    console.log('S: ', s);

    setSelectedGenres(s);
  };

  



  return (
    <>
      {showLoginPopup && (
        <SignInPopup setShowLoginPopup={() => setShowLoginPopup(false)} />
      )}
      
      {/* {/* <HorizontalBar spaceBetween={true} margin='30px 0 0 0'>
        <SearchBar
          onSearch={(place, date) => {
            if (place)
              navigate('/announcements?place=' + JSON.stringify(place));
            else navigate('/announcements');
          }}
          disabledDate={true}
          place={place}
          date={date}
        />
      </HorizontalBar>

      
        <HorizontalBar margin='30px 0px 20px 0px' gap='20px' spaceBetween direction='column' mediaQuery={786}>
          <div style={{ display: 'flex', gap: '20px' }}>
            {selectedInstruments.length > 0 ? (
              <DeletableButton
                padding='12px 40px 12px 20px'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                }}
                onDelete={() => setSelectedInstruments([])}
              >
                {selectedInstruments
                  .map((x, i) => (
                    <span key={i}>{musicalInstruments[x].label}</span>
                  ))
                  .reduce((prev, curr, i) => [prev, ', ', curr])}
              </DeletableButton>
            ) : (
              <SecondaryButton onClick={() => setInstrumentPopup(true)}>
                Strumenti
              </SecondaryButton>
            )}

            {selectedGenres.length > 0 ? (
              <DeletableButton
                padding='12px 40px 12px 20px'
                onDelete={() => setSelectedGenres([])}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                }}
              >
                {selectedGenres
                  .map((x) => <span style={{}}>{genres[x].label}</span>)
                  .reduce((prev, curr, i) => [prev, ', ', curr])}
              </DeletableButton>
            ) : (
              <SecondaryButton onClick={() => setGenrePopup(true)}>
                Generi
              </SecondaryButton>
            )}

            {userData?.role == ADMIN && !notActiveFilter && (
              <SecondaryButton onClick={() => setNotActiveFilter(true)}>
                Filtra non attivi
              </SecondaryButton>
            )}

            {userData?.role == ADMIN && notActiveFilter && (
              <DeletableButton onClick={() => setNotActiveFilter(false)}>
                Vedi tutti
              </DeletableButton>
            )}
          </div> 
          {(place || date || !!selectedGenres.length || !!selectedInstruments.length) &&  (
            <div>
              <SecondaryButton
                onClick={() => {
                  setDate('');
                  setDateR('');
                  setPlace('');
                  setCloseData([]);
                  setShowClosest(false);
                  setLoadingAnnouncements(true);
                  setSelectedGenres([])
                  setSelectedInstruments([])
                  setMaxDistance(80);
                  setReset(true);
                  navigate('/announcements');
                }}
              >
                Reset
              </SecondaryButton>
            </div>
          )}
        </HorizontalBar> */}
     
      {showClosest && (
        <HorizontalBar
          margin='0px 0px 40px 0px'
          gap={'40px'}
          displayResponsive='block'
          mediaQuery={'768'}
          direction={'column'}
        >
          <SecondaryButton disabled>Nelle vicinanze</SecondaryButton>
          <Slider
            defaultValue={maxDistance}
            min={10}
            max={80}
            step={10}
            onChange={(e) => {
              setMaxDistance(e);
            }}
            styles={{
              track: {
                backgroundColor: '#a7a3ff',
                height: '5px',
              },
            }}
            activeDotStyle={{
              backgroundColor: '#a7a3ff',
              borderColor: '#f0efff',
            }}
            // trackStyle={{ backgroundColor: "#a7a3ff", }}

            marks={heightMarks}
            dots
          />
        </HorizontalBar>
      )}

      <HorizontalBar margin='30px 0px 20px 0px' spaceBetween={true}>
        <SmallText>
          <span style={{ fontWeight: 'bold' }}>
            {!showClosest && data.filter((x) => filterData(x)).length }
            {showClosest && closeData.filter((x) => filterData(x)).length }
          </span>{' '}
          annunci disponibili - {' '}
          <span style={{ fontWeight: 'bold' }}>
            {!showClosest && Math.min(...[limit,data.filter((x) => filterData(x)).length])}
            {showClosest && Math.min(...[limit,closeData.filter((x) => filterData(x)).length])}
          </span>{' '}
          {!showClosest && " mostrati"}
          {showClosest && " mostrati"}

         
          {showClosest && `a ${maxDistance} Km da ${place?.raw.name} `}
        </SmallText>
       
      </HorizontalBar>
      {instrumentPopup && (
        <Popup
          title={'Scegli fino a 5 strumenti'}
          onClose={() => setInstrumentPopup(false)}
        >
          <TwoColumnGridContainer>
            {musicalInstruments.map((x) => (
              <SelectableCard
                selected={selectedInstruments.includes(x.id)}
                onClick={() => toggleInstrument(x.id)}
                key={x.id}
              >
                <TagIcon src={x.img} alt={x.name} $pt={'0'} />
                <p>{x.label}</p>
              </SelectableCard>
            ))}
          </TwoColumnGridContainer>
        </Popup>
      )}

      {genrePopup && (
        <Popup
          title={'Scegli il genere'}
          content='Contenuto '
          onClose={() => setGenrePopup(false)}
        >
          <TwoColumnGridContainer>
            {genres.map((x) => (
              <SelectableCard
                selected={selectedGenres.includes(x.id)}
                onClick={() => toggleGenre(x.id)}
                key={x.id}
              >
                <p>{x.label}</p>
              </SelectableCard>
            ))}
          </TwoColumnGridContainer>
        </Popup>
      )}

      

      
     

      
      {!loadingAnnouncements &&
        !data.filter((x) => filterData(x)).length &&
        place &&
        !showClosest && (
          <div
            style={{
              height: '50vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h4>:(</h4>
            <Text>
              Nessuna candidatura disponibile a {place.label.split(',')[0]}{' '}
              {closeData.length <= 0 && 'e dintorni'}
            </Text>
            {closeData.length > 0 && (
              <SecondaryButton onClick={() => setShowClosest(true)}>
                Mostra nelle vicinanze
              </SecondaryButton>
            )}
          </div>
        )}
      {!loadingAnnouncements &&
        !data.filter((x) => filterData(x)).length &&
        !place && (
          <div
            style={{
              height: '50vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text>
              Nessuna candidatura{' '}
              {userData && userData.role == ADMIN && notActiveFilter
                ? 'da validare'
                : 'disponibile'}
            </Text>
          </div>
        )}

      {!loadingAnnouncements &&
        !closeData.filter((x) => filterData(x)).length &&
        showClosest && (
          <div
            style={{
              height: '50vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h4>:(</h4>
            <Text>Nessuna candidatura disponibile</Text>
          </div>
        )}

      {loadingAnnouncements && (
        <div
          style={{
            height: '50vh',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Loader />
        </div>
      )}

      {/** MAIN */}
      {!loadingAnnouncements && (
        <TwoColumnGridWithMargin>
          
        <TwoColumnGridAnnouncementContainer>    
          {data
            .filter((x) => filterData(x))
            .slice(0, limit)
            .map((announcement, i) => (
              <AnnouncementCard
                callback={()=>{}}
                action={false}
                actionsOpen={actionOpen == i}
                
                  setActionOpen={() =>
                    actionOpen == i ? setActionOpen(-1) : setActionOpen(i)
                  }
                  onAccept={() => {console.log("Accept")}}
                  onConfirm={() => console.log("Confirm")}
                  onReject={() =>console.log("Reject")}
                  onCancel={() => console.log("Cancel")}
                  onShow={() => console.log("Show")}
                  onEvaluate={() => console.log("Evaluate")}
                  onReview={() => console.log("Review")}
                role={userData?.role} 
                announcement={announcement.announcement} 
                status={AnnouncementStates.OPEN}/>
             
              
            ))}
            </TwoColumnGridAnnouncementContainer>    
        </TwoColumnGridWithMargin>
          
          
      )}
      {data.filter((x) => filterData(x)).length > limit && (
        <div style={{width:"100%", display:'flex',alignItems:'center',justifyContent:'center'}}>
          <DeletableButton onClick={() => setLimit(limit + 6)}>
            Mostra altri
          </DeletableButton>
        </div>
      )}
      {showClosest && closeData.length > 0 && (
        <div>
          {/** NWIGHBOROOD */}
          <GridContainer>
            {closeData
              .filter((x) => filterData(x))
              .slice(0, limit)
              .map((announcement, index) => (
                <AnnouncementCard
                callback={()=>{}}
                role={userData?.role}
                id={announcement.announcement.id}
                status={AnnouncementStates.OPEN}/>
                
              ))}
          </GridContainer>
          
            {closeData.filter((x) => filterData(x)).length > limit && (
              <div style={{width:"100%", display:'flex',alignItems:'center',justifyContent:'center'}}>
              <DeletableButton onClick={() => setLimit(limit + 6)}>
                Mostra altri
              </DeletableButton>
              </div>
            )}
          </div>
        
      )}
    </>
  );
}

export default Applications;
