



import DeletableButton from '../../../components/presentationals/buttons/DeletableButton/DeletableButton';

import { useNavigate } from "react-router-dom";

function Success() {

	const navigate = useNavigate();

	const onClick = () => {
		navigate("/me/profile");
		navigate(0);
	}
	
	return (
		
		<div style={{
			'display': 'flex',
			'flexDirection': 'column',
			'alignItems':'center',
			'marginTop':'128px',
			'padding':'16px',
		}}>
			<p style={{
				'fontSize':'32px',
			}}>
				Pagamento avvenuto con successo
			</p>
			<p style={{
				'fontSize':'16px',
			}}>
				Stiamo aggiornando il tuo profilo, questa operazione potrebbe richiedere qualche minuto
			</p>

			<DeletableButton style={{"width":"fit-content"}} onClick={onClick}>
				Torna alla pagina profilo
			</DeletableButton>

		</div>
	);
}

export default Success;