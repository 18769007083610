import styled from 'styled-components';



export const HalfScreenMainContainer = styled.div`
 
  
  display:flex;
  width:100vw;
  position:relative;
  padding-top: 5%;
  align-items:center;
  justify-content:center;
  overflow: hidden;
  
  background: ${({ $bgColor }) => ($bgColor ? $bgColor : 'rgba(217, 227, 176, 0.24)')};;

  flex-direction: column;
  

`;

export const HalfScreenInnerContainer = styled.div`

  margin: 3%;
  display: flex;
  justify-content: space-between;
  max-width:1300px;

  align-items: center;
  padding-top:20px;
  width: calc(100% - 10%);
  height: calc(100% - 10%);
  @media (max-width: 1024px) {
    flex-direction: column;
  }

`;

export const HalfScreenInnerContainerLarge = styled.div`
 

  margin: 5%;
  display: flex;
  justify-content: space-between;
  max-width:1300px;

  align-items: center;
  padding-top:50px;
  width: calc(100% - 10%);
  height: calc(100% - 10%);
  @media (max-width: 1024px) {
    flex-direction: column;
  }

`;