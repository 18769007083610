const genres = [
    { id: 0, label: 'Classico' },
    { id: 1, label: 'Barocco' },
    { id: 2, label: 'Pop' },
    { id: 3, label: 'Jazz' },
    { id: 4, label: 'Folk' },
    { id: 5, label: 'Blues' },
    { id: 6, label: 'Elettronica' },
    { id: 7, label: 'altro' }
  ]
export default genres;