// App.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import StripePayment from '../Stripe/Stripe';
import { useSession, useUser } from '@clerk/clerk-react';


const subscriptions = [
  { id: 0, name: 'Gratuito', price: '0€/anno' },
  { id: 1, name: 'Singolo', price: '5€/anno' },
  { id: 2, name: 'Duo', price: '10€/anno' },
  { id: 3, name: 'Trio', price: '15€/anno' },
  { id: 4, name: 'Quartetto', price: '20€/anno' },
  { id: 5, name: 'Formazioni più grandi', price: '25€/anno' }
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Title = styled.h1`
  margin-bottom: 20px;
  text-align: center;
`;

const SubscriptionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`;

const SubscriptionBox = styled.div`
  border: 2px solid ${(props) => (props.selected ? '#007bff' : '#ccc')};
  background-color: ${(props) => (props.iscurrent ? '#dff0d8' : 'white')};
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  transition: border-color 0.3s;
  text-align: center;
  flex: 1 1 calc(33.333% - 20px);
  min-width: 150px;

  &:hover {
    border-color: #007bff;
  }

  @media (max-width: 768px) {
    flex: 1 1 calc(50% - 20px);
  }

  @media (max-width: 480px) {
    flex: 1 1 100%;
  }
`;

const SubscriptionName = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const SubscriptionPrice = styled.div`
  font-size: 14px;
  margin-top: 5px;
`;

const SubscriptionSelector = ({ currentPlan }) => {
  currentPlan = currentPlan ?? 0; // if no plan - it is free plan
  const [selectedSubscription, setSelectedSubscription] = useState(currentPlan);


  const [jwtToken, setJwtToken] = useState(null);
  const [userId, setUserId] = useState(null);

  const {session} = useSession();
  useEffect(() => {
    session.getToken().then(
      token => {
        setJwtToken(token);
      }
    );
  }, [session]);


  const { user } = useUser();
  useEffect(() => {
    setUserId(user.id);
  }, [user]);


  return (
    <Container>
      <SubscriptionContainer>
        {subscriptions.map((subscription) => (
          <SubscriptionBox
            key={subscription.id}
            selected={subscription.id === selectedSubscription}
            iscurrent={subscription.id === currentPlan ? 'true' : undefined}
            onClick={() => setSelectedSubscription(subscription.id)}
          >
            <SubscriptionName>{subscription.name}</SubscriptionName>
            <SubscriptionPrice>{subscription.price}</SubscriptionPrice>
          </SubscriptionBox>
        ))}
      </SubscriptionContainer>
      {jwtToken && userId && (
        <StripePayment
          productId={selectedSubscription}
          disabled={selectedSubscription===currentPlan}
          userId={userId}
          jwtToken={jwtToken}
          subscriptionName={subscriptions.find(x => x.id === selectedSubscription).name}
          subscriptionPrice={subscriptions.find(x => x.id === selectedSubscription).price}
        />
      )}
    </Container>
  );
};

export default SubscriptionSelector;
