const musicalInstruments = [
  { id: 0, label: "Alto", img: null },
  { id: 1, label: "Armonica a bocca", img: null },
  { id: 2, label: "Arpa", img: null },
  { id: 3, label: "Arpa Barocca", img: null },
  { id: 4, label: "Banjo", img: null },
  { id: 5, label: "Baritono", img: null },
  { id: 6, label: "Bass-Baritone", img: null },
  { id: 7, label: "Basso", img: null },
  { id: 8, label: "Batteria", img: null },
  { id: 9, label: "Campane Tubolari", img: null },
  { id: 10, label: "CDJ", img: null },
  { id: 11, label: "Celesta", img: null },
  { id: 12, label: "Chalumeau", img: null },
  { id: 13, label: "Chitarra", img: null },
  { id: 14, label: "Chitarra acustica", img: null },
  { id: 15, label: "Chitarra Barocca", img: null },
  { id: 16, label: "Chitarra elettrica", img: null },
  { id: 17, label: "Clarinetto", img: null },
  { id: 18, label: "Clarinetto Basso", img: null },
  { id: 19, label: "Clavicembalo", img: null },
  { id: 20, label: "Clavicordo", img: null },
  { id: 21, label: "Compositore", img: null },
  { id: 22, label: "Contralto", img: null },
  { id: 23, label: "Contrabbasso", img: null },
  { id: 24, label: "Contrabbasso Barocco", img: null },
  { id: 25, label: "Controller DJ", img: null },
  { id: 26, label: "Controller Midi", img: null },
  { id: 27, label: "Controtenore", img: null },
  { id: 28, label: "Corno Francese", img: null },
  { id: 29, label: "Corno Inglese", img: null },
  { id: 30, label: "Corno naturale", img: null },
  { id: 31, label: "Cornetto", img: null },
  { id: 32, label: "DAW", img: null },
  { id: 33, label: "Direttore di orchestra", img: null },
  { id: 34, label: "Drum machines", img: null },
  { id: 35, label: "Eufonio", img: null },
  { id: 36, label: "Fagotto", img: null },
  { id: 37, label: "Fagotto Barocco", img: null },
  { id: 38, label: "Fisarmonica", img: null },
  { id: 39, label: "Flauto", img: null },
  { id: 40, label: "Flauto Dolce", img: null },
  { id: 41, label: "Flauto Traverso Barocco", img: null },
  { id: 42, label: "Flicorno baritono", img: null },
  { id: 43, label: "Flicorno contralto", img: null },
  { id: 44, label: "Flicorno soprano", img: null },
  { id: 45, label: "Flicorno tenore", img: null },
  { id: 46, label: "Glockenspiel", img: null },
  { id: 47, label: "Grancassa", img: null },
  { id: 48, label: "Liuto", img: null },
  { id: 49, label: "Loop Station", img: null },
  { id: 50, label: "Mandolino", img: null },
  { id: 51, label: "Marimba", img: null },
  { id: 52, label: "Mezzosoprano", img: null },
  { id: 53, label: "Oboe", img: null },
  { id: 54, label: "Oboe Barocco", img: null },
  { id: 55, label: "Organo", img: null },
  { id: 56, label: "Organo Hammond", img: null },
  { id: 57, label: "Ottavino", img: null },
  { id: 58, label: "Percussioni", img: null },
  { id: 59, label: "Pianoforte", img: null },
  { id: 60, label: "Piatti", img: null },
  { id: 61, label: "Rullante", img: null },
  { id: 62, label: "Sackbut", img: null },
  { id: 63, label: "Samplers", img: null },
  { id: 64, label: "Sassofono", img: null },
  { id: 65, label: "Sax alto", img: null },
  { id: 66, label: "Sax baritono", img: null },
  { id: 67, label: "Sax tenore", img: null },
  { id: 68, label: "Serpentone", img: null },
  { id: 69, label: "Sequencer", img: null },
  { id: 70, label: "Slide guitar", img: null },
  { id: 71, label: "Sopranista", img: null },
  { id: 72, label: "Soprano", img: null },
  { id: 73, label: "Spinetta", img: null },
  { id: 74, label: "Synth", img: null },
  { id: 75, label: "Tamburello", img: null },
  { id: 76, label: "Tamburo", img: null },
  { id: 77, label: "Tastiere", img: null },
  { id: 78, label: "Tenore", img: null },
  { id: 79, label: "Theremin", img: null },
  { id: 80, label: "Timpani", img: null },
  { id: 81, label: "Tiorba", img: null },
  { id: 82, label: "Tromba", img: null },
  { id: 83, label: "Tromba naturale", img: null },
  { id: 84, label: "Trombone", img: null },
  { id: 85, label: "Trombone Basso", img: null },
  { id: 86, label: "Trombone Tenore", img: null },
  { id: 87, label: "Tuba", img: null },
  { id: 88, label: "Turntables", img: null },
  { id: 89, label: "Triangolo", img: null },
  { id: 90, label: "Ukulele", img: null },
  { id: 91, label: "Vibrafono", img: null },
  { id: 92, label: "Violino", img: null },
  { id: 93, label: "Violino Barocco", img: null },
  { id: 94, label: "Viola", img: null },
  { id: 95, label: "Viola Barocca", img: null },
  { id: 96, label: "Viola da Gamba Basso", img: null },
  { id: 97, label: "Viola da Gamba Soprano", img: null },
  { id: 98, label: "Viola da Gamba Tenore", img: null },
  { id: 99, label: "Violoncello", img: null },
  { id: 100, label: "Violoncello Barocco", img: null },
  { id: 101, label: "Virginale", img: null },
  { id: 102, label: "Voce", img: null },
  { id: 103, label: "VST", img: null },
  { id: 104, label: "Xilofono", img: null },
  { id: 105, label: "Maestro pianista collaboratore", img: null }
];


export default musicalInstruments;
