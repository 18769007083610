import styled from 'styled-components';

export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  background-color: #f0efff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 30px;
  z-index: 1;
  width: 100%;

  @media (max-width: 390px) {
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: ${({ $direction }) => ($direction ? $direction:'column' )};
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: ${({ $direction }) => ($direction ? $direction:'row')};
    width: 85%;
  }
`;

export const InputWithIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const SearchInput = styled.input`
  padding: 0; /* Rimuove il padding */
  border: none; /* Rimuove il bordo */
  border-radius: 0; /* Rimuove il border-radius */
  font-size: 14px;
  background: none; /* Rimuove lo sfondo */
  outline: none; /* Rimuove l'outline/focus border */
  color: inherit; /* Usa il colore di testo predefinito */

  &::placeholder {
    color: ${({ $color }) => ($color ? $color : 'black')};
    font-size: 16px;
  }
`;
