import styled from 'styled-components';

export const Banner = styled.div`
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: url(${(props) => props.$bkgImg});
  background-size: cover; 
  background-position: center;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
  }
`;

export const BannerImage = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ContentContainer = styled.div`

max-width:1300px;
width:100%
`

export const CenterTextContainer = styled.div`
  font-family: Raleway Regular;
  position: absolute;
  top: 30%;
  left: 0;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;

  @media (max-width: 768px) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    text-align: center;
  }
`;

export const Heading = styled.h1`
  font-family: Raleway Regular;
  color: #37245A;
`;

export const Subheading = styled.h3`
  font-family: Raleway Regular;
  color: #37245A;
`;

export const BottomText = styled.p`
  position: absolute;
  bottom: 50px;
  right:0;
  left:0;
  text-align: center;
  color: white;
`;

export const DoubleDownArrow = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
`;

export const FirstHalfContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  
  gap: 20px;
  @media (max-width: 1024px) {
    align-items: center;
  }
  @media (max-width: 640px) {
    width: 90%;
  }
`;

export const SecondHalfContainer = styled.div`
  width: 47%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
  }

  .slick-initialized .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 1024px) {
    width: 60%;
  }

  @media (max-width: 610px) {
    width: 80%;
  }
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  border: 2px solid white;
  position: relative;
  left: ${(props) => `${props.index * -10}px`};
  width: ${({$size}) => $size?$size +"px":'30px'};
  height: ${({$size}) => $size?$size+"px":'30px'};
`;

export const Row = styled.div`
  display: flex;
  justify-content: ${({ $justifyContent }) => ($justifyContent ? $justifyContent : 'space-between')};
  align-items: center;
  width: 100%;
  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ImageStack = styled.div`
  display: flex;

  @media (max-width: 480px) {
    margin-left: 30px;
  }
`;

export const Paragraph = styled.p`
  font-family: Raleway Regular;
  width: 100%;
  font-size: 18px;
  align-items: flex-start;
  text-align: left;
  text-align: ${({ $textAlign }) => ($textAlign ? $textAlign : 'left')};
  color: ${({ $textColor }) => ($textColor ? $textColor : '#000')};
`;

export const LineClamper = styled.div`
  @media (max-width: 480px) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;