import { useClerk } from "@clerk/clerk-react";
import { faFilter, faSync } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import GenericSearchBar from "../../../components/functionals/GenericSearchBar/GenericSearchBar";
import Popup from "../../../components/functionals/Popup/Popup";
import HorizontalBar from "../../../components/presentationals/bars/HorizontalBar/HorizontalBar";
import IconButton from "../../../components/presentationals/buttons/IconButton/IconButton";
import SecondaryButton from "../../../components/presentationals/buttons/SecondaryButton/SecondaryButton";
import RequestCard from "../../../components/presentationals/cards/RequestCard/RequestCard";
import PreventiveForm from "../../../components/presentationals/forms/PreventiveForm/PreventiveForm";
import ReviewForm from "../../../components/presentationals/forms/ReviewForm/ReviewForm";
import { SmallText } from "../../../components/presentationals/texts/TextsElements";
import { ADMIN, PLANNER } from "../../../config/profile/Roles";
import Request from "../../../models/Request";
import Review from "../../../models/Review";
import { acceptRequest, addPreventive, cancelRequest, confirmRequest, getRequests, rejectRequest } from "../../../remote/requests";
import { newReview, updateReview } from "../../../remote/reviews";
import getToken from "../../../userManagement/utilities";
import Loader from "../../../utils/Loader";
import RequestForm from "../../../components/presentationals/forms/RequestForm/RequestForm";
import { OuterContainer } from "../../../components/presentationals/Containers/layouts/MainLayout/MainLayoutElements";
import RequestsStates from "../../../config/RequestsStates";
import DeletableButton from "../../../components/presentationals/buttons/DeletableButton/DeletableButton";
import { TwoColumnGridContainer } from "../../../components/presentationals/Containers/GridContainer/TwoColumnsGridContainer/TwoColumnsGridContainer";
import SelectableCard from "../../../components/presentationals/cards/SelectableCard/SelectableCard";


function Requests(props) {

	
	const [actionOpen,setActionOpen] = useState(-1)
	const {session} = useClerk()
	const [showRequest,setShowRequest] = useState(null)
	const [evaluateRequest,setEvaluateRequest] = useState(null)
	const [reviewRequest, setReviewRequest] = useState(null)
	const [loading,setLoading] = useState(true)
	const [search, setSearch] = useState("")
  const [filters,setFilters] = useState([RequestsStates.PENDING])
  const [filterPopup, setFilterPopup] = useState(false)

	const [requests, setRequests] = useState(null)

	const preventiveFormRef = useRef()
	const reviewFormRef = useRef()


	const filterByInput = (x)=>{
    console.log("Search: ",search)
		return x.id.includes(search) || x.musicianName.includes(search) || x.plannerName.includes(search)
	}

	const filterRequest = (x)=>{
    let res = true;
		if (search)
			res = filterByInput(x)

    res &= filters.includes(x.status)
    
		return res;
	}

  const toggleState = (f) => {
   

    let s = filters;

   

    if (filters.includes(f))
      s = filters.filter((x) => x !== f);
    else {
      s = filters.concat([f]);
    }

    console.log('S: ', s);

    setFilters(s);
  };



	const onAccept = async (id)=>{
		setLoading(true)
		let t = await getToken(session)
		await acceptRequest(id,t)
		await loadRequests()
	}

	const onConfirm = async (id)=>{
		setLoading(true)
		let t = await getToken(session)
		await confirmRequest(id,t)
		await loadRequests()
	}

	const onReject = async (id)=>{
		setLoading(true)
		let t = await getToken(session)
		await rejectRequest(id,t)
		await loadRequests()
	}

	const onCancel = async (id) => {
		setLoading(true)
		let t = await getToken(session)
		await cancelRequest(id,t)
		await loadRequests()
	}

	const onShow = async (request)=>{
		setShowRequest(request)
	}

	const onEvaluate = async (request)=>{
		
		setEvaluateRequest(request)
		
	}

	const onReview = async (request)=>{
		
		setReviewRequest(request)
		
	}
	


	const loadRequests = async()=>{
		console.log("Getting requetes...")
		let t = await getToken(session);
		let requests = await getRequests(t);
		
        requests = requests.map(
          (x) =>
            new Request(
              x.id,
              x.musicianId,
              x.plannerId,
              new Date(x.date),
              x.place,
              x.status,
              x.notes,
              x.musicianName,
              x.plannerName,
              x.image,
			  x.preventive,
			  x.reviewId,
			  x.extra
            )
        );

		console.log("got: ",requests)
		setRequests(requests)
		setLoading(false)

	}


	useEffect(()=>{
		loadRequests()
	},[])

  const handleReset = ()=>{
    setFilters([RequestsStates.PENDING])
    setSearch("")
  }


	
	
	
	

	return (
    <OuterContainer>
      <HorizontalBar spaceBetween={true} margin='30px 0 0 0'>
        <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
          <IconButton
            color={'black'}
            onClick={() => {}}
            icon={faFilter}
            text={'Filtra'}
            display='flex'
          />
        </div>
        <div style={{ display: 'flex', flex: 5 }}>
          <GenericSearchBar
            placeholder={
              'Cerca per id, nome musicista, nome organizzatore, ...'
            }
            onSearch={(search) => {
              setSearch(search);

             
            }}
            searchV={null}
          />
        </div>
      </HorizontalBar>

      <HorizontalBar margin='10px 40px' spaceBetween>
        <div style={{display:'flex', gap:"20px"}}>
        {filters.length > 0 ? (
          <SecondaryButton
            padding='12px 20px 12px 20px'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
            onClick={() => setFilterPopup(true)}
          >
            {filters
              .map((x, i) => <span key={i}>{x}</span>)
              .reduce((prev, curr, i) => [prev, ', ', curr])}
          </SecondaryButton>
        ) : (
          <SecondaryButton onClick={() => console.log("hey")}>
            Stati
          </SecondaryButton>
        )}</div>
        <div>
          <SecondaryButton onClick={handleReset}>Reset</SecondaryButton>
        </div>
      </HorizontalBar>

      <HorizontalBar spaceBetween={true}>
        <SmallText>
          {!loading && requests.filter(x=>filterRequest(x)).length} richieste
        </SmallText>
        <IconButton
          color={'black'}
          onClick={() => {
            setLoading(true);
            loadRequests();
          }}
          icon={faSync}
          text={'Aggiorna'}
          display='flex'
        />
      </HorizontalBar>

      <div style={{ margin: '50px 0' }}>
        {loading && <Loader />}
        {!loading &&
          requests.filter((x) => filterRequest(x)) &&
          requests
            .filter((x) => filterRequest(x))
            .map((x, i) => (
              <RequestCard
                actionsOpen={actionOpen == i}
                role={props.role}
                setActionOpen={() =>
                  actionOpen == i ? setActionOpen(-1) : setActionOpen(i)
                }
                onAccept={() => onAccept(x.id)}
                onConfirm={() => onConfirm(x.id)}
                onReject={() => onReject(x.id)}
                onCancel={() => onCancel(x.id)}
                onShow={() => onShow(x)}
                onEvaluate={() => onEvaluate(x)}
                onReview={() => onReview(x)}
                status={x.status}
                image={x.image}
                title={
                  props.role == ADMIN
                    ? x.plannerName + ' per ' + x.musicianName
                    : props.role == PLANNER
                    ? x.musicianName
                    : x.plannerName
                }
                place={x.place ? x.place.label : ''}
                review={x.reviewId}
                data={x.date}
              />
            ))}

        {showRequest && (
          <Popup
            title={
              props.role == ADMIN
                ? showRequest.plannerName + ' per ' + showRequest.musicianName
                : props.role == PLANNER
                ? showRequest.musicianName
                : showRequest.plannerName
            }
            content='Contenuto del Popup'
            onClose={() => {
              setShowRequest(null);
            }}
          >
            <RequestForm
              //ref={requestFormRef}
              place={showRequest.place}
              dateV={showRequest.date}
              notes={showRequest.notes}
              disabled={true}
            />
          </Popup>
        )}

        {evaluateRequest && (
          <Popup
            title={
              evaluateRequest.musicianName +
              ' per ' +
              evaluateRequest.plannerName
            }
            content='Contenuto del Popup'
            onClose={() => {
              setEvaluateRequest(null);
            }}
            onConfirm={
              props.role == ADMIN
                ? async () => {
                    console.log('Azione confermata');
                    //createRequest();
                    setLoading(true);
                    let t = await getToken(session);
                    let preventive = preventiveFormRef.current.getPreventive();
                    if (props.role == ADMIN)
                      await addPreventive(
                        evaluateRequest.id,
                        preventive.preventive,
                        preventive.extra,
                        t
                      );
                    else await acceptRequest(evaluateRequest.id, t);
                    setEvaluateRequest(null);
                    await loadRequests();
                  }
                : null
            }
          >
            <PreventiveForm
              ref={preventiveFormRef}
              disabled={props.role != ADMIN}
              preventiveV={evaluateRequest.preventive}
              date={evaluateRequest.date}
              details={evaluateRequest.notes}
              place={evaluateRequest.place}
            />
          </Popup>
        )}

        {reviewRequest && (
          <Popup
            title={
              reviewRequest.musicianName + ' per ' + reviewRequest.plannerName
            }
            content='Contenuto del Popup'
            onClose={() => {
              setReviewRequest(null);
            }}
            onConfirm={async () => {
              // console.log('Azione confermata');
              // //createRequest();
              setLoading(true);
              let t = await getToken(session);
              let r = reviewFormRef.current.getReview();
              console.log('R: ', r);
              let review = new Review(
                -1,
                reviewRequest.musicianId,
                reviewRequest.plannerId,
                reviewRequest.plannerName,
                reviewRequest.id,
                r.text,
                r.value
              );
              // await addPreventive(evaluateRequest.id,30,t)

              if (!reviewRequest.reviewId) await newReview(review, t);
              else {
                review.id = reviewRequest.reviewId;
                await updateReview(review, t);
              }

              setReviewRequest(null);

              await loadRequests();
            }}
          >
            <ReviewForm
              ref={reviewFormRef}
              id={reviewRequest.reviewId}
              request={reviewRequest.id}
            />
          </Popup>
        )}
      </div>

      {filterPopup && (
        <Popup
          title={'Scegli fino a 5 strumenti'}
          onClose={() => setFilterPopup(false)}
          
        >
          <TwoColumnGridContainer>
            {Object.keys(RequestsStates).filter(x=>x!=RequestsStates.PENDING).map((x) => (
              <SelectableCard
                selected={filters.includes(x)}
                onClick={() => toggleState(x)}
                key={x}
              >
                <p>{x}</p>
              </SelectableCard>
            ))}
          </TwoColumnGridContainer>
        </Popup>
      )}
    </OuterContainer>
  );
}

export default Requests;