import styled from 'styled-components';


export const HorizontalLayout = styled.div`
  display: flex;
  width: 100%;
  align-items: ${({$alignItems}) => $alignItems || 'center'};
  justify-content: ${({$justifyContent}) => $justifyContent || 'center'};

  @media (min-width: 769px) {
    display: ${({ $display }) => ($display ? $display : 'flex')};
  }

  @media (max-width: 768px) {
    display: ${({ $responsiveDisplay }) => ($responsiveDisplay ? $responsiveDisplay : 'flex')};
  }
`;