import styled, { createGlobalStyle } from 'styled-components';

export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 51;
`;

export const PopupContainer = styled.div`
  background-color: white;
  width: 600px;
  max-height: 600px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: ${({ $alignItems }) => $alignItems || 'normal'};

  @media (max-width: 600px) {
    width: 400px;
  }

  @media (max-width: 450px) {
    width: 350px;
  }
  @media (max-width: 370px) {
    width: 300px;
  }
`;

export const TitleContent = styled.div`
  padding: 20px;
  @media (max-width: 450px) {
    padding: 10px 20px;
  }
`;

export const Content = styled.div`
  display: grid;
  align-content: start;
  padding: 0 25px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #a7a3ff;
  }
`;

export const ButtonContainer = styled.div`
  display: grid;
  justify-content: center;
  padding: 0 20px;
  align-items: center; 
  margin: 10px 0;
  align-content: center;
  gap: 10px;
}
`;

export const GlobalStyle = createGlobalStyle`
  body {
    overflow: ${(props) => (props.noScroll ? 'hidden' : 'auto')};
  }
`;
