import styled from "styled-components";

import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";

export const MediaCarouselWrapper = styled.div`
  text-align: center;
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  &.current {
    transition: all 0.5s linear;
    position: relative;
    top: 0;
    left: 0;
    opacity: 1;
  }
  &.previous {
    position: absolute;
    top: 0;
    left: -120%;
    transition: all 0.3s linear;
    opacity: 0;
  }
  &.future {
    position: absolute;
    top: 0;
    left: 120%;
    transition: all 0.3s linear;
    opacity: 0;
  }
`;



export const MediaCarouselContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: transparent;
`;

export const MediaCarouselLeftSlider = styled(AiOutlineDoubleLeft)`
  position: absolute;
  left: 0;
  top: 215px;
  transform: translateY(-50%);
  padding: 10px;
  z-index: 10;
  &:hover {
    color: #ba5d2c;
    cursor: pointer;
    border: 1px solid #ba5d2c;
    border-radius: 10px;
    padding: 9px;
  }
`;

export const MediaCarouselRightSlider = styled(AiOutlineDoubleRight)`
  position: absolute;
  right: 0;
  top: 215px;
  transform: translateY(-50%);
  padding: 10px;
  &:hover {
    color: #ba5d2c;
    cursor: pointer;
    border: 1px solid #ba5d2c;
    border-radius: 10px;
    padding: 9px;
  }
  z-index: 10;
`;
