import { SelectableCardContainer } from './SelectableCardElements';

const SelectableCard = ({ onClick, disabled, selected, children }) => {
    return (
    <SelectableCardContainer
      onClick={!disabled ? onClick : null}
      disabled={disabled}
      selected={selected}
    >
      {children}
    </SelectableCardContainer>
  );
};

export default SelectableCard;
