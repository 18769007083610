import React from 'react';
import { IconButtonContainer } from './IconButtonElements';
import { IconText } from '../../texts/TextsElements';
import { FavoritesButtonContainer } from '../FavoritesButton/FavoritesButtonElements';

const IconButton = ({ icon, text, color, onClick, display, size, fontSize }) => {
  return (
    <FavoritesButtonContainer onClick={onClick} $display={display}>
      <IconButtonContainer icon={icon} color={color} size={size} />
      <IconText $fontSize={fontSize}>{text}</IconText>
    </FavoritesButtonContainer>
  );
};

export default IconButton;
