import styled from 'styled-components';


export const FullSizeContainer = styled.div`
  display: grid;
  align-content: start;
  overflow: auto;
  width: ${({ $fullWidth }) => $fullWidth || '35vw'};
  // margin-top: 20px;
  gap: 20px;

  @media (max-width: 768px) {
    width: ${({ $fullWidth }) => $fullWidth || '90vw'};
  }
`;

