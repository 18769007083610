import styled, { keyframes } from 'styled-components';

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  position: ${({$position}) => $position || 'absolute'};
  top: ${({$top}) => $top || 0};
  left: 0;
  height: 30px;
  background-color: #9a84bc;
  padding: 15px 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 30;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  width: 40%;
  flex-direction: row;
  justify-content: space-between;
`;

export const TabText = styled.button`
  color: #f0efff;
  border: none;
  font-size: 20px;
  cursor: pointer;

  /* Stili per lo stato normale */
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s;

  text-decoration: ${({ $isSelected }) => ($isSelected ? 'underline' : 'none')};

  /* Stili per lo stato di hover */
  &:not(:disabled):hover {
    color: #fff;
    transform: scale(1.3); /* Ingrandisce il testo */
    transition: color 0.3s, transform 0.3s;
  }

  /* Stili per lo stato di click */
  &:not(:disabled):active {
    animation: ${({ disabled }) => (disabled ? 'none' : pulseAnimation)} 0.2s
      ease-in-out;
  }
`;
