

import { useEffect, useRef } from 'react';
import WholeScreen from '../../components/presentationals/Containers/layouts/WholeScreen/WholeScreen';

function CookieDeclaration() {
  const legalContainerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'CookieDeclaration';
    script.src = 'https://consent.cookiebot.com/22fc254b-07a1-4cab-8de6-b4ce5f8295cc/cd.js';
    script.type = 'text/javascript';
    script.async = true;

    legalContainerRef.current.appendChild(script);

    return () => {
      legalContainerRef.current.removeChild(script);
    };
  }, [legalContainerRef]);

  return (
    <WholeScreen $bgColor={"#fff"} >
      <div ref={legalContainerRef} className="legal__container" />
    </WholeScreen>
  );
}

export default CookieDeclaration;
