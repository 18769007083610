import {
  useClerk,
  useUser,
} from "@clerk/clerk-react";
import Slider from 'rc-slider';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Popup from '../../components/functionals/Popup/Popup';
import SearchBar from '../../components/functionals/SearchBar/SearchBar';
import { TwoColumnGridContainer } from '../../components/presentationals/Containers/GridContainer/TwoColumnsGridContainer/TwoColumnsGridContainer';
import HorizontalBar from '../../components/presentationals/bars/HorizontalBar/HorizontalBar';
import DeletableButton from '../../components/presentationals/buttons/DeletableButton/DeletableButton';
import { RightFixedButton } from '../../components/presentationals/buttons/DeletableButton/DeletableButtonElements';
import SecondaryButton from '../../components/presentationals/buttons/SecondaryButton/SecondaryButton';
import MainCard from '../../components/presentationals/cards/MainCard/MainCard';
import { GridContainer, TagIcon } from '../../components/presentationals/cards/MainCard/MainCardElements';
import SelectableCard from '../../components/presentationals/cards/SelectableCard/SelectableCard';
import RequestForm from '../../components/presentationals/forms/RequestForm/RequestForm';
import {
  SmallText, Text
} from '../../components/presentationals/texts/TextsElements';
import { ADMIN, USER } from '../../config/profile/Roles';
import genres from '../../data/genres';
import musicalInstruments from '../../data/instruments';
import { disableMusician, getMusicians, validateMusician } from '../../remote/musicians';
import { getPlanners } from "../../remote/planners";
import { useStateValue } from '../../stores/StateProvider';
import SignInPopup from "../../userManagement/signIn/signInPopup";
import getToken from '../../userManagement/utilities';
import { haversineDistance } from '../../utils/Geolocalization';
import Images from "../../utils/Images";
import Loader from "../../utils/Loader";
import { musicianPlans, SINGOLO } from "../../config/musicianPlans";
import { StyledOption, StyledSelect } from "../../components/presentationals/forms/PersonalDataForm/PersonalDataFormElements";

function SearchMusicians() {
  const [{userData}] = useStateValue();
  const [place, setPlace] = useState('');
  const [reset, setReset] = useState(false);
  const [groupFilter, setGroupFilter] = useState("");

  const [loadingMusicians,setLoadingMusicians] = useState(true)
  const [validatingMusician,setValidatingMusician] = useState([])
  const [unfilterData,setUnfilterData] = useState();

  const [dateR, setDateR] = useState('');

  const [maxDistance, setMaxDistance] = useState(80);

  const [data, setData] = useState([]);
  const [closeData, setCloseData] = useState([]);

  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showClosest, setShowClosest] = useState(false)
  

  const dateRRef = useRef();
  dateRRef.current = dateR;
  const placeRef = useRef();
  placeRef.current = place;

  const [notActiveFilter, setNotActiveFilter] = useState(
    userData?.role == ADMIN
  );

  const { session } = useClerk();
  const { isSignedIn } = useUser();
  const requestFormRef = useRef();
  const url = window.location.href; 

  const updateData = async () => {
    let musicians = []
    if (url.includes('/planners')) {
      let t = await getToken(session);
      musicians = await getPlanners(t);
    } else {
      musicians = await getMusicians();
    }

    console.log('musicians', musicians);
    setUnfilterData(musicians);
    
    const urlParams = new URLSearchParams(window.location.search);
    const placeParam = urlParams.get('place');
   

    let p = null;

    if (placeParam) {
      p = JSON.parse(decodeURIComponent(placeParam));
      setPlace(p);
    }


    if (placeParam && p != null) {
      setData(
        musicians.filter(
          (x) =>
            x.city &&
            (x.city.raw.place_id === p.raw.place_id ||
              filterCloseCities(x.city, p, 0, 10))
        )
      );

      setCloseData(
        musicians.filter(
          (x) =>
            x.city &&
            x.city.raw.place_id !== p.raw.place_id &&
            filterCloseCities(x.city, p,10,maxDistance)
        )
      );
    } else {
      setData(
        musicians.map((x) => {
          x.close = false;
          return x;
        })
      );
      setCloseData([]);
    }
    setLoadingMusicians(false)
  };

  // const loadPlanners = async ()=>{

  //   let t = await getToken(session);
  //   // let planners = await getPlanners(t);
  //   let planner = await getPlanner("user_2ZtRDcKbsQMEX629axXNRM7tIHY",t);
  //   // console.log("Planners: ",planners)
  //   console.log("Planner: ",planner)
    
  // }

  
  // useEffect(()=>{
  //   loadPlanners()
  // },[])

  useEffect(() => {
    updateData();
  }, [window.location.href]);

  useEffect(() => {
    if(reset){
      updateData();
      setReset(false)
    }
  }, [reset]);

  useEffect(()=>{
    if (unfilterData && place){
      setCloseData(
        unfilterData.filter(
          (x) =>
            x.city &&
            x.city.raw.place_id !== place.raw.place_id &&
            filterCloseCities(x.city, place,10,maxDistance)
        )
      );
      }
  },[maxDistance])


  useEffect(() => {
    if (isSignedIn) setShowLoginPopup(false);
  }, [isSignedIn]);

  const filterCloseCities = (cityA, cityB,minD,maxD) => {
    console.log("City a: ",cityA)
    console.log("City b: ",cityB)
    console.log("MinD: ",minD)
    console.log("MaxD: ",maxD)
    const distance = haversineDistance(cityA.y, cityA.x, cityB.y, cityB.x);

    // Considera gli utenti entro 10 km dalla città target
    return distance>=minD && distance <= maxD;
  };

  const navigate = useNavigate();

  const [limit, setLimit] = useState(6);

  const [requestOpen, setRequestOpen] = useState(null);
  const [validateOpen, setValidateOpen] = useState(null);
  const [disableOpen, setDisableOpen] = useState(null);

  const [instrumentPopup, setInstrumentPopup] = useState(false);

  const [genrePopup, setGenrePopup] = useState(false);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedInstruments, setSelectedInstruments] = useState([]);
  const heightMarks = {
    10: '10Km',
    
    20: '20Km',
    30: '30Km',

    40: '40Km',

    50: '50Km',

    60: '60Km',

    70: '70Km',

    80: '80Km'
  };


  const filterDataByGenres = (item) => {
    if (selectedGenres.length > 0) {
      return item.genres.find((x) => selectedGenres.includes(x.id));
    }

    return true;
  };

  const filterDataByInstruments = (item) => {
    if (selectedInstruments.length > 0) {
      return item.instruments.find((x) => selectedInstruments.includes(x.id));
    }

    return true;
  };

  const filterDataByNotActive = (item) => {
    if (!item.isOnboardingCompleted) {
      return false;
    }

    if (userData?.role === ADMIN && notActiveFilter) {
      return !item.valid;
    }

    if (userData?.role !== ADMIN) {
      return item.valid;
    }

    return true;
  };

  const filterData = (item) => {
    return (
      filterDataByGroupType(item) && 
      filterDataByNotActive(item) &&
      filterDataByGenres(item) &&
      filterDataByInstruments(item)
    );
  };

  const onValidate = async () => {
    // setLoadingMusicians(true)
    let a = validatingMusician
    a.push(validateOpen.id)
    setValidatingMusician(a)
    setValidateOpen(null);
    let t = await getToken(session);
    await validateMusician(validateOpen.id, t);
    await updateData();
    setValidatingMusician(validatingMusician.filter(x=>x!=validateOpen.id))
    
  };

  const onDisable = async () => {
    // setLoadingMusicians(true)
    let a = validatingMusician
    a.push(disableOpen.id)
    setValidatingMusician(a)
    setDisableOpen(null);
    let t = await getToken(session);
    await disableMusician(disableOpen.id, t);
    await updateData();
    setValidatingMusician(validatingMusician.filter(x=>x!=disableOpen.id))
  };

  const toggleInstrument = (instrument) => {
    console.log('Instrument: ', instrument);

    let s = selectedInstruments;

    console.log('s: ', s);

    if (selectedInstruments.includes(instrument))
      s = selectedInstruments.filter((x) => x !== instrument);
    else if (selectedInstruments.length<5) {
      s = selectedInstruments.concat([instrument]);
    }

    console.log('S: ', s);

    setSelectedInstruments(s);
  };

  const toggleGenre = (genre) => {
    let s = selectedGenres;

    console.log('s: ', s);

    if (selectedGenres.includes(genre))
      s = selectedGenres.filter((x) => x !== genre);
    else if (selectedGenres.length<3){
      s = selectedGenres.concat([genre]);
    }

    console.log('S: ', s);

    setSelectedGenres(s);
  };

  const filterDataByGroupType = (item) => {
    if (groupFilter=="") 
        return true;
      
    if (groupFilter == SINGOLO && (!item.group || !item.group.groupType))
      return true;

    if (item.group && item.group.groupType==groupFilter.toLowerCase())
      return true;

    return false;
  };
  

  // console.log("place",place);

  return (
    <>
      {showLoginPopup && (
        <SignInPopup setShowLoginPopup={() => setShowLoginPopup(false)} />
      )}

      {userData?.role != ADMIN && <RightFixedButton onClick={() => {
                        navigate('/consulting');
                      }} $bkgImg={Images.elements.consigliamiButton} />}
      
      <HorizontalBar spaceBetween={true} margin='30px 0 0 0'>
        <SearchBar
          disabledDate={true}
          onSearch={(place, date) => {
            if (place)
              navigate('/musicians?place=' + JSON.stringify(place) );
            else navigate('/musicians');
          }}
          place={place}
        />
      </HorizontalBar>

      {!url.includes('/planners') && (
        <HorizontalBar margin='30px 0px 20px 0px' gap='20px' spaceBetween direction='column' mediaQuery={786}>
          <div style={{ display: 'flex', gap: '20px', width:"100%" }}>
            {selectedInstruments.length > 0 ? (
              <DeletableButton
                padding='12px 40px 12px 20px'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                }}
                onDelete={() => setSelectedInstruments([])}
              >
                {selectedInstruments
                  .map((x, i) => (
                    <span key={i}>{musicalInstruments.filter(y=>y.id==x)[0].label}</span>
                  ))
                  .reduce((prev, curr, i) => [prev, ', ', curr])}
              </DeletableButton>
            ) : (
              <SecondaryButton onClick={() => setInstrumentPopup(true)}>
                Strumenti
              </SecondaryButton>
            )}

            {selectedGenres.length > 0 ? (
              <DeletableButton
                padding='12px 40px 12px 20px'
                onDelete={() => setSelectedGenres([])}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                }}
              >
                {selectedGenres
                  .map((x) => <span style={{}}>{genres[x].label}</span>)
                  .reduce((prev, curr, i) => [prev, ', ', curr])}
              </DeletableButton>
            ) : (
              <SecondaryButton onClick={() => setGenrePopup(true)}>
                Generi
              </SecondaryButton>
            )}

            <StyledSelect
                    name='group'
                    onChange={(option)=>setGroupFilter(option.target.value)}
                    style={{maxWidth:300}}
                    // $active={!!errors?.groupType?.message}
                    
                  >
                  <StyledOption value=''>Cosa stai cercando?</StyledOption>
                  <StyledOption value={SINGOLO}>{"Singolo"}</StyledOption>
                  {Object.keys(musicianPlans).map((option) => (
                    <StyledOption key={option} value={option}>
                      {musicianPlans[option].label}
                    </StyledOption>
                  ))}
              </StyledSelect>

            {userData?.role == ADMIN && !notActiveFilter && (
              <SecondaryButton onClick={() => setNotActiveFilter(true)}>
                Filtra non attivi
              </SecondaryButton>
            )}

            {userData?.role == ADMIN && notActiveFilter && (
              <DeletableButton onClick={() => setNotActiveFilter(false)}>
                Vedi tutti
              </DeletableButton>
            )}
          </div>
          {(place || !!selectedGenres.length || !!selectedInstruments.length) &&  (
            <div>
              <SecondaryButton
                onClick={() => {
                  setDateR('');
                  setPlace('');
                  setCloseData([]);
                  setShowClosest(false);
                  setLoadingMusicians(true);
                  setSelectedGenres([])
                  setSelectedInstruments([])
                  setMaxDistance(80);
                  setReset(true);
                  navigate('/musicians');
                }}
              >
                Reset
              </SecondaryButton>
            </div>
          )}
        </HorizontalBar>
      )}
      {showClosest && !url.includes('/planners') && (
        <HorizontalBar
          margin='0px 0px 40px 0px'
          gap={'40px'}
          displayResponsive='block'
          mediaQuery={'768'}
          direction={'column'}
        >
          <SecondaryButton disabled>Nelle vicinanze</SecondaryButton>
          <Slider
            defaultValue={maxDistance}
            min={10}
            max={80}
            step={10}
            onChange={(e) => {
              setMaxDistance(e);
            }}
            styles={{
              track: {
                backgroundColor: '#a7a3ff',
                height: '5px',
              },
            }}
            activeDotStyle={{
              backgroundColor: '#a7a3ff',
              borderColor: '#f0efff',
            }}
            // trackStyle={{ backgroundColor: "#a7a3ff", }}

            marks={heightMarks}
            dots
          />
        </HorizontalBar>
      )}

      <HorizontalBar margin='30px 0px 20px 0px' spaceBetween={true}>
        <SmallText>
          <span style={{ fontWeight: 'bold' }}>
            {!showClosest && data.filter((x) => filterData(x)).length}
            {showClosest && closeData.filter((x) => filterData(x)).length}
          </span>{' '}
          {!url.includes('/planners') && 'musicisti disponibili'}
          {url.includes('/planners') && 'organizzatori disponibili'}
          {showClosest && ` a ${maxDistance} Km da ${place?.raw.name} `}
        </SmallText>
      </HorizontalBar>
      {instrumentPopup && (
        <Popup
          title={'Scegli fino a 5 strumenti'}
          onClose={() => setInstrumentPopup(false)}
        >
          <TwoColumnGridContainer>
            {musicalInstruments.map((x) => (
              <SelectableCard
                selected={selectedInstruments.includes(x.id)}
                onClick={() => toggleInstrument(x.id)}
                key={x.id}
              >
                {/* <TagIcon src={x.img} alt={x.name} $pt={'0'} /> */}
                <p>{x.label}</p>
              </SelectableCard>
            ))}
          </TwoColumnGridContainer>
        </Popup>
      )}

      {genrePopup && (
        <Popup
          title={'Scegli il genere'}
          content='Contenuto '
          onClose={() => setGenrePopup(false)}
        >
          <TwoColumnGridContainer>
            {genres.map((x) => (
              <SelectableCard
                selected={selectedGenres.includes(x.id)}
                onClick={() => toggleGenre(x.id)}
                key={x.id}
              >
                <p>{x.label}</p>
              </SelectableCard>
            ))}
          </TwoColumnGridContainer>
        </Popup>
      )}

      {requestOpen && (
        <Popup
          title={'Apri una richiesta con ' + requestOpen.name}
          content='Contenuto del Popup'
          onClose={() => {
            setRequestOpen(null);
          }}
          onConfirm={() => {
            console.log('Azione confermata');

          
            setRequestOpen(null);
          }}
        >
          <RequestForm
            ref={requestFormRef}
            place={place}
            dateV={dateRRef.current}
          />
        </Popup>
      )}

      {validateOpen && (
        <Popup
          title={'Vuoi validare ' + validateOpen.name + ' ?'}
          content={
            'Convalidando il profilo di ' +
            validateOpen.name +
            ' lo rendi visibile a tutti e attivo sulla piattaforma'
          }
          onClose={() => {
            console.log(validateOpen);
            setValidateOpen(null);
          }}
          onConfirm={() => {
            console.log('Azione confermata');
            //createRequest();
            onValidate();
          }}
        ></Popup>
      )}

      {disableOpen && (
        <Popup
          title={'Vuoi disabilitare ' + disableOpen.name + ' ?'}
          content={
            'Disabilitando il profilo di ' +
            disableOpen.name +
            ' lo rendi invisibile a tutti e disattivo sulla piattaforma'
          }
          onClose={() => {
            console.log(disableOpen);
            setDisableOpen(null);
          }}
          onConfirm={() => {
            console.log('Azione confermata');
            //createRequest();
            onDisable();
          }}
        ></Popup>
      )}

      {!loadingMusicians &&
        !data.filter((x) => filterData(x)).length &&
        place &&
        !showClosest && (
          <div
            style={{
              height: '50vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h4>:(</h4>
            <Text>
              {groupFilter!="" && groupFilter!=SINGOLO?"Nessuna formazione disponibile":"Nessun musicista disponibile"} a {place.label.split(',')[0]}{' '}
              {closeData.length <= 0 && 'e dintorni'}
              {groupFilter!="" && groupFilter!=SINGOLO && ". Prova a cercare i musicisti singolarmente"}
            </Text>
            {closeData.length > 0 && (
              <SecondaryButton onClick={() => setShowClosest(true)}>
                Mostra nelle vicinanze
              </SecondaryButton>
            )}
          </div>
        )}
      {!loadingMusicians &&
        !data.filter((x) => filterData(x)).length &&
        !place && (
          <div
            style={{
              height: '50vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {groupFilter=="" && <Text>
              Nessun musicista{' '}
              {userData && userData.role == ADMIN && notActiveFilter
                ? 'da validare'
                : 'disponibile'}
            </Text>}
            {groupFilter!="" && <Text>
              Nessuna formazione disponibile, prova a cercare i musicisti singolarmente
            </Text>}
          </div>
        )}

      {!loadingMusicians &&
        !closeData.filter((x) => filterData(x)).length &&
        showClosest && (
          <div
            style={{
              height: '50vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h4>:(</h4>
            {(groupFilter=="" || groupFilter==SINGOLO) && <Text>Nessun musicista disponibile</Text>}
            {groupFilter!="" && groupFilter!=SINGOLO && <Text>Nessuna formazione disponibile, prova a cercare i musicisti singolarmente</Text>}
          </div>
        )}

      {loadingMusicians && (
        <div
          style={{
            height: '50vh',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Loader />
        </div>
      )}

      {/** MAIN */}
      {!loadingMusicians && (
        <GridContainer>
          {data
            .filter((x) => filterData(x))
            .slice(0, limit)
            .map((profile, index) => (
              <MainCard
                id={profile.id}
                key={profile.id}
                loading={validatingMusician.includes(profile.id)}
                planner={url.includes('/planners')}
                role={userData ? userData.role : USER}
                imageSrc={profile.image}
                overlayText={'over1'}
                profile={profile}
                onClick={() =>
                  userData && userData.role === ADMIN
                    ? window.open(
                        `/admin/${
                          url.includes('/planners') ? 'planners' : 'musicians'
                        }/${profile.id}`,
                        '_blank'
                      )
                    : window.open('/musicians/' + profile.id, '_blank')
                }
                onRequest={(name) =>
                  setRequestOpen({ id: profile.id, name: name })
                }
                onValidate={(name) =>
                  setValidateOpen({ id: profile.id, name: name })
                }
                onDisable={(name) =>
                  setDisableOpen({ id: profile.id, name: name })
                }
                setShowLoginPopup={setShowLoginPopup}
              />
            ))}
        </GridContainer>
      )}
      <div style={{ alignItems:'center', justifyContent:'center', display:'flex'}} >
        {data.filter((x) => filterData(x)).length > limit && (
          <DeletableButton onClick={() => setLimit(limit + 6)}>
            Mostra altri
          </DeletableButton>
        )}
      </div>
      {showClosest && closeData.length > 0 && (
        <div>
          {/** NWIGHBOROOD */}
          <GridContainer>
            {closeData
              .filter((x) => filterData(x))
              .slice(0, limit)
              .map((profile, index) => (
                <MainCard
                  id={profile.id}
                  key={profile.id}
                  loading={validatingMusician.includes(profile.id)}
                  planner={url.includes('/planners')}
                  role={userData ? userData.role : USER}
                  imageSrc={profile.image}
                  overlayText={'over1'}
                  profile={profile}
                  onClick={() =>
                    userData && userData.role === ADMIN
                      ? window.open(
                          `/admin/${
                            url.includes('/planners') ? 'planners' : 'musicians'
                          }/${profile.id}`,
                          '_blank'
                        )
                      : window.open('/musicians/' + profile.id, '_blank')
                  }
                  onRequest={(name) =>
                    setRequestOpen({ id: profile.id, name: name })
                  }
                  onValidate={(name) =>
                    setValidateOpen({ id: profile.id, name: name })
                  }
                  onDisable={(name) =>
                    setDisableOpen({ id: profile.id, name: name })
                  }
                  setShowLoginPopup={setShowLoginPopup}
                />
              ))}
          </GridContainer>
          {closeData.filter((x) => filterData(x)).length > limit && (
            <DeletableButton onClick={() => setLimit(limit + 6)}>
              Mostra altri
            </DeletableButton>
          )}
        </div>
      )}
    </>
  );
}

export default SearchMusicians;
