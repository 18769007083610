import styled from 'styled-components';

export const HorizontalSplitLayout = styled.div`
  height: ${(props) => (props.$height ? props.$height : 'auto')};
  max-height: ${(props) => (props.$height ? props.$height : 'initial')};
  overflow: hidden;
  display: flex;
  align-items: ${({ $alignItems }) => ($alignItems ? $alignItems : 'center')};
  justify-content: center;
  width: 100%;
  margin: ${({ $margin }) => ($margin ? $margin : '0')};
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    display: ${({ $display }) => ($display ? $display : 'block')};
    margin: 0 0 20px 0;
    padding: 20;
  }
`;

export const LeftContent = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 30px;
    .slick-slider {
      width: 100%;
    }
    .slick-arrow {
      display: none !important;
    }
    .slick-dots {
      display: none !important;
    }
  }
  .slick-prev {
    color: wheat;
    z-index: 10;
    left: 5px;
  }

  .slick-next {
    color: wheat;
    z-index: 10;
    right: 5px;
  }

  .slick-list {
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .slick-initialized .slick-slide {
    height: 350px;

    > div {
      height: 100%;
    }
  }
`;

export const RightContent = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: ${({ $alignItems }) => ($alignItems ? $alignItems : 'center')};
  flex-direction: column; 
  height: ${({ $rightHeight }) => ($rightHeight ? $rightHeight : 'auto')};
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    height: auto;
  }
`;

export const InnerRightContent = styled.div`
 
  width: ${(props) => (props.width ? props.width : '50%')};
`;

export const RightContainer = styled.div`
  padding-inline: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
