import styled, { css } from 'styled-components';

export const RequestCardContainer = styled.div`
  padding: 10px;
  border-radius: 4px;
    margin-bottom: 10px;
  background-color: #f0efff;
  
  color: black;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  text-align: center; /* Aggiunto per allineare il testo al centro */
  display:flex;
  flex-direction:row;
  position:relative;
  padding:20px;

 
`;

export const HamBurgerMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 999;
  
`;

export const DotsMenu = styled.div`
  display:none;
  ${props =>
    props.$isOpen &&
    css`
      display: flex;
    `}
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position:absolute;
  top: 62px;
  right: 20px;
  background: #f1f2f3;
  z-index: 1;
  border: 1px solid #ccc; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
  border-radius: 4px; 
  width: 200px
  
    
`;

export const RejectedRequestCardContainer = styled.div`
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #ccc; /* Colore di sfondo per il tasto disabilitato */
  color: black;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  text-align: center; /* Aggiunto per allineare il testo al centro */
  display:flex;
  flex-direction:row;

 
`;

export const ExpiredRequestCardContainer = styled.div`
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #ccc; /* Colore di sfondo per il tasto disabilitato */
  color: black;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  text-align: center; /* Aggiunto per allineare il testo al centro */
  display:flex;
  flex-direction:row;

  
`;