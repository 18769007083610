import { Circle, ProgressContainer } from "./ProgressIndicatorElements";

const ProgressIndicator = ({ totalSteps, currentStep }) => {
    const circles = [];
  
    for (let i = 0; i < totalSteps; i++) {
      circles.push(<Circle key={i} $active={i === currentStep} />);
    }
  
    return <ProgressContainer>{circles}</ProgressContainer>;
  };
  
  export default ProgressIndicator;