import { AWS } from "../config/URLs";

async function newAnnouncement(request, jwtToken) {
  const requestObject = {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
    body: JSON.stringify(request),
  };

  const response = await fetch(AWS + "/announcements", requestObject);
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
}

async function applyToTheAnnouncement(id, jwtToken) {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    })
  };

  const response = await fetch(AWS + "/announcements/"+ id+"/apply", requestObject);
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
}

async function getAnnouncement(id, jwtToken) {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
  };

  const response = await fetch(AWS + "/announcements/" + id, requestObject);
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
}

async function getAnnouncements(jwtToken) {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
  };

  const response = await fetch(AWS + "/announcements", requestObject);
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
}

async function closeAnnouncement(id, jwtToken) {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
  };

  const response = await fetch(AWS + "/announcements/" + id+"/close", requestObject);
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
}

async function cancelAnnouncement(id, jwtToken) {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
  };



  const response = await fetch(AWS + "/announcements/" + id+"/cancel", requestObject);
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
}

async function enableAnnouncement(id, jwtToken) {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
  };



  const response = await fetch(AWS + "/announcements/" + id+"/enable", requestObject);
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
}

async function getMyApplications(jwtToken) {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
  };

  const response = await fetch(AWS + "/musician/applications", requestObject);
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
}

async function getApplications(id, jwtToken) {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
  };

  const response = await fetch(AWS + "/announcements/" + id+"/applications", requestObject);
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
}

export {
  newAnnouncement,
  getAnnouncement,
  getAnnouncements,
  closeAnnouncement,
  cancelAnnouncement,
  getMyApplications,
  applyToTheAnnouncement,
  getApplications,
  enableAnnouncement
};
