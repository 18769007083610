import styled from 'styled-components';

export const FavoritesButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  align-items: center;
  cursor: pointer;

  @media (max-width: 850px) {
    margin: 8px;
    margin-bottom: 2px;
  }

  @media (max-width: 768px) {
    display: ${({ $display }) => ($display ? $display : 'block')};
  }
`;
