import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import PlaceSelector from "../../../functionals/PlaceSelector/PlaceSelector";
import LargeTextInput from "../../../inputs/LargeTextInput/LargeTextInput";
import { StyledDatePicker } from "../PersonalDataForm/PersonalDataFormElements";
import Input from "../../../inputs/TextInput/TextInput";

const RequestForm = forwardRef(
  ({ dateV, place, notes, preventive, disabled = false }, ref) => {
    const [dove, setDove] = useState(place);
    const [date, setDate] = useState(dateV);
    const [details, setDetails] = useState(notes);

    const dateRef = useRef();
    dateRef.current = date;

    const detailsRef = useRef();
    detailsRef.current = details;

    const handleDoveChange = (value) => {
      setDove(value);
    };

    const handleQuandoChange = (eventDate) => {
      setDate(eventDate);
    };

    const getRequestValues = () => {
      return {
        place: dove,
        date: date,
        details: detailsRef.current,
      };
    };

    useImperativeHandle(ref, () => ({
      getRequestValues: getRequestValues,
    }));

    return (
      <>
        <StyledDatePicker
          selected={dateRef.current}
          onChange={(eventDate) => handleQuandoChange(eventDate)}
          dateFormat="MMMM d, yyyy"
          placeholderText="Quando?"
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          $border="none"
          minDate={new Date()}
          disabled={disabled}
        />
        <PlaceSelector
          placeholder="Dove?"
          valueP={dove}
          onChange={handleDoveChange}
          disabled={disabled}
          bgColor="#f0efff"
        />

        {disabled && details && <Input disabled value={details} />}

        {disabled && preventive && <Input disabled value={preventive} />}
        {!disabled && (
          <LargeTextInput
            type="text"
            value={detailsRef.current}
            width={"100%"}
            onChange={(e) => setDetails(e.target.value)}
            placeholder="Scrivi qui ulteriori dettagli..."
            margin={"10px 0 0 0"}
          />
        )}
      </>
    );
  }
);

export default RequestForm;
