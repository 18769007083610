import styled from 'styled-components';

export const ImageContainer = styled.div`
  width: ${(props) => (props.$size ? props.$size+"px" : '134px')};;
  height: ${(props) => (props.$size ? props.$size+"px" : '134px')};;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  cursor: ${(props) => (props.$modify ? 'pointer' : 'auto')};
`;

export const Image = styled.img`
  width: auto;
  height: 100%;
  object-fit: cover;
`;