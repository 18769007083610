import { RESET } from "../actions/GeneralActions";

const generalReducer = (state, action) => {
  switch (action.subtype) {
    case RESET:
      return {  musicians: [],
        userData: null, };
    default:
      return state;
  }
};

export default generalReducer;