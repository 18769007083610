import { AWS } from "../config/URLs";

async function getMe(jwtToken) {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
  };

  try {
    const response = await fetch(AWS + "/me", requestObject);

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
}

async function updateMe(data, jwtToken, errF, succF) {
  const requestObject = {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
    body: JSON.stringify(data),
  };

  fetch(AWS + "/me", requestObject)
    .then(async (response, err) => {
      if (!response.ok) {
        const res = await response.json();
        const error = new Error(`${res}`);
        error.response = res;
        throw error;
      }
      return response.json();
    })
    .then((res) => {
      succF(res);
    })
    .catch((err) => {
      errF(err?.response?.message?.reason)
    });
}

export { getMe, updateMe };
