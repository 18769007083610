import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color:  ${({ $bkgColor }) => $bkgColor || '#fff'};
  align-items:  ${({ $centered }) => $centered || 'flex-start'};
  justify-content: center;
  margin: ${(props) => (props.$margin ? props.$margin : '0 20px')};
  padding: ${(props) => (props.$padding ? props.$padding : '0')};
  box-shadow: ${(props) => (props.$boxShadow ? props.$boxShadow : 'none')};
  border-radius: 10px;
  @media (max-width: 768px) {
    align-items: ${({ $mobileAlign, $centered }) =>
      $mobileAlign ? $mobileAlign : $centered || 'flex-start'};
    margin: ${({$mobileMargin}) => $mobileMargin || '20 0px'};
  }
  width:100%;
  box-sizing: border-box;
`;

export const Content = styled.div`
  width: 100%;
  display: ${({ $fullWidth }) => ($fullWidth ? 'block' : 'flex')};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 30px
  
  @media (max-width: 768px) {
    display: ${({ $display }) => ($display ? $display : 'flex')};
  }
  
`;

export const AbsoluteContainer = styled.div`
  position: fixed;
  top: 80px;
  margin: 0 20px;
  @media (max-width: 768px) {
    top: 60px;
  }
`;
