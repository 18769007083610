import { ADMIN } from "../actions/AdminActions";
import { GENERAL } from "../actions/GeneralActions";
import { MUSICIANS } from "../actions/MusiciansActions";
import { REQUESTS } from "../actions/RequestsActions";
import { USERS } from "../actions/UserActions";
import adminReducer from "./AdminReducers";
import generalReducer from "./GeneralReducer";
import musiciansReducer from "./MusiciansReducers";
import requestsReducer from "./RequestsReducers";
import userReducer from "./UserReducers";

const rootReducer = (state, action) => {
  switch (action.type) {
    case USERS:
      return userReducer(state, action);

    case MUSICIANS:
      return musiciansReducer(state, action);

    case REQUESTS:
      return requestsReducer(state, action);

    case ADMIN:
      return adminReducer(state, action);

    case GENERAL:
      return generalReducer(state, action);

    default:
      return state;
  }
};

export default rootReducer;
