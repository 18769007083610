import { AWS } from "../config/URLs";

async function newPlanner(user, jwtToken, errF, succF) {
  const requestObject = {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
    body: JSON.stringify(user),
  };

  fetch(AWS + "/planners/new", requestObject)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((res) => {
      succF(res);
    })
    .catch((err) => errF(err));
}

async function getPlanner(user, jwtToken) {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    })
  };

  try {
    let response = await fetch(AWS + "/planners/get/" + user, requestObject);
    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      return new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }

  
}

async function getPlanners(jwtToken) {
  const requestObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
  };

  try {
    let response = await fetch(AWS + "/planners", requestObject);
    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function updatePlanner(data, jwtToken, errF, succF) {
  const requestObject = {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    }),
    body: JSON.stringify(data),
  };

  fetch(AWS + "/planners/update", requestObject)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((res) => {
      succF(res);
    })
    .catch((err) => errF(err));
}

export { newPlanner, getPlanner, updatePlanner, getPlanners };
