import styled from 'styled-components';

export const SliderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Arrow = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  margin: 0 10px;
`;
