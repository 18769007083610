/**
 * Object represeting the whole initial state of the app on which reducers will operate
 */

const InitialState = {
  musicians: [],
  userData: null,
};

export default InitialState;
