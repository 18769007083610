import { useState } from 'react';
import {
  Container,
  DeleteIcon,
  ExpandIcon,
  SecondaryContainer,
} from './ExpandableCardsElements';

import {
  faTrash,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'; // Sostituisci con l'icona desiderata
import { SmallText, Text } from '../../texts/TextsElements';

const ExpandableCards = ({
  title,
  isNotExpandible,
  modify,
  secondaryItems,
  onSongsDelete,
  onGenreDeleteClick,
  onAddSongsClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(isNotExpandible ? true : false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <Container
        disabled={isNotExpandible}
        onClick={() => (!isNotExpandible ? toggleExpanded() : {})}
      >
        {((!isNotExpandible && !!secondaryItems.length) || modify) && (
          <ExpandIcon
            color={'black'}
            icon={isExpanded ? faMinus : faPlus}
            size='xs'
          />
        )}
        <Text>{title}</Text>
        {modify && (
          <DeleteIcon
            color={'#F0EFFF'}
            icon={faTrash}
            onClick={() => onGenreDeleteClick(title)}
          />
        )}
      </Container>
      {isExpanded && secondaryItems && (
        <>
          {secondaryItems.map((x, i) => (
            <SecondaryContainer key={i}>
              <SmallText $fontSize='18px'>{x}</SmallText>
              {modify && <DeleteIcon icon={faTrash} onClick={() => onSongsDelete(title,x)} />}
            </SecondaryContainer>
          ))}
        </>
      )}
      {isExpanded && modify && secondaryItems && secondaryItems.length < 3 && (
        <SecondaryContainer onClick={() => onAddSongsClick(secondaryItems, title)}>
          <Text>Aggiungi un brano</Text>
        </SecondaryContainer>
      )}
    </>
  );
};

export default ExpandableCards;
