import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

export const ReviewContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1%;

  @media (max-width: 768px) {
    display: ${({ $displayResponsive }) => ($displayResponsive ? $displayResponsive : 'flex')};
  }
`;

export const StarIcon = styled(FontAwesomeIcon)`
  color: #ffd700;
  margin: 4px;
  width:20px;
`;
