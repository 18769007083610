import React, { useState } from 'react';
import styled from 'styled-components';
import { SecondaryClickableText } from '../../presentationals/buttons/SecondaryButton/SecondaryButtonElements';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 30px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const Label = styled.label`
  font-size: 16px;
`;

const Link = styled.a`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  margin: 0 5px;

  &:hover {
    text-decoration: none;
  }
`;

const TermsCheckbox = ({isChecked, onChange}) => {



  return (
    <Container>
      <Checkbox
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
        id="terms"
      />
      <Label htmlFor="terms">
        Accetto l' {' '}
        <SecondaryClickableText onClick={()=>window.open("/datiPersonali")} >
          informativa sul trattamento dei dati personali
        </SecondaryClickableText> 
        {' '}e i {' '}
        <SecondaryClickableText onClick={()=>window.open("/terminiCondizioni")} >
          termini e le condizioni di utilizzo
        </SecondaryClickableText> .
      </Label>
    </Container>
  );
};

export default TermsCheckbox;
