
import styled, { keyframes } from 'styled-components';

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
`;

export const SecondaryBtn = styled.button`
position: relative;
padding: 10px 0px;
color: ${({ $color }) => ($color ? $color : '#df6951')};
border: none;
border-radius: 20px;
font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : '16px')};
font-weight: ${({ $fontWeight }) => ($fontWeight ? $fontWeight : 'bold')};
text-decoration: none; /* Aggiunge la sottolineatura */
cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')}; /* Imposta il cursore appropriato */
display: ${({ $display }) => ($display ? 'none' : 'block')};
text-align: center;
/* Stili per lo stato normale */
background-color: transparent;
transition: color 0.3s; /* Cambia solo il colore del testo */

/* Stili per lo stato di hover */
&:not(:disabled):hover {
  text-decoration: underline; /* Cambia il colore del testo durante l'hover */
}

/* Stili per lo stato di click */
&:not(:disabled):active {
  animation: ${({ disabled }) => (disabled ? 'none' : pulseAnimation)} 0.2s ease-in-out;
}
@media (max-width: 850px) {
  margin: 5px; 
  margin-top: 0;
  padding: 5px 0px;
}
@media (max-width: 768px) {
  display: block;
}

`;


export const SecondaryClickableText = styled.span`

color: #9A84BC;

font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : '16px')};
font-weight: ${({ $fontWeight }) => ($fontWeight ? $fontWeight : 'bold')};
text-decoration: none; /* Aggiunge la sottolineatura */
cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')}; /* Imposta il cursore appropriato */

`;

