const errorMessages = {
  form_identifier_exists:
    "Questo indirizzo email è già in uso. Si prega di provare con un altro.",
  form_password_length_too_short:
    "La password deve essere lunga almeno 8 caratteri.",
  form_passwords_do_not_match:
    "Le password non corrispondono. Si prega di riprovare.",
  form_identifier_not_found:
    "Credenziali non valide. Si prega di verificare l'indirizzo email e la password.",
  form_invalid_credentials:
    "Credenziali non valide. Si prega di verificare l'indirizzo email e la password.",
  form_email_not_verified:
    "L'indirizzo email non è stato verificato. Controlla la tua casella di posta per il link di verifica.",
  form_email_verification_failed:
    "La verifica dell'indirizzo email è fallita. Si prega di contattare l'assistenza.",
  form_param_format_invalid:
    "Credenziali non valide. Si prega di verificare l'indirizzo email e la password.",
  form_email_required:
    "L'indirizzo e-mail è obbligatorio",
  form_invalid_email: "indirizzo email non valido",
  form_pass_required:
  "E 'richiesta la password",
  form_confirm_pass_required:
  "È richiesta la conferma della password",
  form_password_pwned:
  "La password è stata trovata in una violazione dei dati online. Per la sicurezza dell'account, utilizza una password diversa.",
  form_password_incorrect:
  "La password non è corretta. Riprova"
  // Aggiungi altri messaggi di errore se necessario
};

export default errorMessages;
