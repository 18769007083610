import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css, keyframes } from 'styled-components';

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  background-color: #a7a3ff;
  color: #f0efff;
  border: none;
  border-radius: 15px;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  position: relative;
  width: 100%;
  height: 60px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  @media (max-width: 768px) {
    height: 50px;
  }
`;

export const SecondaryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: #f0efff;
  color: #a7a3ff;
  width: 100%;
  border: none;
  border-radius: 15px;
  position: relative; /* Rendi il contenitore relativo per posizionare l'icona */
  height: 50px;

  cursor: ${(props) => (props.onClick ? 'pointer' : '')};
  /* Aggiungi l'animazione solo se onClick è definita */
  ${(props) =>
    props.onClick &&
    css`
      &:active {
        animation: ${pulseAnimation} 0.2s ease-in-out;
      }
    `}
`;

export const Icon = styled(FontAwesomeIcon)`
  position: absolute; /* Rendi l'icona assoluta */
  left: 10px; /* Posiziona l'icona alla sinistra del contenitore */
  cursor: pointer;
  color: #f0efff;
`;

export const DeleteIcon = styled(FontAwesomeIcon)`
  position: absolute; /* Rendi l'icona assoluta */
  right: 10px; /* Posiziona l'icona alla sinistra del contenitore */
  cursor: pointer;
  &:active {
    animation: ${pulseAnimation} 0.2s ease-in-out;
  }

  color: ${(props) => (props.color ? props.color : '#A7A3FF')};
`;

export const ExpandIcon = styled(FontAwesomeIcon)`
  position: absolute; /* Rendi l'icona assoluta */
  left: 30px; /* Posiziona l'icona alla sinistra del contenitore */
  cursor: pointer;
  &:active {
    animation: ${pulseAnimation} 0.2s ease-in-out;
  }

  color: ${(props) => (props.color ? props.color : '#A7A3FF')};
`;
