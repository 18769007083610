import { SET_ADMIN } from "../actions/AdminActions";

const adminReducer = (state, action) => {
  switch (action.subtype) {
    case SET_ADMIN:
      return { ...state, admin: action.admin };
    default:
      return state;
  }
};

export default adminReducer;
