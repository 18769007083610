export default class Planner {
  
    isOnboardingCompleted = false

    constructor(id,email) {
        this.id = id;
        this.email = email;
    }
  
   
  }