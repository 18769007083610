
const AnnouncementStates = {
    OPEN:'OPEN',
    FULL:'FULL', 
    EXPIRED:'EXPIRED', 
    CLOSED: 'CLOSED',
    CANCELED: "CANCELED",
    PENDING: 'PENDING'
}

const AnnouncementBudgets = ["150-200",
    "250-300",
    "300-350",
    "350-400",
    "450-500",
    ">500"
    ]



export {AnnouncementStates,AnnouncementBudgets}