import React from 'react';
import styled from 'styled-components';

export const FooterContainer = styled.div`
  display: flex;
  justify-content:center;
  align-items: center;
  bottom: 0;
  z-index:999;
  width:100%;
  height:100%;
  background-color: #000000;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

export const FooterContent = styled.div`
  display: flex;
  width:80%;
  flex-direction: row;
  justify-content: space-between;

  
`;

export const LeftText = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

export const RightElements = styled.div`
  display: flex;
  gap: 10px;
`;

export const DesktopDiv = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (max-width: 768px) {
    display: none;
  }
`;



