async function getToken(session) {
  if (session.user) {
    let token = await session.getToken();
    console.log("TOKEN: ", token);
    return token;
  }
  return null;
}

export default getToken;
