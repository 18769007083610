import { faPlus } from '@fortawesome/free-solid-svg-icons'; // Sostituisci con l'icona desiderata
import IconButton from '../IconButton/IconButton';
import { Image, ImageContainer } from './SelectableSquareButtonElements';
import { VerticalLayout } from '../../Containers/layouts/VerticalLayout/VerticalLayout';
import { BoldText } from '../../texts/TextsElements';

const SelectableSquareButton = ({ imageSrc, width, onClick, selected, label, bkgColor }) => {
  console.log("Width: ",width)
  return (
    <VerticalLayout >
      <ImageContainer bkgColor={'#9a84bc'} size={width} onClick={onClick} selected={selected}>
        {/* {imageSrc && <Image src={imageSrc} alt='Card Image' />}
        {!imageSrc && <IconButton icon={faPlus} />} */}
        <BoldText selected={selected}>{label}</BoldText>
      </ImageContainer>
      {/* <BoldText>{label}</BoldText> */}
    </VerticalLayout>
  );
};

export default SelectableSquareButton;
