import styled from 'styled-components';
import { GridContainer } from '../GridContainerElements';
import SelectableCard from '../../../cards/SelectableCard/SelectableCard';
import { SelectableCardContainer } from '../../../cards/SelectableCard/SelectableCardElements';

export const TwoColumnGridContainer = styled(GridContainer)`
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));

    // ${SelectableCardContainer}:nth-child(even) {
    //   justify-self: unset;
    // }
  }
`;

export const TwoColumnGridAnnouncementContainer = styled(GridContainer)`
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);

    // ${SelectableCardContainer}:nth-child(even) {
    //   justify-self: unset;
    // }
  }
`;

export const TwoColumnGridWithMargin = styled.section`
  margin: 20px 0;
  
`;

export const TwoColumnResponsiveGrid = styled(GridContainer)`
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  grid-gap: 15px;
  padding-bottom: 20px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, minmax(160px, 1fr));
  }

  @media (max-width: 380px) {
    grid-template-columns: repeat(2, minmax(140px, 1fr));
  }

  @media (max-width: 300px) {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }
`;