import styled from 'styled-components';

export const MainLayoutContainer = styled.div`
  width: 100%;
  padding-top: 0;
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InnerLayoutContainer = styled.div`
  margin-top: ${(props) => (props.$noMargin ? '0' : '90px')};
  width: 80%;
  max-width: 1300px;
  @media (max-width: 768px) {
    width: ${({ $fullWidth }) => $fullWidth || '80%'};
    margin-top: ${(props) =>
      props.$noMargin ? '0' : props.$isInvalid ? '110px' : '90px'};
  }
`;

export const SigninContainer = styled.div`
  @media (max-width: 850px) {
    margin-top: 14px;
    margin-bottom: 8px;
  }
`;

export const OuterContainer = styled.div`
  margin: ${({ $adminMargin }) => ($adminMargin ? '145px 0px 0' : '100px 0px 0')};
  

  @media (max-width: 768px) {
  padding:20px;
  margin: ${({ $adminMargin }) => ($adminMargin ? '90px 0px 0' : '0px 0px 0')};
  }
`;