import { useEffect } from "react";
import { HalfScreenInnerContainer, HalfScreenInnerContainerLarge, HalfScreenMainContainer } from "./HalfScreenElements";
import { Heading } from "../../../../../screens/home/HomeElements";
import Images from "../../../../../utils/Images";


const HalfScreen = ({ bgColor, title, large=false, color, style, children}) => {

   

    return (
      <HalfScreenMainContainer style={style} $bgColor={bgColor} color={color}>
        {title && <Heading style={{ margin: '0' }}>{title}</Heading>}
        {!large && <HalfScreenInnerContainer>
          {children}
        </HalfScreenInnerContainer>}
        {large && <HalfScreenInnerContainerLarge>
          {children}
          </HalfScreenInnerContainerLarge>}
      </HalfScreenMainContainer>
    );
  };
  
  export default HalfScreen;