import Images from '../../../../utils/Images';
import { HorizontalLayout } from '../../Containers/layouts/HorizontalLayout/HorizontalLayout';
import { VerticalLayout } from '../../Containers/layouts/VerticalLayout/VerticalLayout';
import { Image, ImageContainer } from '../../buttons/SelectableSquareButton/SelectableSquareButtonElements';
import RoundImage from '../../media/RoundImage/RoundImage';
import { Title1 } from '../../texts/TextsElements';
import { RequestCardContainer } from './PaymentMethodCardElements';

const PaymentMethodCard = ({ title, data, place, status }) => {
  let requestCardComponent;

  requestCardComponent = (
    <>
      <RequestCardContainer>
        <HorizontalLayout>
          <ImageContainer selected style={{ border: 'none' }}>
            <Image src={Images.icons.bankTransferIcon} alt='Card Image' />
          </ImageContainer>
          <VerticalLayout>
            <Title1>{title}</Title1>
          </VerticalLayout>
        </HorizontalLayout>
      </RequestCardContainer>
    </>
  );

  return requestCardComponent;
};

export default PaymentMethodCard;
