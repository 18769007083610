function NotFoundPage(props) {
  return (
    <>
      <p>pagina non esistentee</p>
      <button
        onClick={() =>
          (document.location.href = document.location.origin + "/")
        }
      >
        vai alla home
      </button>
    </>
  );
}

export default NotFoundPage;
