export const SINGOLO = "SINGOLO";
export const DUO = "DUO";
export const TRIO = "TRIO";
export const QUARTETTO = "QUARTETTO";
export const MORE = "MORE";

export const musicianPlans =  {
    DUO: {label: "Duo", number: 2},
    TRIO: {label: "Trio", number: 3},
    QUARTETTO: {label: "Quartetto", number: 4},
    QUINTETTO: {label:"Quintetto", number: 5},
    MORE: {label:"More", number: 10}
}