import { Header } from '../../../Header/Header';
import {
  InnerLayoutContainer,
  MainLayoutContainer
} from './MainLayoutElements';
import { useStateValue } from '../../../../../stores/StateProvider';


const MainLayout = ({ children, noMargin, fullWidth }) => {
  const [{ userData }] = useStateValue();


  return (
    <MainLayoutContainer>
      <Header/>
      <InnerLayoutContainer $noMargin={noMargin} $fullWidth={fullWidth} $isInvalid={!userData?.valid}>
      
        {children}
      </InnerLayoutContainer>
    </MainLayoutContainer>
  );
};

export default MainLayout;
