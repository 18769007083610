import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components';

const PartnersSliderWrapper = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
`;

const SlideContainer = styled.div`
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 250px;
    margin: 0 10px; /* Aggiunge margine tra le slides */
`;

const StyledImg = styled.img`
    max-height: 150px;
    max-width: 100%;
    object-fit: contain;
`;

const PartnersSlider = () => {
    const [settings] = useState({
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: 'linear',
        arrows: false
    });

    const logos = [
        require("../../../assets/logo/cocaCola.png"),
        require("../../../assets/logo/ferrari.png"),
        require("../../../assets/logo/ferrero.png"),
        require("../../../assets/logo/kimbo.png"),
        require("../../../assets/logo/lamborghini.png"),
        require("../../../assets/logo/lavazza.png"),
    ];

    return (
        <PartnersSliderWrapper>
            <h2 style={{color:"#fff"}}>I nostri partner</h2>
            <Slider {...settings}>
                {logos.map((logo, index) => (
                    <SlideContainer key={index}>
                        <StyledImg src={logo} alt='logo' />
                    </SlideContainer>
                ))}
            </Slider>
        </PartnersSliderWrapper>
    );
};

export default PartnersSlider;
