import { useState } from "react";
import { SmallText } from "../../texts/TextsElements";
import { Image, ImageContainer } from "./RoundImageElements";

const RoundImage = ({ imageSrc, size, modify, onChange }) => {
    const [isUploading, setIsUploading] = useState(false);
  
    const handleImageClick = () => {
      if (modify) {
        // Se modify è true, permetti all'utente di caricare un'altra foto
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.addEventListener('change', handleFileInputChange);
        fileInput.click();
      }
    };
  
    const handleFileInputChange = (e) => {
        onChange(e.target.files[0])
      
    };
  
    return (
      <ImageContainer $size={size} $modify={modify} onClick={handleImageClick}>
        {isUploading ? (
          <SmallText>Caricamento...</SmallText>
        ) : (
          <Image src={imageSrc} alt="Card Image" />
        )}
      </ImageContainer>
    );
  };
  
  export default RoundImage;