import { LabelText, SwitchContainer, SwitchInput, SwitchLabel, SwitchSlider } from "./SwitchElements";

const Switch = ({ checked, labelLeft, labelRight, onChange }) => {
    return (
      <SwitchContainer >
        {labelLeft && <LabelText>{labelLeft}</LabelText>}
        <SwitchLabel>
          <SwitchInput type="checkbox" checked={checked} onChange={onChange} />
          <SwitchSlider />
        </SwitchLabel>
        {labelRight && <LabelText>{labelRight}</LabelText>}
      </SwitchContainer>
    );
  };
  
  export default Switch;
  