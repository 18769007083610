import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { forwardRef, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import languagesList from '../../../../data/languages';
import errorMessages from '../../../../utils/ClerkUtilities/ErrorsMessages';
import { emailPattern } from '../../../../utils/String';
import Popup from '../../../functionals/Popup/Popup';
import Input from '../../../inputs/TextInput/TextInput';
import { TwoColumnGridContainer } from '../../Containers/GridContainer/TwoColumnsGridContainer/TwoColumnsGridContainer';
import DeletableButton from '../../buttons/DeletableButton/DeletableButton';
import { IconButtonContainer } from '../../buttons/IconButton/IconButtonElements';
import {
  Tag,
  TagContainer,
  TagIcon,
} from '../../cards/MainCard/MainCardElements';
import SelectableCard from '../../cards/SelectableCard/SelectableCard';
import { ErrorText, Text } from '../../texts/TextsElements';
import { FullSizeContainer } from '../PaymentMethods/PaymentMethodsFormElements';
import {
  InputWithError,
  StyledForm,
} from '../PersonalDataForm/PersonalDataFormElements';
import { uploadFile } from '../../../../remote/bucket';
import getToken from '../../../../userManagement/utilities';
import { useClerk } from '@clerk/clerk-react';
import SecondaryButton from '../../buttons/SecondaryButton/SecondaryButton';
import Switch from '../../../functionals/Switch/Switch';

const MoreInfoForm = forwardRef(
  (
    {
      tmpUserRef,
      submitForm,
      submitRef,
      isMusicianRole,
      formWidth,
      resetForm,
      fullWidth,
      fileUrl,
      loading
    },
    ref
  ) => {
    const [languages, setLanguages] = useState([]);
    const [filePdf,setFilePdf] = useState(null);
    const [languagePopup, setLanguagePopup] = useState(false);
    const [file,setFile] = useState(fileUrl)
    const {
      register,
      handleSubmit,
      formState: { errors },
      control,
      setValue,
      trigger,
    } = useForm({
      defaultValues: {

        conservatory: '',
        file: '',
        languages: '',
        companyName: '',
        companyLink: '',
        companyEmail: '',
        car: false
      },
      mode: 'all',
    });

    useEffect(()=>{
      setFile(fileUrl)
      console.log("File url: ", fileUrl)
    },[fileUrl])

    useEffect(() => {
      if (tmpUserRef?.current) {
        if (isMusicianRole) {
          if (tmpUserRef.current?.languages) {
            setValue('languages', tmpUserRef.current?.languages);
            setLanguages(tmpUserRef.current?.languages);
          }
         
          setValue('conservatory', tmpUserRef.current?.conservatory);
          setValue('car',tmpUserRef.current?.car)
        } else {
          setValue('companyName', tmpUserRef.current?.companyName);
          setValue('companyLink', tmpUserRef.current?.companyLink);
          setValue('companyEmail', tmpUserRef.current?.companyEmail);
        }
      }
    }, [setValue, tmpUserRef, resetForm]);

    const handleLanguageToggle = ({ id, label }) => {
      let tmp = [...languages];
      if (languages.some((language) => language.id === id)) {
        tmp = tmp.filter((x) => x.id !== id);
      } else if (tmp.length < 3) {
        tmp.push({ id, label });
      }
      tmpUserRef.current = { ...tmpUserRef.current, languages: tmp };
      setLanguages(tmp);
      setValue('languages', tmp);
      trigger('languages');
    };


    const handlePdfUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const fileData = {
            url: reader.result,
            name: file.name,
            file:file
          };
          setFilePdf(fileData);
          setValue("file", fileData);
          trigger("file");
        };
        reader.readAsDataURL(file);
      }
    };


    const openPdfPreview = (url) => {
      const pdfWindow = window.open();
      pdfWindow.document.write(
        `<iframe src="${url}" width="100%" height="100%"></iframe>`
      );
    };

    const {session} = useClerk();

  

    
    
   

    return (
      <StyledForm
        ref={ref}

        onSubmit={handleSubmit(async (data) => {
          let t = await getToken(session)
          if (filePdf)
            uploadFile(filePdf.file,tmpUserRef.current.id+".pdf",t, (error)=>console.log("error: ",error),()=>{console.log("heeeei");submitForm(data)})
          else
          submitForm(data,filePdf?{file:filePdf.file,name:tmpUserRef.current.id+".pdf"}:null)
        })}
        $width={formWidth}
      >
        
        {languagePopup && (
          <Popup
            title={'Scegli le tue lingue'}
            onClose={() => setLanguagePopup(false)}
            onConfirm={() => {
              setLanguagePopup(false);
            }}
          >
            <TwoColumnGridContainer>
              {languagesList.map((x) => (
                <SelectableCard
                  selected={
                    languages &&
                    languages.some((language) => language.id === x.id)
                  }
                  onClick={() => handleLanguageToggle(x)}
                  key={x.id}
                >
                  <p>{x.label}</p>
                </SelectableCard>
              ))}
            </TwoColumnGridContainer>
          </Popup>
        )}
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <FullSizeContainer $fullWidth={fullWidth}>
              {isMusicianRole && (
                <>
                  
                  
                  {/* <TagContainer $display='flex'>
                    <DeletableButton
                      onClick={(e) => {
                        e.preventDefault();
                        handlePreviewClick();
                      }}
                      bgColor={'#F0EFFF'}
                      color={'#A7A3FF'}
                    >
                      Preview
                    </DeletableButton>
                  </TagContainer> */}
                 
                  
                  <InputWithError>
                    <Text $textAlign={'center'}>
                      {'Conservatorio di provenienza'}
                    </Text>
                    <Input
                      type='text'
                      placeholder={'Inserisci qui il nome del conservatorio'}
                      {...register('conservatory', {
                        required: 'E\' richiesto il nome del conservatorio',
                      })}
                      $active={!!errors?.conservatory?.message}
                    />
                    {errors.conservatory && (
                      <ErrorText>{errors.conservatory.message}</ErrorText>
                    )}
                  </InputWithError>

                  {!file && <InputWithError>
                      <Input
                            id='upload-button'
                            type='file'
                            accept='.pdf'
                            {...register('file')}
                            onChange={handlePdfUpload}
                            display={"none"}
                          />
                      {!filePdf && (
                        <DeletableButton
                          onClick={(e) => {
                            e.preventDefault();
                            document.getElementById('upload-button').click();
                          }}
                          bgColor={'#F0EFFF'}
                          color={'#A7A3FF'}
                        >
                          Inserisci il tuo diploma o un'autocertificazione
                        </DeletableButton>
                      )}
                      {filePdf && (
                        <div style={{display:'flex',flexDirection:'row', gap:"10px"}}>
                          <DeletableButton
                            onClick={(e) => {
                              e.preventDefault();
                              openPdfPreview(filePdf.url);
                            }}
                            bgColor={'#F0EFFF'}
                            color={'#A7A3FF'}
                          >
                            {filePdf.name}
                          </DeletableButton>
                          <SecondaryButton
                            onClick={(e) => {
                              e.preventDefault();
                              setFilePdf(null);
                              setValue('file', null);
                            }}
                            bgColor={'#FF6347'}
                            color={'#FF6347'}
                          >
                            Rimuovi
                          </SecondaryButton>
                        </div>
                      )}
                      {errors.file && (
                        <ErrorText>{errors.file.message}</ErrorText>
                      )}
                    </InputWithError>
                  }
                  {file && <div style={{display:'flex',flexDirection:'row', gap:"10px"}}>
                          <DeletableButton
                            onClick={(e) => {
                              e.preventDefault();
                              openPdfPreview(fileUrl);
                            }}
                            bgColor={'#F0EFFF'}
                            color={'#A7A3FF'}
                          >
                            Vedi il file
                          </DeletableButton>
                          <SecondaryButton
                            onClick={(e) => {
                              e.preventDefault();
                              setFile(null);
                            }}
                            bgColor={'#FF6347'}
                            color={'#FF6347'}
                          >
                            Sovrascrivi
                          </SecondaryButton>
                        </div> }

                 
                  <Text $textAlign={'left'}>{'Quali lingue parli?'}</Text>
                  <TagContainer
                    $display='flex'
                    {...register('languages', {
                      validate: (value) =>
                        value.length > 0 ||
                        'Per piacere, seleziona almeno una lingua',
                    })}
                  >
                    
                    {languages &&
                      languages.map((x, index) => {
                        const currentLanguage = x.label;
                        return (
                          <Tag key={index}>
                            <TagIcon
                              src={
                                languagesList.filter(
                                  (language) => language.id == x.id
                                )[0].img
                              }
                              alt={x.name}
                              style={{ paddingTop: '0' }}
                            />
                            {currentLanguage}
                            <IconButtonContainer
                              icon={faXmark}
                              color={'black'}
                              // size={'md'}
                              style={{ paddingLeft: '5px' }}
                              onClick={() => {
                                handleLanguageToggle(x);
                              }}
                            />
                          </Tag>
                        );
                      })}
                    <Tag
                      $color={'white'}
                      $bgColor={'#a7a3ff'}
                      onClick={() => {
                        console.log('clicked');
                        setLanguagePopup(true);
                      }}
                      $cursor={'pointer'}
                    >
                      {'Aggiungi'}
                    </Tag>
                  </TagContainer>
                  {errors.languages && (
                    <ErrorText>{errors.languages.message}</ErrorText>
                  )}

                  <InputWithError>
                                  <Controller
                                      control={control}
                                      name='car'
                                      
                                      render={({field}) => (
                                        <Switch labelLeft={"Sei automunito?"} checked={field.value} onChange={()=>{field.onChange(!field.value)}} />
                                      )}
                                    />
                                  {errors.city && <ErrorText>{errors.car.message}</ErrorText>}
                  
                  </InputWithError>

                  
                </>
              )}
              {!isMusicianRole && (
                <>
                  
                  <InputWithError>
                  <Text $textAlign={'center'}>
                    {'Qual è il nome della tua azienda?'}
                  </Text>
                    <Input
                      type='text'
                      placeholder={'Inseerisci il nom dell’azieenda'}
                      {...register('companyName', {
                        required: "il nome dell'azienda è obbligatorio",
                      })}
                      $active={!!errors?.companyName?.message}
                    />
                    {errors.companyName && (
                      <ErrorText>{errors.companyName.message}</ErrorText>
                    )}
                  </InputWithError>
                  <input
                        id='upload-button'
                        type='file'
                        accept='.pdf'
      
                        onChange={(event) => {
                          const files = Array.from(event.target.files);

                          const promises = files.map((file) => {
                            return new Promise((resolve, reject) => {
                              const reader = new FileReader();
                              reader.onloadend = function () {
                                resolve({ imageUrl: reader.result, file: file });
                              };
                              reader.onerror = reject;
                              // uploadImage(file,"prova.png")
                              reader.readAsDataURL(file);
                            });
                          });

                          Promise.all(promises).then((imageUrls) => {
                            console.log('imageUrls', imageUrls);
                            // field.onChange([...field.value, ...imageUrls]);
                          });
                        }}
                        //style={{ display: 'none' }}
                      />
                  
                  
                  <InputWithError>
                  <Text $textAlign={'center'}>{'Email aziendale'}</Text>
                    <Input
                      type='companyEmail'
                      placeholder={'Inserisci qui la mail aziendale'}
                      {...register('companyEmail', {
                        required: errorMessages.form_email_required,
                        pattern: {
                          value: emailPattern,
                          message: errorMessages.form_invalid_email,
                        },
                      })}
                      $active={!!errors?.companyEmail?.message}
                    />
                    {errors.companyEmail && (
                      <ErrorText>{errors.companyEmail.message}</ErrorText>
                    )}
                  </InputWithError>
                </>
              )}
            </FullSizeContainer>
            <button ref={submitRef} type='submit' style={{ display: 'none' }} />
          </div>
        </>
      </StyledForm>
    );
  }
);

export default MoreInfoForm;
