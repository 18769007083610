import styled from 'styled-components';

export const WholeScreenMainContainer = styled.div`
  width: 100%;
  
  background: ${({ $bgColor }) => ($bgColor ? $bgColor : '#fff')};;
  height: 100vh;
  display:flex;

  justify-content: center;
  align-items: center;
  flex-direction: column;
  
`;

export const MainContainer = styled.div`
  width: 100%;
  
  background: ${({ $bgColor }) => ($bgColor ? $bgColor : '#fff')};;
  display:flex;

  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  
`;



export const WholeScreenInnerContainer = styled.div`
  margin: 5%;
  display: flex;
  justify-content: space-between;
  max-width:1300px;
  
  align-items: center;
  padding-top:50px;
  width: calc(100% - 10%);
  height: calc(100% - 10%);
  @media (max-width: 1024px) {
    flex-direction: column;
  }
    
`;

export const WholeScreenInnerContainerBkg = styled.div`
  margin: 5%;
  display: flex;
  justify-content: space-between;
  max-width:1300px;
  
  align-items: center;
  padding:50px;
  width: calc(100% - 10%);
  height: calc(100% - 10%);
  @media (max-width: 1024px) {
    flex-direction: column;
    
  }
    @media (max-width: 768px) {
    justify-content: center;
    
  }
     background: url(${(props) => props.$bkgImg});
  background-size: cover; 
  background-position: center;
  border-radius: 20px;
`;

export const InnerContainer = styled.div`
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
  max-width:1300px;
  position: relative;
  
  
  align-items: flex-start;
  padding-top:50px;
  width: calc(100% - 10%);
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;
