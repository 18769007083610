

export default class Musician {

   
    isOnboardingCompleted = false
  
  
    constructor(id,email) {
        this.id = id;
        this.email = email;
    }

   

    

  }