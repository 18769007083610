import {
  HorizontalSplitLayout,
  LeftContent,
  RightContent,
} from './HSplitScreenElements';

const HSplitScreen = ({
  left,
  right,
  height,
  outerMargin,
  outerPadding,
  alignItems,
  rightSideAlignItems,
  display,
  rightHeight,
}) => {
  return (
    <HorizontalSplitLayout
      $height={height}
      $margin={outerMargin}
      $padding={outerPadding}
      $alignItems={alignItems}
      $display={display}
    >
      <LeftContent>{left}</LeftContent>
      <RightContent $alignItems={rightSideAlignItems} $rightHeight={rightHeight}>{right}</RightContent>
    </HorizontalSplitLayout>
  );
};

export default HSplitScreen;
