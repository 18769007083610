import { VerticalLayout } from "../../../components/presentationals/Containers/layouts/VerticalLayout/VerticalLayout";
import DeletableButton from "../../../components/presentationals/buttons/DeletableButton/DeletableButton";
import PaymentMethodsForm from "../../../components/presentationals/forms/PaymentMethods/PaymentMethodsForm";


function Payment(props) {
	

	return (
			<PaymentMethodsForm />
			
	);
}

export default Payment;