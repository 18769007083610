import styled from 'styled-components';

export const CenteredDiv = styled.div`
  display: ${({ $display }) => $display || 'flex'};
  justify-content: center;
  align-items: center;
  @media (min-width: 850px) {
    width: ${({ $width }) => $width || '90%'};
    display: block;
  }
  @media (min-width: 1050px) {
    width: ${({ $width }) => $width || '80%'};
    display: block;
  }
`;
