import styled from 'styled-components';

export const HorizontalBarContainer = styled.div`
  display: flex;
  visibility: ${({ $hidden }) => ($hidden ? 'hidden' : 'default')};
  width: 97%;
  height: auto;
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : 'transparent')};
  color: ${({ $color }) => ($color ? $color : 'black')};
  align-items: center;
  margin: ${({ $margin }) => ($margin ? $margin : '0')};
  padding: ${({ $padding }) => ($padding ? $padding : '0')};

  @media (max-width: 768px) {
    display: ${({ $displayResponsive }) => ($displayResponsive ? $displayResponsive : 'flex')};


  }
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  justify-content: ${({ $spaceBetween }) =>
    $spaceBetween ? 'space-between' : 'flex-start'};
  margin: ${({ $margin }) => ($margin ? $margin : '0')};
  gap: ${({ $gap }) => ($gap ? $gap : '0')};
  
  @media (max-width: ${({ $mediaQuery }) =>
      $mediaQuery ? $mediaQuery : '480'}px) {
    flex-direction: ${({ $direction }) => ($direction ? $direction : 'row')};
    gap: 10px;
  }
`;
