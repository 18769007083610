import {
  faCalendarPlus,
  faLocationDot,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import IconButton from '../../presentationals/buttons/IconButton/IconButton';
import { StyledDatePicker } from '../../presentationals/forms/PersonalDataForm/PersonalDataFormElements';
import { SmallText, TextLink } from '../../presentationals/texts/TextsElements';
import PlaceSelector from '../PlaceSelector/PlaceSelector';
import {
  InputContainer,
  InputWithIcon,
  SearchContainer,
} from './SearchBarElements';


const SearchBar = ({ tutorial, onSearch, place, date, disabledDate }) => {
  const [dove, setDove] = useState('');
  const [quando, setQuando] = useState('');

  const handleDoveChange = (value) => {
    setDove(value);
  };

  const handleQuandoChange = (eventDate) => {
    setQuando(eventDate);
  };

  const handleCercaClick = () => {
    const dateObject = quando ? new Date(quando) : new Date();

    const formattedDate = dateObject.toLocaleDateString('fr-CA', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    onSearch(dove, formattedDate);
  };

  useEffect(() => {
    setDove(place);
    if (date) {
      setQuando(new Date(date));
    } else {
      setQuando('');
    }
  }, [place, date]);

  return (
    <div style={{display:'flex',flexDirection:'column', width:"100%"}}>
    <SearchContainer>
      <InputContainer direction="column">
        <InputWithIcon>
          <FontAwesomeIcon icon={faLocationDot} style={{width:"18px"}} color='#9484bc'/>
        
          <PlaceSelector placeholder='Dove?' valueP={dove} onChange={handleDoveChange} minWidth />
        </InputWithIcon>
        {!disabledDate && <InputWithIcon>
          <FontAwesomeIcon icon={faCalendarPlus} style={{width:"20px"}} color='#9a84bc' />
          <StyledDatePicker
            selected={quando}
            onChange={(eventDate) => handleQuandoChange(eventDate)}
            dateFormat='MMMM d, yyyy'
            placeholderText='Quando?'
            showYearDropdown
            showMonthDropdown
            dropdownMode='select'
            $border='none'
            $backgroundColor='transparent'
           
            minDate={new Date()}
          />
        </InputWithIcon>}
      </InputContainer>
      {/* <DeletableButton onClick={handleCercaClick}>
        Cerca
      </DeletableButton> */}
      <IconButton icon={faSearch} onClick={handleCercaClick} />
      
    </SearchContainer>
      {tutorial && <div style={{paddingTop:"20px", paddingLeft:"10px", display:'flex',alignItems:'flex-start'}}>
      <TextLink href={"/howitworks"} target={"_blank"}  $textColor={"#000"} $textAlign='center'> Come funziona?</TextLink>
      
      </div>}
    </div>
  );
};

export default SearchBar;
