import { useClerk, useUser } from '@clerk/clerk-react';
import {
  faContactCard
} from '@fortawesome/free-regular-svg-icons'; // Sostituisci con l'icona desiderata
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'; // Sostituisci con l'icona desiderata
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import YouTube from 'react-youtube';
import Popup from '../../../components/functionals/Popup/Popup';
import TitledContainer from '../../../components/presentationals/Containers/TitledContainer/TitledContainer';
import HSplitScreen from '../../../components/presentationals/Containers/layouts/HSplitScreen/HSplitScreen';
import { HorizontalLayout } from '../../../components/presentationals/Containers/layouts/HorizontalLayout/HorizontalLayout';
import { RightFixedButton } from '../../../components/presentationals/buttons/DeletableButton/DeletableButtonElements';
import IconButton from '../../../components/presentationals/buttons/IconButton/IconButton';
import { IconButtonContainer } from '../../../components/presentationals/buttons/IconButton/IconButtonElements';
import ExpandableCards from '../../../components/presentationals/cards/ExpandableCards/ExpandableCards';
import {
  Tag,
  TagContainer,
  TagIcon,
} from '../../../components/presentationals/cards/MainCard/MainCardElements';
import RequestForm from '../../../components/presentationals/forms/RequestForm/RequestForm';
import ReviewsCounterIndicator from '../../../components/presentationals/indicators/ReviewsCounterIndicator/ReviewsCounterIndicator';
import SquareImage from '../../../components/presentationals/media/SquareImage/SquareImage';
import { IconText, SmallText, Text, TextLink } from '../../../components/presentationals/texts/TextsElements';
import RequestsStates from '../../../config/RequestsStates';
import { ADMIN, MUSICIAN, PLANNER } from '../../../config/profile/Roles';
import eventsList from '../../../data/events';
import genres from '../../../data/genres';
import musicalInstruments from '../../../data/instruments';
import languagesList from '../../../data/languages';
import Request from '../../../models/Request';
import { newRequest } from '../../../remote/requests';
import SignInPopup from '../../../userManagement/signIn/signInPopup';
import getToken from '../../../userManagement/utilities';
import Loader from '../../../utils/Loader';
import { Paragraph } from '../../home/HomeElements';
import {
  FloatRightButton,
  LocationContainer,
  MoreInfoContainer,
  RightContainer,
  VideoContainer,
} from './PublicProfileElements';
import Carousel from '../../../components/functionals/Carousel/Carousel';
import RoundImage from '../../../components/presentationals/media/RoundImage/RoundImage';
import { abbreviateSurname } from '../../../utils/String';
import axios from 'axios';
import { MORE, musicianPlans } from '../../../config/musicianPlans';

function PublicProfile({ musician, userData }) {
  const [details, setDetails] = useState('');
  const [date, setDate] = useState('');
  const [place, setPlace] = useState('');
  const detailsRef = useRef();
  detailsRef.current = details;
  const dateRef = useRef();
  dateRef.current = date;
  const placeRef = useRef();
  placeRef.current = place;
  const { session } = useClerk();
  const [loading, setLoading] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const profilePicRef = useRef();
  profilePicRef.current = profilePic;

  const {user} = useUser()


  const [popupVisible, setPopupVisible] = useState(false);
  const [adminContactPopup, setadminContactPopup] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const requestFormRef = useRef();
  
  const { isSignedIn } = useUser();

  const [isMobile, setIsMobile] = useState(false);

  const [isFileAvailable, setIsFileAvailable] = useState(false);
  const [isCVAvailable, setIsCVAvailable] = useState(false);
  const fileUrl = `https://orchestra-diploma.s3.eu-central-1.amazonaws.com/${musician.id}.pdf`;
  const cvUrl = `https://orchestra-diploma.s3.eu-central-1.amazonaws.com/cv/${musician.id}.pdf`;

  useEffect(() => {
    // Funzione per verificare l'esistenza del file
    const checkFileExists = async () => {
      try {
        await axios.head(fileUrl);
        setIsFileAvailable(true);
      } catch (error) {
        setIsFileAvailable(false);
      }
    };

    const checkCVExists = async () => {
      try {
        await axios.head(cvUrl);
        setIsCVAvailable(true);
      } catch (error) {
        setIsCVAvailable(false);
      }
    };

    checkFileExists();
    checkCVExists();
  }, [fileUrl]);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia("(max-width: 767px)").matches;
      setIsMobile(isMobile);
    };

    handleResize(); // Controlla le dimensioni iniziali

    window.addEventListener("resize", handleResize); // Aggiungi un listener per rilevare i cambiamenti di dimensione

    return () => {
      window.removeEventListener("resize", handleResize); // Rimuovi il listener quando il componente viene smontato
    };
  }, []);

  const closePopup = () => {
    setPopupVisible(false);
  };

  const createRequest = async (requestData) => {
    console.log(requestData);

    let t = await getToken(session);
    await newRequest(
      new Request(
        '-1',
        popupVisible.id,
        userData.id,
        requestData.date,
        requestData.place,
        RequestsStates.ISSUED,
        requestData.details,
        popupVisible.name,
        userData.name
      ),
      t
    );
  };


  console.log("USEEEER: ",user)

  useEffect(() => {
    if (isSignedIn) setShowLoginPopup(false);
  }, [isSignedIn]);

  const opts = {
    height: '390',
    width: '640',
  };

  const calculateAge = () => {
    const birthDate = new Date(musician.dateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  if (loading) return <Loader />;

  let videoId = '';
  let videoId2 = '';

  try {
    videoId = musician.premium && musician.premium.link?.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/i
    )[1];
  } catch {}

  try {
    videoId2 =musician.premium && musician.premium.link2?.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/i
    )[1];
  } catch {}


  
  return (
    <div>
      {showLoginPopup && (
        <SignInPopup setShowLoginPopup={() => setShowLoginPopup(false)} />
      )}

      {/* {userData?.role !== MUSICIAN && (
        <RightFixedButton
          onClick={() => {
            if (!isSignedIn) {
              setShowLoginPopup(true);
            } else {
              if (userData?.role !== ADMIN) openPopup();
              else setadminContactPopup(true);
            }
          }}
        >
          <IconButton
            color={'white'}
            text={`Fai una richiesta`}
            display='flex'
          />
        </RightFixedButton>
      )} */}

      

      <HSplitScreen
        width='100%'
        outerMargin='20px 0'
        display='none'
        left={<></>}
        right={
          <HorizontalLayout
            $justifyContent='flex-end'
            // style={{ paddingLeft: '20px' }}
          >
            <TagContainer>
              {musician.role === MUSICIAN &&
                musician.instruments &&
                musician.instruments.map((x, index) => {
                  const currentGenre = musicalInstruments.filter(
                    (y) => y.id === x.id
                  )[0].label;
                  return (
                    <Tag key={index}>
                      {/* <TagIcon
                        key={index}
                        src={
                          musicalInstruments.filter(
                            (instrument) => instrument.id == x.id
                          )[0].img
                        }
                        alt={x.name}
                      /> */}
                      {currentGenre}
                    </Tag>
                  );
                })}
              {musician.role === PLANNER &&
                musician.events &&
                musician.events.map((x, index) => {
                  const currentGenre = eventsList.filter(
                    (y) => y.id === x.id
                  )[0].label;
                  return <Tag key={index}>{currentGenre}</Tag>;
                })}
            </TagContainer>
          </HorizontalLayout>
        }
      />
      <HSplitScreen
        outerPadding={'0px 0px 40px 0px'}
        outerMargin={'0 0 40px 0'}
        width={'100%'}
        alignItems={'flex-start'}
        rightSideAlignItems={'flex-end'}
        left={
          
          // <Carousel isDots={true} isAutoplay={true}>
          //   {musician.profileImage && (
          //     <SquareImage src={musician.profileImage} />
          //   )}
          
            
           
          //   {/* {musician.gallery.map((x, i) => (
          //     <SquareImage key={i} src={x.imageUrl} />
          //   ))} */}
          // </Carousel>
         
            <RoundImage
              imageSrc={user.profileImageUrl}
              modify={false}
              
            />
           
           
        }
        right={
          <RightContainer>
             <TitledContainer
                margin={'0 0 10px 0'}
                titleMargin={'0 0 10px 0'}
                title={
                  'Ciao, sono ' + musician.firstName + ' ' + abbreviateSurname(musician.lastName)
                }
                mobileAlign={"center"}
                contentDisplay={'none'}
                textAlignMobile={'center'}
                textAlign={'right'}
                centered={'flex-end'}
              >
                
                <Paragraph $textAlign={'left'}>{musician.premium && musician.premium.aboutYou}</Paragraph>
                
              </TitledContainer>
            
            <HorizontalLayout style={{ flexDirection: 'column', gap: '10px' }}>
            {musician.role === MUSICIAN && musician.group && musician.group.groupName && <MoreInfoContainer>
                <div
                  style={{
                    columnGap: '5px',
                    alignSelf: 'end',
                    display: 'flex',
                  }}
                >
                  
                    <>
                      
                      <IconText $fontSize={'18px'} $pb={'0'}>
                        Formazione:
                      </IconText>
                      <IconText $fontSize={'18px'} $pb={'0'}>
                        {musician.group.groupName} - {musician.group.groupType==musicianPlans[MORE].label.toLowerCase()?"Ensemble":musician.group.groupType}
                      </IconText>
                      
                    </>
                  
                  
                </div>
              </MoreInfoContainer>}
              <MoreInfoContainer>
                <div
                  style={{
                    columnGap: '5px',
                    alignSelf: 'end',
                    display: 'flex',
                  }}
                >
                  {musician.role === MUSICIAN && (
                    <div style={{display:'flex', flexDirection:'column', alignItems:isMobile?'center':'flex-end'}}>
                      <div style={{display:'flex',flexDirection:'row',columnGap:'5px'}}>
                        <IconText $fontSize={'18px'} $pb={'0'}>
                          Conservatorio:
                        </IconText>
                        <IconText $fontSize={'18px'} $pb={'0'}>
                          {musician.conservatory}
                        </IconText>
                      </div>
                      {isFileAvailable && <TextLink target='_blank' href={"https://orchestra-diploma.s3.eu-central-1.amazonaws.com/"+musician.id+".pdf"}  $textColor={"#000"} $textAlign='center'>(vedi il mio certificato)</TextLink>}
                      {!isFileAvailable && <TextLink $textColor={"#000"} $textAlign='center'>- nessun certificato disponibile - </TextLink>}
                      
                      
                    </div>
                  )}
                  {musician.role === PLANNER && (
                    <>
                      <IconText $fontSize={'18px'} $pb={'0'}>
                        Nome della ditta:
                      </IconText>
                      <IconText $fontSize={'18px'} $pb={'0'}>
                        {musician.companyName}
                      </IconText>
                    </>
                  )}
                </div>
              </MoreInfoContainer>
              <MoreInfoContainer
                style={{
                  width: '100%',
                  justifyContent: 'space-evenly',
                  display:'flex',
                  flexDirection:'column'
                }}
              >
                <LocationContainer>
                  <IconText $fontSize={'18px'} $pb={'0'}>
                    Citta':
                  </IconText>
                  <IconButtonContainer
                    icon={faLocationDot}
                    color={'black'}
                    size={'xs'}
                  />
                  <IconText $fontSize={'18px'} $pb={'0'}>
                    {musician.city?.raw?.name}
                  </IconText>
                </LocationContainer>
                <div
                  style={{
                    columnGap: '5px',
                    alignSelf: 'end',
                    display: 'flex',
                  }}
                >
                  <IconText $fontSize={'18px'} $pb={'0'}>
                    Eta':
                  </IconText>
                  <IconText
                    $fontSize={'18px'}
                    style={{ alignSelf: 'end' }}
                    $pb={'0'}
                  >
                    {`${calculateAge()} anni`}
                  </IconText>
                </div>
                {musician.role === MUSICIAN && (
                  <div
                    style={{
                      columnGap: '5px',
                      alignSelf: 'end',
                      display: 'flex',
                    }}
                  >
                    <IconText $fontSize={'18px'}>Lingue:</IconText>
                    {musician.languages?.map((x, index) => {
                      return (
                        <TagIcon
                          key={index}
                          src={
                            languagesList.filter(
                              (language) => language.id == x.id
                            )[0].img
                          }
                          alt={x.name}
                          $width='20px'
                          $height='20px'
                          $pr={
                            index === PLANNER.languages?.length - 1
                              ? '5px'
                              : '0'
                          }
                        />
                      );
                    })}
                  </div>
                )}
                {musician.role === MUSICIAN && musician.car &&  (
                  <div
                    style={{
                      columnGap: '5px',
                      alignSelf: 'end',
                      display: 'flex',
                    }}
                  >
                    <IconText $fontSize={'18px'}>Automunito</IconText>
                    
                  </div>
                )}
                {musician.role === MUSICIAN && isCVAvailable &&(
                  <div
                    style={{
                      columnGap: '5px',
                      alignSelf: 'end',
                      display: 'flex',
                    }}
                  >
                    <TextLink target='_blank' href={"https://orchestra-diploma.s3.eu-central-1.amazonaws.com/cv/"+musician.id+".pdf"}  $textColor={"#000"} $textAlign={'left'}>Vedi il mio CV</TextLink>
                  </div>
                )}
              </MoreInfoContainer>
              {musician.role === PLANNER && (
                <MoreInfoContainer>
                  <div
                    style={{
                      columnGap: '5px',
                      alignSelf: 'end',
                      display: 'flex',
                      overflowWrap: 'anywhere',
                    }}
                  >
                    <>
                      <IconText $fontSize={'18px'}>E-mail aziendale:</IconText>
                      <a
                        href={`mailto:${musician.companyEmail}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <IconText $fontSize={'18px'}>
                          {musician.companyEmail}
                        </IconText>
                      </a>
                    </>
                  </div>
                </MoreInfoContainer>
              )}
              {userData.role==ADMIN && <MoreInfoContainer>
                  <div
                    style={{
                      columnGap: '5px',
                      alignSelf: 'end',
                      display: 'flex',
                      overflowWrap: 'anywhere',
                    }}
                  >
                    <>
                      <IconText $fontSize={'18px'}>E-mail:</IconText>
                      <a
                        href={`mailto:${musician.email}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <IconText $fontSize={'18px'}>
                          {musician.email}
                        </IconText>
                      </a>
                    </>
                  </div>
                </MoreInfoContainer>}
            </HorizontalLayout>
            {/* {musician.role === MUSICIAN && (
              <HorizontalLayout $display='none'>
                <ReviewsCounterIndicator
                  numberOfStars={(musician.reviews && musician.reviews.length > 0
                    ? musician.reviews.reduce((a, b) => a + b.value, 0) /
                      musician.reviews.length
                    : 0
                  ).toFixed(1)}
                  numberOfComments={musician.reviews && musician.reviews.length?musician.reviews.length:0}
                />
              </HorizontalLayout>
            )} */}
          </RightContainer>
        }
      />
      {/* {userData?.role !== MUSICIAN && (
        <FloatRightButton
          onClick={() => {
            if (!isSignedIn) {
              setShowLoginPopup(true);
            } else {
              if (userData?.role !== ADMIN) openPopup();
              else setadminContactPopup(true);
            }
          }}
          $bottom={userData?.role == ADMIN?'160px':'90px'}
        >
          <IconButtonContainer icon={faContactCard} color={'white'} size='lg' />
        </FloatRightButton>
      )} */}

      <HorizontalLayout
        $justifyContent='flex-start'
        $display='none'
        $responsiveDisplay='block'
      >
        <TagContainer $display='flex'>
          {musician.role === MUSICIAN &&
            musician.instruments &&
            musician.instruments.map((x, index) => {
              const currentGenre = musicalInstruments.filter(
                (y) => y.id === x.id
              )[0].label;
              return (
                <Tag key={index}>
                  {/* <TagIcon
                    key={index}
                    src={
                      musicalInstruments.filter(
                        (instrument) => instrument.id == x.id
                      )[0].img
                    }
                    alt={x.name}
                  /> */}
                  {currentGenre}
                </Tag>
              );
            })}
          {musician.role === PLANNER &&
            musician.events &&
            musician.events.map((x, index) => {
              const currentGenre = eventsList.filter((y) => y.id === x.id)[0]
                .label;
              return <Tag key={index}>{currentGenre}</Tag>;
            })}
        </TagContainer>
      </HorizontalLayout>
      <HorizontalLayout $display='none'>
        <Paragraph $textAlign='center' style={{ paddingInline: '20px' }}>
          {musician?.premium?.aboutYou
            ? musician?.premium?.aboutYou
            : ''}
        </Paragraph>
      </HorizontalLayout>
      {musician.role === PLANNER && (
        <div
          style={{
            columnGap: '5px',
            display: 'flex',
            overflowWrap: 'anywhere',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <>
            <IconText $fontSize={'18px'}>Sito web aziendale:</IconText>
            <a
              href={`${musician.companyLink}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <IconText $fontSize={'18px'}>{musician.companyLink}</IconText>
            </a>
          </>
        </div>
      )}
      {musician.role === MUSICIAN && musician.premium && musician.premium.link && videoId && (
        <VideoContainer >
          <YouTube videoId={videoId} opts={opts} />
        </VideoContainer>
      )}
      {musician.role === MUSICIAN && musician.premium && musician.premium.link2 && videoId2 && (
        <VideoContainer >
          <YouTube videoId={videoId2} opts={opts} />
        </VideoContainer>
      )}
      {musician.role === MUSICIAN && (
        <TitledContainer
          title={'I miei cavalli di battaglia'}
          titleMargin={'0 0 20px 0'}
        >
          <>
            {musician.genres.map((x) => (
              <ExpandableCards
                key={x.id}
                isNotExpandible={false}
                title={genres.filter((y) => y.id === x.id)[0].label}
                secondaryItems={x.songs}
              />
            ))}
          </>
        </TitledContainer>
      )}
      {musician.role === MUSICIAN && musician.link && videoId && (
        <VideoContainer $displayResponsive='none'>
          <YouTube videoId={videoId} opts={opts} />
        </VideoContainer>
      )}
      {musician.role === MUSICIAN && musician.link2 && videoId2 && (
        <VideoContainer $display='none'>
          <YouTube videoId={videoId2} opts={opts} />
        </VideoContainer>
      )}
      {/* {musician.role === MUSICIAN && (
        <TitledContainer
          titleMargin={'20px 0'}
          title={'Dicono di me'}
          sideComponent={
            <ReviewsCounterIndicator
              numberOfStars={(musician.reviews && musician.reviews.length > 0
                ? musician.reviews.reduce((a, b) => a + b.value, 0) /
                  musician.reviews.length
                : 0
              ).toFixed(1)}
              numberOfComments={musician.reviews && musician.reviews.length?musician.reviews.length:0}
              displayResponsive={'none'}
            />
          }
        >
          {(!musician.reviews || !musician.reviews.length) && (
            <Paragraph $textAlign='center'>Non ci sono recensioni</Paragraph>
          )}
          {musician.reviews && !!musician.reviews.length &&
            musician.reviews.slice(0, limitReviews).map((x) => (
              <ReviewCard
                title={x.plannerName}
                numberOfStars={x.value}
                key={x.id}
              >
                {x.comment}
              </ReviewCard>
            ))}
          {musician.reviews && musician.reviews.length > limitReviews && (
            <DeletableButton onClick={() => setLimitReviews(limitReviews + 3)}>
              Mostra altre recensioni
            </DeletableButton>
          )}
        </TitledContainer>
      )} */}
      {popupVisible && (
        <Popup
          title={
            'Apri una richiesta con ' +
            musician.firstName +
            ' ' +
            musician.lastName
          }
          content='Contenuto del Popup'
          onClose={closePopup}
          onConfirm={() => {
            console.log('Azione confermata');
            createRequest(requestFormRef.current.getRequestValues());
            closePopup();
          }}
        >
          <RequestForm
            ref={requestFormRef}
            place={place}
            dateV={dateRef.current}
          />
        </Popup>
      )}

      {adminContactPopup && (
        <Popup
          title={
            'Informazioni di contatto per ' +
            musician.firstName +
            ' ' +
            musician.lastName
          }
          onClose={() => setadminContactPopup(false)}
          textAlign='center'
          alignItems={'flex-start'}
        >
          <div
            style={{
              gap: '10px',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '20px',
              fontSize: '18px',
              alignItems: 'flex-start',
              margin: 'auto auto 20px',
            }}
          >
            <>
              <div>
                <FontAwesomeIcon
                  icon={faPhone}
                  // color='#DF6951'
                  size='xs'
                ></FontAwesomeIcon>
                {/* <i class="fa-solid fa-phone"></i></i>   */}
                <span
                  style={{
                    marginLeft: '15px',
                    fontWeight: '300',
                  }}
                >
                  Phone
                </span>
                <span
                  style={{
                    fontWeight: '100',
                    marginLeft: '20px',
                  }}
                >
                  {musician.phone}
                </span>
              </div>

              <div>
                <FontAwesomeIcon icon={faEnvelope} size='xs'></FontAwesomeIcon>
                <span style={{ marginLeft: '15px', fontWeight: '300' }}>
                  Email
                </span>
                <span
                  style={{
                    fontWeight: '100',
                    marginLeft: '30px',
                  }}
                >
                  <a href={`mailto:${musician?.email}`}>{musician.email}</a>
                </span>
              </div>
            </>
          </div>
        </Popup>
      )}
      {/* {validateOpen && (
        <Popup
          title={
            'Vuoi validare ' +
            musician.firstName +
            ' ' +
            musician.lastName +
            ' ?'
          }
          content={
            'Convalidando il profilo di ' +
            musician.firstName +
            ' ' +
            musician.lastName +
            ' lo rendi visibile a tutti e attivo sulla piattaforma'
          }
          onClose={() => {
            console.log(validateOpen);
            setValidateOpen(null);
          }}
          onConfirm={() => {
            console.log('Azione confermata');
            //createRequest();
            onValidate();
          }}
        ></Popup>
      )} */}
    </div>
  );
}

export default PublicProfile;
