const emailPattern = /^[A-Z|a-z0-9._%+-]+@[A-Z|a-z0-9.-]+\.[A-Z|a-z]{2,}$/;
const pecPattern = /^[A-Z|a-z0-9._%+-]+@pec.it$/;

function abbreviateSurname(surname) {
    // Split the surname into words
    const words = surname.split(' ');
  
    // Map over each word to get the first letter and add a period
    const abbreviated = words.map(word => word.charAt(0).toUpperCase() + '.');
  
    // Join the abbreviated parts to form the final result
    return abbreviated.join('');
  }

  function dateTimeToString(date){
    date = date.split("T")[0]
    let dateFields = date.split("-")
    let day = dateFields[2]
    let month = dateFields[1]
    let year = dateFields[0]

    return day+"/"+month+"/"+year
  }

  export {abbreviateSurname, emailPattern, pecPattern, dateTimeToString}