

const plannerTabs = [
    
    {id:"profile",label:"Il tuo profilo"}, 
    {id:"announcements",label:"Annunci"},
    // {id:"settings",label:"Impostazioni e sicurezza"}
]

const musicianTabs = [
    
    {id:"profile",label:"Il tuo profilo"}, 
    {id:"applications",label:"Le mie candidature"},
    // {id:"settings",label:"Impostazioni e sicurezza"}
]

const adminTabs = [
    
    {id:"musicians",label:"Musicisti"}, 
    {id:"planners",label:"Organizzatori"}, 
    {id:"announcements",label:"Annunci"},
]

export {plannerTabs,musicianTabs, adminTabs};