import { Route, Routes } from 'react-router-dom';
import HorizontalSplitScreen from '../../components/presentationals/Containers/layouts/HorizontalSplitScreen/HorizontalSplitScreen';
import SignInForm from '../signIn/signIn';
import SignUpForm from '../signUp/signUp';
import VerifyEmail from '../signUp/verifyEmail';
import ResetPwd from '../resetPwd/resetPwd';

export default function Auth() {
  return (
    <HorizontalSplitScreen innerWidth={'50%'}>
      <Routes>
        <Route path='/' element={<SignInForm />} />
        <Route path='signin' element={<SignInForm />} />
        <Route path='signup' element={<SignUpForm />} />
        <Route path='confirm' element={<VerifyEmail />} />
        <Route path='reset' element={<ResetPwd />} />
      </Routes>
    </HorizontalSplitScreen>
  );
}