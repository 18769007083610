import styled from 'styled-components';

export const SelectableCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: black;
  border-radius: 20px;
  font-size: 16px;
  text-align: center; /* Aggiunto per allineare il testo al centro */
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  background-color: ${({ selected }) =>
    selected ? '#A7A3FF' : '#9a84bc'};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? '0.7' : '1')};
  
`;