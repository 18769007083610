import styled from 'styled-components';

export const HeaderDiv = styled.div`
  position: fixed;
  top: 40px;
  z-index: 10;
`;

export const ContentDiv = styled.div`
  /* Stili per il footer */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 480px;
  // min-height: 480px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #a7a3ff;
  }
  @media (max-width: 480px) {
    // height: 352px;
    // min-height: 352px;
  }
`;

export const ButtonContainerDiv = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

export const FooterDiv = styled.div`
  /* Stili per il footer */
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 10px;
  box-sizing: border-box;
  background: transparent;
  // @media (min-width: 1202px) {
  //   width: 45%;
  // }
  @media (max-width: 768px) {
    width: 100%;
    position: fixed;
    background: white;
  }
`;
