import { useUser } from '@clerk/clerk-react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import { InputContainer } from '../../../functionals/SearchBar/SearchBarElements';
import Switch from '../../../functionals/Switch/Switch';
import Input from '../../../inputs/TextInput/TextInput';
import { ErrorText } from '../../texts/TextsElements';
import {
  InputWithError,
  StyledForm,
  StyledOption,
  StyledSelect
} from './GroupDataFormElements';

const GroupDataForm = forwardRef(
  ({ tmpUserRef, submitForm, submitRef, formWidth, resetForm }, ref) => {
    const { user } = useUser();
    const [profilePic, setProfilePic] = useState(null);
    const profilePicRef = useRef();
    profilePicRef.current = profilePic;
    const [errImage,setErrImage] = useState("")
    const [isGroup, setIsGroup] = useState(false)

    const groupTypeOptions = [
      { value: 'duo', label: 'Duo' },
      { value: 'trio', label: 'Trio' },
      { value: 'quartetto', label: 'Quartetto' },
      { value: 'quintetto', label: 'Quintetto' },
      { value: 'more', label: 'Formazione più grande' },
    ];



    const {
      register,
      handleSubmit,
      formState: { errors },
      control,
      setValue,
      clearErrors
    } = useForm({
      defaultValues: {
        groupType:'',
        groupName: ''
      },
      mode: 'all',
    });

    const handleProfilePicUpload = async (file) => {
      try {
        console.log("Setting imae profile:",file);
        await user.setProfileImage({ file: file });
        return true
      } catch (error) {
        setProfilePic(null)
        setErrImage("Errore nel caricamento dell'immagine")
        return false
      } 
    };

    useEffect(() => {
      window.addEventListener('error', (e) => {
        if (
          e.message ===
          'ResizeObserver loop completed with undelivered notifications.'
        ) {
          const resizeObserverErrDiv = document.getElementById(
            'webpack-dev-server-client-overlay-div'
          );
          const resizeObserverErr = document.getElementById(
            'webpack-dev-server-client-overlay'
          );

          if (resizeObserverErr) {
            resizeObserverErr.setAttribute('style', 'display: none');
          }
          if (resizeObserverErrDiv) {
            resizeObserverErrDiv.setAttribute('style', 'display: none');
          }
        }
      });
    }, []);

    useEffect(() => {
      if (tmpUserRef?.current && tmpUserRef?.current?.group && tmpUserRef?.current?.group?.groupType ) {
        

        
          setIsGroup(true)
          setValue('groupName', tmpUserRef.current?.group.groupName);
          setValue('groupType', tmpUserRef.current?.group.groupType);
          
      }
      
    }, [setValue, tmpUserRef, resetForm]);

    useEffect(() => {
      if (!isGroup) {
        clearErrors('groupName');
        clearErrors('groupType');
      }
    }, [isGroup, clearErrors]);

    return (
      <StyledForm
        ref={ref}
        $width={formWidth}
        onSubmit={handleSubmit(async (data) => {

          if (!isGroup){
            data={}
          }
        
       
         
          if (profilePic) 
            handleProfilePicUpload(profilePicRef.current)
         
          submitForm({group: data});
        })}
      >
        <div
          style={{
            width: '100%',
     
            display: 'flex',
            gap: '8px',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Switch labelLeft={"Hai una formazione?"} checked={isGroup} onChange={()=>{console.log("Is group: ",isGroup); setIsGroup(!isGroup)}} />
              {isGroup && <><InputWithError>
                  <Input
                    type='text'
                    display={isGroup?null:'none'}
                    placeholder='Nome formazione'
                    {...register('groupName', {
                      required: isGroup ? 'Il nome della formazione è obbligatorio' : false,
                    })}
                    disabled={!isGroup}
                    
                    $active={!!errors?.groupName?.message}
                  />
                  
                  
                  {errors.groupName && <ErrorText>{errors.groupName.message}</ErrorText>}
                </InputWithError>
                <InputWithError>
                <InputContainer>
                  <StyledSelect
                    name='group'
                    disabled={!isGroup}
                    $active={!!errors?.groupType?.message}

                    {...register('groupType', {
                      required: 'Il tipo di formazione è obbligatoria',
                    })}
                  >
                    <StyledOption value=''>Seleziona il tipo di formazione</StyledOption>
                    {groupTypeOptions.map((option) => (
                      <StyledOption key={option.value} value={option.value}>
                        {option.label}
                      </StyledOption>
                    ))}
                  </StyledSelect>
                </InputContainer>
                {errors.groupType && <ErrorText>{errors.groupType.message}</ErrorText>}
              </InputWithError>
              </>}
          
          {/* <TwoColumnGridWithMargin>
          
            <TwoColumnGridContainer>      
              
              
            </TwoColumnGridContainer>
          </TwoColumnGridWithMargin> */}
          <button ref={submitRef} type='submit' style={{ display: 'none' }} />
        </div>
      </StyledForm>
    );
  }
);

export default GroupDataForm;
