import { forwardRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Popup from '../../../functionals/Popup/Popup';
import LargeTextInput from '../../../inputs/LargeTextInput/LargeTextInput';
import Input from '../../../inputs/TextInput/TextInput';
import DeletableButton from '../../buttons/DeletableButton/DeletableButton';
import { ErrorText, Text } from '../../texts/TextsElements';
import { CharCounter } from '../AboutYouForm/AboutYouFormElements';
import { FullSizeContainer } from '../PaymentMethods/PaymentMethodsFormElements';
import {
  InputWithError,
  StyledForm,
} from '../PersonalDataForm/PersonalDataFormElements';
import getToken from '../../../../userManagement/utilities';
import { useClerk } from '@clerk/clerk-react';
import { uploadFile } from '../../../../remote/bucket';
import { SecondaryBtn } from '../../buttons/SecondaryButton/SecondaryButtonElements';
import SecondaryButton from '../../buttons/SecondaryButton/SecondaryButton';
const MAX_CHARS = 500;

const PremiumMusicianForm = forwardRef(
  (
    {
      tmpUserRef,
      submitForm,
      submitRef,
      isMusicianRole,
      formWidth,
      resetForm,
      fullWidth,
    },
    ref
  ) => {
    const [charCount, setCharCount] = useState(0);
    const [languages, setLanguages] = useState([]);
    const [filePdf, setFilePdf] = useState(null);

    const [showPreviewPopup, setShowPreviewPopup] = useState(false);
    const [showPreview2Popup, setShowPreview2Popup] = useState(false);
    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
      trigger,
    } = useForm({
      defaultValues: {
        link: '',
        conservatory: '',
        languages: '',
        companyName: '',
        companyLink: '',
        companyEmail: '',
      },
      mode: 'all',
    });

    useEffect(() => {
      if (tmpUserRef?.current && tmpUserRef.current.premium) {
  
        
          setValue('link', tmpUserRef.current.premium.link);
          setValue('link2', tmpUserRef.current.premium.link2);
          setValue('aboutYou', tmpUserRef.current?.premium.aboutYou);
        
      }
    }, [setValue, tmpUserRef, resetForm]);

    
   

    const handlePreviewClick = () => {
      const link = document.querySelector('input[name="link"]').value;

      if (!link.match(youtubePattern)) {
        setValue('link', link, { shouldValidate: true });
      } else {
        setShowPreviewPopup(true);
      }
    };

    const handlePreview2Click = () => {
      const link2 = document.querySelector('input[name="link2"]').value;

      if (!link2.match(youtubePattern)) {
        setValue('link2', link2, { shouldValidate: true });
      } else {
        setShowPreview2Popup(true);
      }
    };

    let videoId = '';
    let videoId2 = '';
    const inputLink = document.querySelector('input[name="link"]')?.value;

    try {
      if (inputLink?.includes('youtu.be')) {
        videoId = inputLink.split('youtu.be/')[1].split('?')[0];
      } else if (inputLink?.includes('youtube.com')) {
        
        videoId = inputLink.split('v=')[1].split('&')[0];
      }
    }
    catch (error){
      
    }

    const inputLink2 = document.querySelector('input[name="link2"]')?.value;

    try {
      if (inputLink2?.includes('youtu.be')) {
        videoId2 = inputLink2.split('youtu.be/')[1].split('?')[0];
      } else if (inputLink2?.includes('youtube.com')) {
        
        videoId2 = inputLink2.split('v=')[1].split('&')[0];
      }
    }
    catch (error){
      
    }

    const youtubePattern = /^(https?:\/\/)?((www\.|m\.)?youtube\.com|youtu\.?be)\/.+$/;
    
    const handlePdfUpload = (event) => {
      console.log("File: ",event)
      const file = event.target.files[0];
      console.log("File: ",file)
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const fileData = {
            url: reader.result,
            name: file.name,
            file: file
          };
          setFilePdf(fileData);
          // setValue("file", fileData);
          // trigger("file");
        };
        reader.readAsDataURL(file);
      }
    };


    const openPdfPreview = () => {
      const pdfWindow = window.open();
      pdfWindow.document.write(
        `<iframe src="${filePdf.url}" width="100%" height="100%"></iframe>`
      );
    };

    const handleInputChange = (event) => {
      console.log("event",event.target.value);
      const charCount = event.target.value.length;
      if (charCount <= MAX_CHARS) {
        setCharCount(charCount);
      }
    };

    const {session} = useClerk();

    return (
      <StyledForm
        ref={ref}
        onSubmit={handleSubmit(async (data) => {
          let t = await getToken(session)
          if (filePdf)
            uploadFile(filePdf.file,"cv/"+tmpUserRef.current.id+".pdf",t, (error)=>console.log("error: ",error),()=>{console.log("heeeei");submitForm(data)},true)
          else
            submitForm(data)
        })}
        $width={formWidth}
      >
        {showPreviewPopup && (
          <Popup
            title={'Video Preview'}
            onClose={() => setShowPreviewPopup(false)}
          >
            <iframe
              title='Video Preview'
              width='100%'
              height='315'
              src={`https://www.youtube.com/embed/${videoId}`}
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            ></iframe>
          </Popup>
        )}
        {showPreview2Popup && (
          <Popup
            title={'Video Preview'}
            onClose={() => setShowPreview2Popup(false)}
          >
            <iframe
              title='Video Preview'
              width='100%'
              height='315'
              src={`https://www.youtube.com/embed/${videoId2}`}
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            ></iframe>
          </Popup>
        )}
        
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <FullSizeContainer $fullWidth={fullWidth}>
              <InputWithError>
                  <LargeTextInput
                    placeholder='Dicci qualcosa su di te...'
                    {...register('aboutYou', {
                      required: 'La descrizione è obbligatoria',
                    })}
                    $active={!!errors?.aboutYou?.message}
                    textAreaWidth={formWidth}
                    maxLength={MAX_CHARS}
                    onChange={handleInputChange}
                  />
                  <CharCounter>
                    {charCount}/{MAX_CHARS}
                  </CharCounter>

                  {errors.aboutYou && (
                    <ErrorText>{errors.aboutYou.message}</ErrorText>
                  )}
              </InputWithError>
              {isMusicianRole && (
                <>
                  
                  <InputWithError $direction={'column'} >
                    <Text $textAlign={'center'}>
                      {'Carica due video youtube delle performance che preferisci'}
                    </Text>
                    <Input
                      type='link'
                      placeholder={'https://www.youtube.com/...'}
                      {...register('link', {
                        required: "L'URL è obbligatorio",
                        pattern: {
                          value: youtubePattern,
                          message: 'URL di YouTube non valido',
                        },
                      })}
                      $active={!!errors?.link?.message}
                      onClick={(e) => {
                        e.preventDefault();
                        handlePreviewClick();
                      }}
                    />
                    <Input
                      type='link2'
                      placeholder={'https://www.youtube.com/...'}
                      {...register('link2', {
                        required: "L'URL è obbligatorio",
                        pattern: {
                          value: youtubePattern,
                          message: 'URL di YouTube non valido',
                        },
                      })}
                      $active={!!errors?.link2?.message}
                      onClick={(e) => {
                        e.preventDefault();
                        handlePreview2Click();
                      }}
                    />
                  </InputWithError>
                  {/* <TagContainer $display='flex'>
                    <DeletableButton
                      onClick={(e) => {
                        e.preventDefault();
                        handlePreviewClick();
                      }}
                      bgColor={'#F0EFFF'}
                      color={'#A7A3FF'}
                    >
                      Preview
                    </DeletableButton>
                  </TagContainer> */}
                  {errors.link && <ErrorText>{errors.link.message}</ErrorText>}
                  {errors.link2 && <ErrorText>{errors.link2.message}</ErrorText>}
                  
                 
                  
                  
                </>
              )}
              <InputWithError>
                    <Input
                          id='upload-button-premium'
                          type='file'
                          accept='.pdf'
                          {...register('file')}
                          onChange={handlePdfUpload}
                          display={"none"}
                        />
                    {!filePdf && (
                      <DeletableButton
                        onClick={(e) => {
                          e.preventDefault();
                          console.log("Hei:")
                          document.getElementById('upload-button-premium').click();
                        }}
                        bgColor={'#F0EFFF'}
                        color={'#A7A3FF'}
                      >
                        Carica il tuo CV
                      </DeletableButton>
                    )}
                    {filePdf && (
                      <div style={{display:'flex',flexDirection:'row', gap:"10px"}}>
                        <DeletableButton
                          onClick={(e) => {
                            e.preventDefault();
                            openPdfPreview();
                          }}
                          bgColor={'#F0EFFF'}
                          color={'#A7A3FF'}
                        >
                          {filePdf.name}
                        </DeletableButton>
                        <SecondaryButton
                          onClick={(e) => {
                            e.preventDefault();
                            setFilePdf(null);
                            setValue('file', null);
                          }}
                          bgColor={'#FF6347'}
                          color={'#FF6347'}
                        >
                          Rimuovi
                        </SecondaryButton>
                      </div>
                    )}
                    {errors.file && (
                      <ErrorText>{errors.file.message}</ErrorText>
                    )}
                  </InputWithError>
            </FullSizeContainer>
            <button ref={submitRef} type='submit' style={{ display: 'none' }} />
          </div>
        </>
      </StyledForm>
    );
  }
);

export default PremiumMusicianForm;
