import styled from 'styled-components';

export const RequestCardContainer = styled.div`
  padding: 10px;
  border-radius: 4px;
    margin-bottom: 10px;
  background-color: #f0efff;
  color: black;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  text-align: center; /* Aggiunto per allineare il testo al centro */
  display:flex;
  flex-direction:row;

 
`;

export const RejectedRequestCardContainer = styled.div`
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #ccc; /* Colore di sfondo per il tasto disabilitato */
  color: black;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  text-align: center; /* Aggiunto per allineare il testo al centro */
  display:flex;
  flex-direction:row;

 
`;

export const ExpiredRequestCardContainer = styled.div`
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #ccc; /* Colore di sfondo per il tasto disabilitato */
  color: black;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  text-align: center; /* Aggiunto per allineare il testo al centro */
  display:flex;
  flex-direction:row;

  
`;