import React, { useState } from 'react';
import {
  Input,
  TogglePasswordVisibilityButton,
  PasswordInputContainer,
} from './PasswordInputElements';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

const PasswordInput = React.forwardRef(({ ...props }, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = (e) => {
    e.preventDefault()
    setShowPassword(!showPassword);
  };

  return (
    <PasswordInputContainer>
      <Input {...props} type={showPassword ? 'text' : 'password'} ref={ref} />
      <TogglePasswordVisibilityButton icon={showPassword ? faEye : faEyeSlash} onClick={togglePasswordVisibility} />
    </PasswordInputContainer>
  );
});

export default PasswordInput;
