import styled from 'styled-components';

export const ImageContainer = styled.div`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: 190px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
