import { useUser } from '@clerk/clerk-react';
import { subYears } from 'date-fns';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import PlaceSelector from '../../../functionals/PlaceSelector/PlaceSelector';
import Input from '../../../inputs/TextInput/TextInput';
import { InputContainer } from '../../../inputs/TextInput/TextInputElements';
import {
  TwoColumnGridContainer,
  TwoColumnGridWithMargin,
} from '../../Containers/GridContainer/TwoColumnsGridContainer/TwoColumnsGridContainer';
import { HorizontalLayout } from '../../Containers/layouts/HorizontalLayout/HorizontalLayout';
import RoundImage from '../../media/RoundImage/RoundImage';
import { ErrorText } from '../../texts/TextsElements';
import {
  InputWithError,
  StyledDatePicker,
  StyledForm,
  StyledOption,
  StyledPhoneInput,
  StyledSelect,
} from './PersonalDataFormElements';

const PersonalDataForm = forwardRef(
  ({ tmpUserRef, submitForm, submitRef, formWidth, resetForm }, ref) => {
    const { user } = useUser();
    const [profilePic, setProfilePic] = useState(null);
    const profilePicRef = useRef();
    profilePicRef.current = profilePic;
    const [errImage,setErrImage] = useState("")


    const genderOptions = [
      { value: 'male', label: 'Maschio' },
      { value: 'female', label: 'Femmina' },
      { value: 'other', label: 'Altro' },
    ];



    const {
      register,
      handleSubmit,
      formState: { errors },
      control,
      setValue,
      
    } = useForm({
      defaultValues: {
        firstName: '',
        lastName: '',
        city: '',
      },
      mode: 'all',
    });

    const handleProfilePicUpload = async (file) => {
      try {
        console.log("Setting imae profile:",file);
        await user.setProfileImage({ file: file });
        return true
      } catch (error) {
        setProfilePic(null)
        setErrImage("Errore nel caricamento dell'immagine")
        return false
      } 
    };

    useEffect(() => {
      window.addEventListener('error', (e) => {
        if (
          e.message ===
          'ResizeObserver loop completed with undelivered notifications.'
        ) {
          const resizeObserverErrDiv = document.getElementById(
            'webpack-dev-server-client-overlay-div'
          );
          const resizeObserverErr = document.getElementById(
            'webpack-dev-server-client-overlay'
          );

          if (resizeObserverErr) {
            resizeObserverErr.setAttribute('style', 'display: none');
          }
          if (resizeObserverErrDiv) {
            resizeObserverErrDiv.setAttribute('style', 'display: none');
          }
        }
      });
    }, []);

    useEffect(() => {
      if (tmpUserRef?.current) {
        setValue('firstName', tmpUserRef.current?.firstName);
        setValue('lastName', tmpUserRef.current?.lastName);
        setValue('city', tmpUserRef.current?.city);
        setValue('phone', tmpUserRef.current?.phone);
        setValue('gender', tmpUserRef.current?.gender);
        setValue('dateOfBirth', tmpUserRef.current?.dateOfBirth);
      }
      
    }, [setValue, tmpUserRef, resetForm]);

    
    return (
      <StyledForm
        ref={ref}
        $width={formWidth}
        onSubmit={handleSubmit(async (data) => {
        
       
         
          if (profilePic) 
            handleProfilePicUpload(profilePicRef.current)
         
          submitForm(data);
        })}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <HorizontalLayout>
          <div style={{display:'flex', flexDirection:'column', alignItems:'center',gap:"10px"}}>
            <RoundImage
              imageSrc={
                !profilePicRef.current
                  ? user.profileImageUrl
                  : URL.createObjectURL(profilePicRef.current)
              }
              modify={true}
              onChange={setProfilePic}
            />
            {errImage && <ErrorText>{errImage}</ErrorText>}
            </div>
            {/* <RoundImage
              imageSrc={
                'https://images.unsplash.com/photo-1696325905035-74fdaebbb68d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1972&q=80'
              }
              modify={true}
              onChange={setProfilePic}
            /> */}
          </HorizontalLayout>
          <TwoColumnGridWithMargin>
          
            <TwoColumnGridContainer>      
              <InputWithError>
                <Input
                  type='text'
                  placeholder='Nome'
                  {...register('firstName', {
                    required: 'Il nome è obbligatorio',
                  })}
                  
                  $active={!!errors?.firstName?.message}
                />
                
                
                {errors.firstName && <ErrorText>{errors.firstName.message}</ErrorText>}
              </InputWithError>
              <InputWithError>
                <Input
                  type='text'
                  placeholder='Cognome'
                  {...register('lastName', {
                    required: 'Il cognome è obbligatorio',
                  })}
                  $active={!!errors?.lastName?.message}
                />
                {errors.lastName && (
                  <ErrorText>{errors.lastName.message}</ErrorText>
                )}
              </InputWithError>
              <InputWithError>
                <Controller
                    control={control}
                    name='city'
                    rules={{ required: 'La città è obbligatoria' }}
                    render={({ field }) => (
                      <PlaceSelector
                          {...field}
                          type='text'
                          placeholder='Città'
                          onChange={(selected)=>field.onChange(selected)}
                          $active={!!errors?.city?.message}
                          name='city'
                          valueP={field.value}
                          bgColor="#f0efff"
                        />
                    )}
                  />
                {errors.city && <ErrorText>{errors.city.message}</ErrorText>}
              </InputWithError>
              <InputWithError>
                <Controller
                  control={control}
                  name='dateOfBirth'
                  rules={{ required: 'La data di nascita è obbligatoria' }}
                  render={({ field }) => (
                    <StyledDatePicker
                      {...field}
                      selected={field.value}
                      onChange={(dateOfBirth) => field.onChange(dateOfBirth)}
                      dateFormat='dd-MM-yy'
                      placeholderText='Data di nascita'
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode='select'
                      $active={!!errors?.dateOfBirth?.message}
                      maxDate={subYears(new Date(), 18)}
                    />
                  )}
                />
                {errors.dateOfBirth && <ErrorText>{errors.dateOfBirth.message}</ErrorText>}
              </InputWithError>
              <InputWithError>
                <InputContainer>
                  <StyledSelect
                    name='gender'
                    $active={!!errors?.gender?.message}
                    {...register('gender', {
                      required: 'Il sesso è obbligatorio',
                    })}
                  >
                    <StyledOption value=''>Seleziona il tuo sesso</StyledOption>
                    {genderOptions.map((option) => (
                      <StyledOption key={option.value} value={option.value}>
                        {option.label}
                      </StyledOption>
                    ))}
                  </StyledSelect>
                </InputContainer>
                {errors.gender && <ErrorText>{errors.gender.message}</ErrorText>}
              </InputWithError>
              <InputWithError>
                <InputContainer>
                  <Controller
                    control={control}
                    name='phone'
                    rules={{
                      required: 'Il numero di telefono è obbligatorio',
                      validate: (value) =>
                        isValidPhoneNumber(value) ||
                        'Inserisci un numero di telefono valido',
                    }}
                    render={({ field }) => (
                      <StyledPhoneInput
                        {...field}
                        value={field.value}
                        defaultCountry='IT'
                        placeholder='Numero di telefono'
                        onChange={(phone) => field.onChange(phone)}
                        $active={!!errors?.phone?.message}
                      />
                    )}
                  />
                </InputContainer>

                {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
              </InputWithError>
              {tmpUserRef?.current?.email && <InputWithError>
                <Input
                  type='text'
                  placeholder='Email'
                  value={tmpUserRef.current.email}
                  disabled
                />
               
              </InputWithError>}
              
              
            </TwoColumnGridContainer>
          </TwoColumnGridWithMargin>
          <button ref={submitRef} type='submit' style={{ display: 'none' }} />
        </div>
      </StyledForm>
    );
  }
);

export default PersonalDataForm;
