import React, { useEffect, useState } from 'react';
import { PlaceSelectorBox, PlaceSelectorContainer, SuggestionItem, SuggestionsList } from './PlaceSelectorElements';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

const PlaceSelector = React.forwardRef(({color, border, valueP, bgColor, onChange, minWidth, ...props}, ref) => {
    const provider = new OpenStreetMapProvider();
    const [suggestions,setSuggestions] = useState([])
    const [isActive,setIsActive] = useState(false);
    const [value,setValue] = useState(null);
    const [query,setQuery] = useState("");

    useEffect(()=>{
      
        setValue(valueP)
      
    },[valueP])

    const handleCityChange = async (nValue) => {

        if (value &&  nValue.length < value.label.length) {
          setQuery("")
        }
        else
          setQuery(nValue)
        setValue(null)
        
        onChange(null)
        setIsActive(true)
        try {
          const results = await provider.search({ query: nValue });
          console.log("Results: ",results)
          setSuggestions(results.filter(x=>x.raw.addresstype=="city" || x.raw.addresstype=="village" ||  x.raw.addresstype=="town" ));
        } catch (error) {
          console.error('Error fetching suggestions', error);
        }
      };

    const handleSelect = (selected) =>{
       
        setQuery("")
        setValue(selected)
        onChange(selected)
    }
  

    return (
    <PlaceSelectorContainer $minWidth={minWidth}>
      <PlaceSelectorBox  ref={ref} $bgColor={bgColor} $color={color} $border={border} {...props} value={query?query:value?value.label:""} onChange={(e)=>handleCityChange(e.target.value)}   />
      {isActive && <SuggestionsList>
            {suggestions.map((suggestion, index) => (
              <SuggestionItem
                key={index}
                onClick={() => {setIsActive(false);  handleSelect(suggestion)}}
              >
                {suggestion.label}
              </SuggestionItem>
            ))}
          </SuggestionsList>}
    </PlaceSelectorContainer>
  );
});

export default PlaceSelector;