import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const HeartIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${({ $isFilled }) => ($isFilled ? 'red' : '#333')};
  margin-bottom: 10px;
  font-size: 20px;
  cursor: pointer;
`;
