import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { useDropzone } from "react-dropzone";
import { CentralContainer, Description, Icon, Overlay, SquareBox, VideoDropzoneContainer, VideoPreview } from './UploadVideoFormElements';
import DeletableButton from "../../buttons/DeletableButton/DeletableButton";



const UploadVideoForm = forwardRef((props,ref) => {
  const [videoSrc, setVideoSrc] = useState(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        print() {
            return "video form"
        },
        validateAndInsert(){
          return validateAndInsert()
        }
      };
    },
    []
  );

  const validateAndInsert = () => {
    return {video: videoSrc};
  }

  const onDrop = useCallback((acceptedFiles) => {
    // Esegui azioni quando il file video viene trascinato o selezionato
    if (acceptedFiles.length > 0) {
      const videoFile = acceptedFiles[0];
      console.log("Gey: ",videoFile)
      const videoURL = URL.createObjectURL(videoFile);
      setVideoSrc(videoURL);
     
      setSuccess(true)
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const [success, setSuccess] = useState(false);

  const resetVideo = ()=>{
    setSuccess(false)
    setVideoSrc(null)
  }

  

  return (
    <VideoDropzoneContainer >
      
      {success ? (
        <VideoPreview controls autoPlay>
         
          <source src={videoSrc}  />
          Your browser does not support the video tag.
          
        </VideoPreview>
      ) : (
        
        <CentralContainer {...getRootProps()}>
          <input {...getInputProps()} />
          <SquareBox>
            <Icon>🎥</Icon>
          </SquareBox>
          
            <Description>Carica un vido di una tua performance (max. 5 min)</Description>
        </CentralContainer>
        
      )}
      {success?<Overlay><DeletableButton onClick={resetVideo}>annulla</DeletableButton></Overlay>:<></>}
   </VideoDropzoneContainer>
  );
  });
  
  export default UploadVideoForm;