import styled from 'styled-components';

export const VerticalLayout = styled.div`
  display: flex;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.bkgColor ? props.bkgColor : '#fff')};
`;
