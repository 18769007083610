import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import TabBar from '../../components/presentationals/bars/TabBar/TabBar';
import { OuterContainer } from '../../components/presentationals/Containers/layouts/MainLayout/MainLayoutElements';
import { musicianTabs, plannerTabs } from '../../config/profile/ProfileTabs';
import { MUSICIAN, PLANNER } from '../../config/profile/Roles';
import { getMyApplications } from '../../remote/announcements';
import Announcements from '../../screens/announcements/Announcements';
import { useStateValue } from '../../stores/StateProvider';
import getToken from '../../userManagement/utilities';
import Applications from '../applications/Applications';
import SettingsAndSecurity from './account/settingsAndSecurity/SettingsAndSecurity';
import Payment from './payment/Payment';
import PaymentSuccess from './payment/Success';
import Profile from './Profile';
import { useClerk } from '@clerk/clerk-react';
import AnnouncementApplications from '../announcementApplications/announcementApplications';

function Me() {
  const navigate = useNavigate();
  const [{ userData }] = useStateValue();
  let roledTabs = []

 

  if (userData.role==MUSICIAN)
      roledTabs=musicianTabs
  else if (userData.role==PLANNER)
    roledTabs=plannerTabs
  return (
    <>
      <TabBar
        onClick={(id) => navigate(id)}
        tabs={roledTabs}
        position='fixed'
        top='80px'
      />
      <Routes>
        <Route
          path='/'
          element={
            <OuterContainer>
              <Profile userData={userData} />
            </OuterContainer>
          }
        />
        <Route
          path='profile'
          element={
            <OuterContainer>
              <Profile userData={userData} />
            </OuterContainer>
          }
        />
        <Route
          path='announcements'
          element={
            <OuterContainer >
              <Announcements role={userData ? userData.role : null} />
            </OuterContainer>}
        />
        <Route
          path='applications'
          element={
            <OuterContainer $adminMargin>
              <Applications applications={userData ? userData.applications:[]}  role={userData ? userData.role : null} />
            </OuterContainer>}
        />
        <Route
          path='settings'
          element={<OuterContainer><SettingsAndSecurity /></OuterContainer>}
        />
        <Route
            path="announcements/:announcement/applications"
            element={
              <>
                
                <OuterContainer $adminMargin>
                    
                      <AnnouncementApplications />
                      
                      </OuterContainer>
              
                
            </>
            }
          />
        <Route path='payment' element={<Payment />} />
        <Route path='payment/success' element={<PaymentSuccess />} />
      </Routes>
    </>
  );
}

export default Me;
