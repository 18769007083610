import {
  TwoColumnResponsiveGrid,
  TwoColumnGridWithMargin,
} from '../../Containers/GridContainer/TwoColumnsGridContainer/TwoColumnsGridContainer';
import SelectableCard from '../../cards/SelectableCard/SelectableCard';
import { useState, useEffect } from 'react';
import { forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorText } from '../../texts/TextsElements';
import { StyledForm } from '../PersonalDataForm/PersonalDataFormElements';
import eventsList from '../../../../data/events';

const EventsListForm = forwardRef(
  ({ submitForm, tmpUserRef, submitRef }, ref) => {
    const [events, setEvents] = useState([]);

    const handleEventsToggle = ({id, label}) => {
      let tmp = [...events];

      if (events.some((event) => event.id === id)) {
        tmp = tmp.filter((x) => x.id !== id);
      } else if (tmp.length < 3) {
        tmp.push({ id, label });
      }
      setEvents(tmp);
      setValue('events', tmp);
    };

    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
    } = useForm({
      defaultValues: {
        events: [],
      },
      mode: 'all',
    });

    useEffect(() => {
      if (tmpUserRef?.current) {
        console.log('tmpUserRef', tmpUserRef?.current);
        if (tmpUserRef.current.events) {
          setEvents(tmpUserRef.current.events);
          setValue(
            'events',
            tmpUserRef.current.events
          );
        }
      }
    }, [setValue, tmpUserRef]);

    register('events', {
      validate: (value) =>
        value.length <= 3 || 'puoi selezionare max 3 eventi',
    });

    return (
      <div style={{ width: '99%' }}>
        <StyledForm ref={ref} onSubmit={handleSubmit(submitForm)}>
          <TwoColumnGridWithMargin>
            <TwoColumnResponsiveGrid>
              {eventsList.map((x, index) => (
                <SelectableCard
                  selected={events.some(
                    (event) => event.id === x.id
                  )}
                  onClick={() => handleEventsToggle(x)}
                  key={index}
                >
                  <p>{x.label}</p>
                </SelectableCard>
              ))}
            </TwoColumnResponsiveGrid>
            {errors.events && (
              <ErrorText>{errors.events.message}</ErrorText>
            )}
          </TwoColumnGridWithMargin>
          <button ref={submitRef} type='submit' style={{ display: 'none' }} />
        </StyledForm>
      </div>
    );
  }
);

export default EventsListForm;
