import styled, { css, keyframes } from 'styled-components';

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
`;

export const ImageContainer = styled.div`
  width: ${(props) => (props.size ? props.size : '250px')};
  height: ${(props) => (props.size ? props.size : '250px')};
  border-radius: 40px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.selected ? '#f0efff' : '#9a84bc')};
  border: ${(props) =>
    props.selected ? '5px solid #A7A3FF' : '5px solid transparent'};
  box-shadow: ${(props) =>
    props.selected
      ? ''
      : '0px 2px 4px rgba(0, 0, 0, 0.2)'}; /* Aggiunta ombra */
  ${(props) =>
    props.onClick &&
    !props.selected &&
    css`
      cursor: pointer;

      &:active {
        animation: ${pulseAnimation} 0.2s ease-in-out;
      }
    `};

  @media (max-width: 768px) {
    width: ${(props) => (props.size ? props.size : '250px')};
    height: ${(props) => (props.size ? props.size : '250px')};
    border-radius: 32px;
  }

  @media (max-width: 480px) {
    width: 250px;
    height: 250px;
    border-radius: 25px;
  }
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  
  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
  }
`;
