import {
  TwoColumnResponsiveGrid,
  TwoColumnGridWithMargin,
} from '../../Containers/GridContainer/TwoColumnsGridContainer/TwoColumnsGridContainer';
import SelectableCard from '../../cards/SelectableCard/SelectableCard';
import { useState, useEffect } from 'react';
import { forwardRef } from 'react';
import musicalInstruments from '../../../../data/instruments';
import { useForm } from 'react-hook-form';
import { ErrorText, Text } from '../../texts/TextsElements';
import { StyledForm } from '../PersonalDataForm/PersonalDataFormElements';
import { TagIcon } from '../../cards/MainCard/MainCardElements';
import { musicianPlans } from '../../../../config/musicianPlans';

const InstrumentsForm = forwardRef(
  ({ submitForm, tmpUserRef, submitRef }, ref) => {


    const [instruments, setInstruments] = useState([]);

    const handleInstrumentToggle = ({id, label}) => {
      let tmp = [...instruments];

      let max = 3;

      if (tmpUserRef.current.group && tmpUserRef.current.group.groupType){
        max = musicianPlans[tmpUserRef.current.group.groupType.toUpperCase()].number
      }

      if (instruments.some((instrument) => instrument.id === id)) {
        tmp = tmp.filter((x) => x.id !== id);
      } else if (tmp.length < max) {
        tmp.push({ id, label });
      }
      setInstruments(tmp);
      setValue('instruments', tmp);
    };

    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
    } = useForm({
      defaultValues: {
        instruments: [],
      },
      mode: 'all',
    });

    useEffect(() => {
      if (tmpUserRef?.current) {
        console.log('tmpUserRef', tmpUserRef?.current);
        if (tmpUserRef.current.instruments) {
          setInstruments(tmpUserRef.current.instruments);
          setValue(
            'instruments',
            tmpUserRef.current.instruments
          );
        }
      }
    }, [setValue, tmpUserRef]);

    register('instruments', {
      validate: (value) =>
        value.length <= 3 || 'puoi selezionare max 3 strumenti',
    });

    return (
      <div style={{ width: '99%', height:"90%", overflow:'scroll' }}>
        <div style={{width:"100%", alignItems:'center',justifyContent:'center'}}>
            {tmpUserRef.current.group && tmpUserRef.current.group.groupType && <Text style={{textAlign:'center'}}>{instruments.length}/{musicianPlans[tmpUserRef.current.group.groupType.toUpperCase()].number}</Text>}
          </div>
          <div style={{width:"100%", alignItems:'center',justifyContent:'center'}}>
            {(!tmpUserRef.current.group || !tmpUserRef.current.group.groupType) && <Text style={{textAlign:'center'}}>{instruments.length}/3</Text>}
          </div>
        <StyledForm $align={"flex-start"}  ref={ref} onSubmit={handleSubmit(submitForm)}>
          
          <TwoColumnGridWithMargin>
            <TwoColumnResponsiveGrid>
              {musicalInstruments.map((x, index) => (
                <SelectableCard
                  selected={instruments.some(
                    (instrument) => instrument.id === x.id
                  )}
                  onClick={() => handleInstrumentToggle(x)}
                  key={index}
                >
                  {/* <TagIcon
                    key={index}
                    src={
                      x.img
                    }
                    alt={x.name}
                    $pt={"0"}
                  /> */}
                  <p>{x.label}</p>
                </SelectableCard>
              ))}
            </TwoColumnResponsiveGrid>
            {errors.instruments && (
              <ErrorText>{errors.instruments.message}</ErrorText>
            )}
          </TwoColumnGridWithMargin>
          <button ref={submitRef} type='submit' style={{ display: 'none' }} />
        </StyledForm>
      </div>
    );
  }
);

export default InstrumentsForm;
