import { faSave } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import { useSession } from '@clerk/clerk-react';
import TitledContainer from '../../../../components/presentationals/Containers/TitledContainer/TitledContainer';
import SecondaryButton from '../../../../components/presentationals/buttons/SecondaryButton/SecondaryButton';
import {
    Icon
} from '../../../../components/presentationals/cards/MainCard/MainCardElements';
import { getMe } from '../../../../remote/me';
import Loader from '../../../../utils/Loader';
import SubscriptionSelector from '../../../../components/functionals/MusicianPlansSelector/MusicianPlanSelector';
import { MUSICIAN } from '../../../../config/profile/Roles';
import { useStateValue } from '../../../../stores/StateProvider';

function SettingsAndSecurity() {

  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(undefined);
  const [{ userData }] = useStateValue();

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia("(max-width: 767px)").matches;
      setIsMobile(isMobile);
    };

    handleResize(); // Controlla le dimensioni iniziali

    window.addEventListener("resize", handleResize); // Aggiungi un listener per rilevare i cambiamenti di dimensione

    return () => {
      window.removeEventListener("resize", handleResize); // Rimuovi il listener quando il componente viene smontato
    };
  }, []);

  // manage the display of the current plan

  const {session} = useSession();
  useEffect(() => {
    session.getToken().then(
      jwtToken => {
        getMe(jwtToken).then(
          me => {

            const nowISOString = new Date(new Date().toUTCString()).toISOString();

            if(me.premiumUserUntilDate && me.premiumPlan) {
              setCurrentPlan((nowISOString < me.premiumUserUntilDate) ? parseInt(me.premiumPlan) : 0);
            }
          }
        );
      }
    );
  }, [session]);
  
  const submitProfileRef = useRef();

  return (
    <>
      {loading && <Loader />}
      {userData.role==MUSICIAN && <TitledContainer
        title={'Il tuo piano'}
        sideComponent={
          isMobile?<Icon
          icon={faSave}
          onClick={() => {submitProfileRef.current.click();
          }}
        />:<SecondaryButton
            // disabled={!currentRef || !currentRef.current}
            onClick={() => {
              submitProfileRef.current.click();
            }}
          >
            Aggiorna
          </SecondaryButton>

        }
        mainPadding={'20px'}
        margin={'20px 0px'}
        boxShadow={'0px 0px 5px rgba(0, 0, 0, 0.3)'}
      >
        <SubscriptionSelector currentPlan={currentPlan}/>
      </TitledContainer>}
      <TitledContainer
        title={'Termini e condizioni'}
        sideComponent={
          isMobile?<Icon
          icon={faSave}
          onClick={() => {submitProfileRef.current.click();
          }}
        />:<SecondaryButton
            // disabled={!currentRef || !currentRef.current}
            onClick={() => {
              submitProfileRef.current.click();
            }}
          >
            Aggiorna
          </SecondaryButton>

        }
        mainPadding={'20px'}
        margin={'20px 0px'}
        boxShadow={'0px 0px 5px rgba(0, 0, 0, 0.3)'}
      ></TitledContainer>
      <TitledContainer
        title={'Disattiva account'}
        sideComponent={
          isMobile?<Icon
          icon={faSave}
          onClick={() => {submitProfileRef.current.click();
          }}
        />:<SecondaryButton
            // disabled={!currentRef || !currentRef.current}
            onClick={() => {
              submitProfileRef.current.click();
            }}
          >
            Aggiorna
          </SecondaryButton>

        }
        mainPadding={'20px'}
        margin={'20px 0px'}
        boxShadow={'0px 0px 5px rgba(0, 0, 0, 0.3)'}
      ></TitledContainer>
      <TitledContainer
        title={'Contatti'}
        sideComponent={
          isMobile?<Icon
          icon={faSave}
          onClick={() => {submitProfileRef.current.click();
          }}
        />:<SecondaryButton
            // disabled={!currentRef || !currentRef.current}
            onClick={() => {
              submitProfileRef.current.click();
            }}
          >
            Aggiorna
          </SecondaryButton>

        }
        mainPadding={'20px'}
        margin={'20px 0px'}
        boxShadow={'0px 0px 5px rgba(0, 0, 0, 0.3)'}
      ></TitledContainer>
    </>
  );
}

export default SettingsAndSecurity;