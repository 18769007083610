
export default class Request {
  
    

    constructor(id,musicianId,plannerId,date,place,status, notes,musicianName="",plannerName="",image="",preventive=null,reviewId=null, extra = "") {
        this.id = id;
        this.musicianId = musicianId;
        this.plannerId = plannerId;
        this.date = date;
        this.place = place;
        this.status = status;
        this.notes = notes;
        this.musicianName = musicianName;
        this.plannerName = plannerName;
        this.image = image;
        this.preventive=preventive;
        this.reviewId = reviewId;
        this.extra = extra;
    }
  
   
  }