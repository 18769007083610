import { useSignIn } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import TitledContainer from "../../components/presentationals/Containers/TitledContainer/TitledContainer";
import LoginForm from "../../components/presentationals/forms/LoginForm/LoginForm";
import { RESET, SIGN_UP } from "../../config/URLs";
import { useState } from "react";
import errorMessages from "../../utils/ClerkUtilities/ErrorsMessages";
import { CenteredDiv } from "../../components/presentationals/forms/LoginForm/LoginFormElements";
import {
  GlobalStyle,
  PopupContainer,
  PopupOverlay,
  TitleContent,
} from "../../components/functionals/Popup/PopupElements";
import { IconButtonContainer } from "../../components/presentationals/buttons/IconButton/IconButtonElements";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export default function SignInPopup({ setShowLoginPopup }) {
  const { isLoaded, signIn, setActive } = useSignIn();

  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");

  // start the sign In process.
  const handleSubmit = async ({ email, password }) => {
    if (!isLoaded) {
      return;
    }
    try {
      const result = await signIn.create({
        identifier: email,
        password,
      });

      if (result.status === "complete") {
        console.log(result);
        await setActive({ session: result.createdSessionId });
        console.log("Result ", result);
      } else {
        /*Investigate why the login hasn't completed */
        console.log(result);
      }
    } catch (err) {
      setErrorMsg(errorMessages[err.errors[0].code]);
    }
  };

  return (
    <>
      <GlobalStyle noScroll />
      <PopupOverlay>
        <PopupContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px",
            }}
            onClick={setShowLoginPopup}
          >
            <IconButtonContainer icon={faXmark} color={"black"} size={"xs"} />
          </div>
          <CenteredDiv $display="block" $width="100%">
            <TitledContainer margin={"0px"} centered={"center"} title="Login" fullWidth contentDisplay="block">
              <LoginForm
                handleSignInSubmit={handleSubmit}
                onSignup={() => navigate(SIGN_UP)}
                onReset={() => navigate(RESET)}
                errorMsg={errorMsg}
              />
            </TitledContainer>
          </CenteredDiv>
          <TitleContent></TitleContent>
        </PopupContainer>
      </PopupOverlay>
    </>
  );
}
