import { useSignIn } from "@clerk/clerk-react";
import React, { useState } from "react";
import TitledContainer from "../../components/presentationals/Containers/TitledContainer/TitledContainer";
import Input from "../../components/inputs/TextInput/TextInput";
import DeletableButton from "../../components/presentationals/buttons/DeletableButton/DeletableButton";
import PasswordInput from "../../components/inputs/PasswordText/PasswordInput";

const ResetPwd = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [successfulCreation, setSuccessfulCreation] = useState(false);
  const [complete, setComplete] = useState(false);
  const [secondFactor, setSecondFactor] = useState(false);

  const { isLoaded, signIn, setActive } = useSignIn();

  if (!isLoaded) {
    return null;
  }

  async function create(e) {
    e.preventDefault();
    await signIn
      ?.create({
        strategy: "reset_password_email_code",
        identifier: email,
      })
      .then((_) => {
        setSuccessfulCreation(true);
      })
      .catch((err) => console.error("error", err.errors[0].longMessage));
  }

  async function reset(e) {
    e.preventDefault();
    await signIn
      ?.attemptFirstFactor({
        strategy: "reset_password_email_code",
        code,
        password,
      })
      .then((result) => {
        if (result.status === "needs_second_factor") {
          setSecondFactor(true);
        } else if (result.status === "complete") {
          setActive({ session: result.createdSessionId });
          setComplete(true);
        } else {
          console.log(result);
        }
      })
      .catch((err) => console.error("error", err.errors[0].longMessage));
  }

  return (
    <form
      style={{
        display: "flex",
       
        flexDirection: "column",
        gap: "1em",
      }}
      onSubmit={!successfulCreation ? create : reset}
    >
      {!successfulCreation && !complete && (
        <TitledContainer
          margin={"0px"}
          title="Inserisci la tua email"
          centered="center"
          fullWidth
          textAlign={"center"}
        >
          <div style={{width:"90%", display:'flex',alignItems:'center',justifyContent:'center', flexDirection:'column'}}>

          
            <Input
              value={email}
              placeholder="Email..."
              onChange={(e) => setEmail(e.target.value)}
            />
            {/* {error && <ErrorText style={{ textAlign: "left" }}>{error}</ErrorText>} */}
            <DeletableButton type="submit">Resetta</DeletableButton>
          </div>
        </TitledContainer>
      )}

      {successfulCreation && !complete && (
        <TitledContainer
          title="Inserisci la nuova password"
          subtitle={email?"Una mail con il codice è stata mandata alla tua mail "+email:""}
          centered="center"
          fullWidth
          margin={"0px"}
          textAlign={"center"}
        >
          <div style={{width:"100%", display:'flex',alignItems:'center',justifyContent:'center', flexDirection:'column'}}>
            <div style={{width:"90%"}}>
              <PasswordInput
                value={password}
                placeholder="Inserisci la password..."
                onChange={(e) => setPassword(e.target.value)}
              />
              <Input
                value={code}
                placeholder="Inserisci il codice di recupero..."
                onChange={(e) => setCode(e.target.value)}
              />
              
            </div>
            <DeletableButton type="submit">Conferma password</DeletableButton>
          </div>
        </TitledContainer>
      )}

      {complete && "You successfully changed you password"}
      {secondFactor && "2FA is required, this UI does not handle that"}
    </form>
  );
};

export default ResetPwd;
