import styled from 'styled-components';

export const Text = styled.p`
  font-size: 16px;
  text-align: ${({ $textAlign }) => ($textAlign ? $textAlign : 'left')};
  font-family: Raleway Regular;
  margin: 0;
  @media (max-width: 480px) {
   
    line-height: 1.2;
  }
`;

export const Header1 = styled.text`
color: ${({ $textColor }) => ($textColor ? $textColor : '#DF6951')};;
font-family: Raleway Regular;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1.8px;
text-transform: uppercase;
`;


export const NoMarginText = styled.p`
    font-size: 24px;
    text-align: ${props=>props.textAlign?props.textAlign:"left"};
    font-family: Raleway Regular;
    margin:0;
    color: ${({ $textColor }) => ($textColor ? $textColor : '#000')};;
`;

export const SmallText = styled.p`
    font-size: ${props => props.$fontSize ? props.$fontSize : "16px"};
    text-align: ${props => props.$textAlign ? props.$textAlign : "left"};
    font-family: Raleway Regular;
    padding-bottom: 3px;
    margin: 0 !important;
    color: ${({ $textColor }) => ($textColor ? $textColor : '#000')};;
`;

export const ErrorText = styled.p`
  color: #bf1650;
  font-size: 12px;
  margin: 0;
  margin-left: 6px;
font-family: Raleway Regular;
  &::before {
    display: inline;
    content: '⚠ ';
  }
`;

export const Title1 = styled.h1`
  font-family: Raleway Regular;
  font-size: 28px;
  font-weight: 500;
  
  color: #37245A;
  text-align: ${({ $textAlign }) => ($textAlign ? $textAlign : 'left')};
  margin: ${({ $margin }) => ($margin ? $margin : '0')};
  @media (max-width: 700px) {
    text-align: center;
  }
  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 1.2;
  }
`;

export const Title1Home = styled.h1`
  font-family: ${({ $bold }) => ($bold ? 'Raleway Bold' : 'Raleway Regular')};
  font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : '25px')}; 
  
  color: #37245A;
  text-align: ${({ $textAlign }) => ($textAlign ? $textAlign : 'left')};
  margin: ${({ $margin }) => ($margin ? $margin : '0')};
  @media (max-width: 700px) {
    text-align: center;
  }
  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 1.2;
  }
`;




export const Title2 = styled.h1`
    font-size: 24px;
    font-family: Raleway Regular;
    text-align: ${props=>props.textAlign?props.textAlign:"left"};
    color: ${({ $textColor }) => ($textColor ? $textColor : '#000')};;
`;

export const NLinesText = styled.div`
    font-size: 16px;
    font-family: Raleway Regular;
    text-align: ${props=>props.textAlign?props.textAlign:"left"};
    display: -webkit-box;
    -webkit-line-clamp: ${props=>props.nLines?props.nLines:3}; // Massimo tree righe
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ $textColor }) => ($textColor ? $textColor : '#000')};;

`;

export const LeftText = styled.div`
  font-family: Raleway Regular;
  font-size: 32px;
  font-weight: bold;
  text-align: left;
  color: ${({ $textColor }) => ($textColor ? $textColor : '#000')};;

  cursor: pointer;

`;

export const BoldText = styled.p`
  font-weight: bold;
  font-family: Raleway Regular;
  color: white;
  color: ${(props) => (props.selected ? '#37245A' : '#fff')};

`;

export const IconText = styled.div`
  color: #000;
  font-size: ${({ $fontSize }) => $fontSize || '16px'};
  text-align: left;
  font-style: normal;
  font-weight: 500;
  padding-bottom: ${({ $pb }) => $pb || '3px'};
  @media (max-width: 480px) {
    font-size: 18px;
    line-height: 1.2;
  }
    font-family: Raleway Regular;
`;

export const TextLink = styled.a`
  font-size: ${props => props.$fontSize ? props.$fontSize : "16px"};
  text-align: ${props => props.$textAlign ? props.$textAlign : "left"};
  padding-bottom: 3px;
  margin: 0 !important;
  color: ${({ $textColor }) => ($textColor ? $textColor : '#000')};
  font-family: Raleway Regular;
  font-size:12px;
  color: #9a84bc;
`;