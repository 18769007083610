import React from 'react';
import { InputContainer, InputBox } from './TextInputElements';
import { TogglePasswordVisibilityButton } from '../PasswordText/PasswordInputElements';
import { faEye,  } from '@fortawesome/free-regular-svg-icons';

const Input = React.forwardRef(({color, border, onClick, display,  ...props}, ref) => {
  return (
    <InputContainer $display={display}>
      <InputBox ref={ref} $color={color} $border={border} {...props} />
      {onClick && <TogglePasswordVisibilityButton icon={faEye} onClick={onClick}  />}
    </InputContainer>
  );
});

export default Input;
