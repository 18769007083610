import { SET_REQUESTS } from "../actions/RequestsActions";

const requestsReducer = (state, action) => {
  switch (action.subtype) {
    case SET_REQUESTS:
      return { ...state, requests: action.requests };

    default:
      return state;
  }
};

export default requestsReducer;
