import { SecondaryBtn } from './SecondaryButtonElements';

const SecondaryButton = ({
  color = "#9A84BC",
  fontSize,
  fontWeight,
  onDelete,
  display,
  ...props
}) => {
  return (
    <SecondaryBtn
      $color={color}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
      $display={display}
      {...props}
    >
      {props.children}
    </SecondaryBtn>
  );
};

export default SecondaryButton;
