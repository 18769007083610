import { SET_USER_DATA } from "../actions/UserActions";

const userReducer = (state, action) => {
  switch (action.subtype) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
