
const RequestsStates = {
    ISSUED:'ISSUED', // the planner asked for the musician availability
    CANCELED_BY_PLANNER:'CANCELED_BY_PLANNER', // the planner canceled the request
    REJECTED_BY_MUSICIAN:'REJECTED_BY_MUSICIAN', // the musician rejected the request
    PENDING: 'PENDING', // the admin has to propose a preventive
    CANCELED_BY_ADMIN:'CANCELED_BY_ADMIN', 
    ESTIMATED:'ESTIMATED', //l'admin ha fatto un preventivo
    REJECTED_BY_PLANNER:'REJECTED_BY_PLANNER', // il planner ha rifiutato il prventivo
    ACCEPTED: 'ACCEPTED', // the planner accepted the request
    CONFIRMED:'CONFIRMED', // the admin confirmed the payment
   
}




export default RequestsStates