import { useClerk } from '@clerk/clerk-react';
import {
  faEye, faPencil, faXmark,
  faCircleCheck as solidCircleCheck
} from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { RightFixedButton } from '../../components/presentationals/buttons/DeletableButton/DeletableButtonElements';
import { IconButtonContainer } from '../../components/presentationals/buttons/IconButton/IconButtonElements';
import { Icon } from '../../components/presentationals/cards/ExpandableCards/ExpandableCardsElements';
import { ADMIN, MUSICIAN, PLANNER } from '../../config/profile/Roles';
import { disableMusician, getMusician, validateMusician } from '../../remote/musicians';
import { getPlanner } from '../../remote/planners';
import { useStateValue } from '../../stores/StateProvider';
import getToken from '../../userManagement/utilities';
import Loader from '../../utils/Loader';
import PublicProfile from '../musician/publicProfile/PublicProfile';
import { FloatRightButton } from '../musician/publicProfile/PublicProfileElements';
import Account from './account/Account';

const ToggleButton = styled.div`
  position: fixed;
  top: 152px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #f0efff;
  padding: 6px 30px;
  border-radius: 30px;
  font-size: 1.2rem;
  color: #a7a3ff;
  cursor: pointer;
  z-index: 40;
  @media (max-width: 768px) {
    padding: 6px 10px;
  }
  @media (max-width: 1400px) {
   
    gap: 0px;
    padding: 6px 10px;
  }

  @media (max-width: 768px) {
    top: 100px;
  }

  > span {
    padding-right: 4px;
    padding-bottom: 4px;
   
  }

  > svg {
    @media (min-width: 769px) and (max-width: 1400px) {
      display: none;
    }
  }
`;

const Profile = ({ noEdit }) => {
  const [isEditing, setIsEditing] = useState(true && !noEdit);
  const [musician, setMusician] = useState(null);
  const [loading, setLoading] = useState(true);
  const [{ userData }] = useStateValue();
  const url = window.location.href; 
  const { session } = useClerk();

  let { id } = useParams();

  const loadMusician = async (id) => {
    setLoading(true);
    if (url.includes('/planners')) {
      let t = await getToken(session);
      let planner = await getPlanner(id, t);
      setMusician({
        ...planner,
        dateOfBirth: new Date(planner.dateOfBirth),
      });
    } else {
      let m = await getMusician(id);
      m.role = MUSICIAN;
      console.log('m: ', m);
      setMusician({
        ...m,
        dateOfBirth: new Date(m.dateOfBirth),
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    console.log("id: ",id)
    console.log("user data: ",userData)
    console.log("!id && userData : ",!id && !!userData );
    if (!id && !!userData) {
      if (userData.role === MUSICIAN || userData.role === PLANNER) {
        setMusician(userData);
      }
      setLoading(false);
    } else if (id) {
      loadMusician(id);
    }
  }, [userData]);

  // console.log('id', id);

  if (loading) return <Loader />;

  // console.log("musician2:n ",musician)

  const validateMusicianFn = async (id)=>{
    setLoading(true)
    let t = await getToken(session);
    await validateMusician(id, t);
    window.location.reload();
  }

  const disableMusicianFn = async (id)=>{
    setLoading(true)
    let t = await getToken(session);
    await disableMusician(id, t);
    window.location.reload();
  }

  return (
    <div >
     
      {!noEdit && (
        <>
          {userData?.role !=PLANNER && <RightFixedButton
            onClick={() => setIsEditing(!isEditing)}
            $bgColor='#a7a3ff'
            $width='auto'
            $top='370px'
            $displayMobile='none'
          >
            <IconButtonContainer
              icon={isEditing ? faEye : faPencil}
              color={'white'}
              size='lg'
            />
          </RightFixedButton>}
          {userData?.role == ADMIN && !musician.valid &&  <RightFixedButton
            $bgColor='#a7a3ff'
            $width='auto'
            $top='450px'
            onClick={() => {
                if (userData?.role == ADMIN) validateMusicianFn(musician.id)
              
            }}
          >
            <Icon
              icon={!musician.valid ? solidCircleCheck : faXmark}
              //onClick={handleValidateUser}
              $color={!musician.valid && 'green'}
            />
          </RightFixedButton>}
          {userData?.role == ADMIN && musician.valid &&  <RightFixedButton
            $bgColor='#a7a3ff'
            $width='auto'
            $top='450px'
            onClick={() => {
                if (userData?.role == ADMIN) disableMusicianFn(musician.id)
              
            }}
          >
            <Icon
              icon={!musician.valid ? solidCircleCheck : faXmark}
              //onClick={handleValidateUser}
              $color={!musician.valid && 'green'}
            />
          </RightFixedButton>}
          {userData?.role == ADMIN &&<FloatRightButton onClick={() => musician.valid?disableMusicianFn(musician.id):validateMusicianFn(musician.id)}
            $bgColor='#a7a3ff'
            $bottom='90px'
          >
            <IconButtonContainer
              icon={!musician.valid ? solidCircleCheck : faXmark}
              color={'white'}
              size='lg'
            />
          </FloatRightButton>}
          <FloatRightButton onClick={() => setIsEditing(!isEditing)}
            $bgColor='#a7a3ff'
            
          >
            <IconButtonContainer
              icon={isEditing ? faEye : faPencil}
              color={'white'}
              size='lg'
            />
          </FloatRightButton>
        </>
      )}
      {isEditing && !noEdit ? (
        <Account
          musician={musician}
          userData={userData}
          setUpdatedData={setMusician}
        />
      ) : (
        <PublicProfile musician={musician} userData={userData} />
      )}
    </div>
  );
};

export default Profile;
