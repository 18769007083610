

import { useState } from 'react';

import DeletableButton from '../../presentationals/buttons/DeletableButton/DeletableButton';
import { createSubscription, updateBillingProfile } from '../../../remote/payments';


export default function BillingAccountForm({ userId, jwtToken, subscriptionId, subscriptionName, subscriptionPrice }) {
    
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);



  const createBillingAccount = async (e) => {
    e.preventDefault();

    setIsProcessing(true);


    // get information from the form


    // mandatory parameters for Stripe are email name and surname

    const email = 'email.prova@gmail.com';
    const nome = 'Mario';
    const cognome = 'Rossi';

    //const announcementId = 'e70ca871-a147-436c-a8a5-78198f80b72a'; // if planner - the id of the announcement to pay for


    await updateBillingProfile(
        jwtToken,
        {
            userId: userId,
            email: email,
            name: nome,
            surname: cognome,
        }
    );

    const response = await createSubscription(
        jwtToken,
        {
            subscriptionId: subscriptionId,
            userId: userId,
        }
    );

    

    /*
    
    await createPayment(
        jwtToken,
        {
            subscriptionId: subscriptionId,
            userId: userId,
            announcementId: announcementId,
        }
    );
    
    
    */


    if(response.ok) {
        response.json().then(json => {            
            if(json.url) {
                window.open(json.url);
            } else {
                setMessage('Qualcosa è andato storto');
            }
        });
    } else {
        setMessage('Qualcosa è andato storto');
    }
    

}


  return (
    <form id="billing-account-form" onSubmit={createBillingAccount}>
      { !message && (
      <>
        <p>{ subscriptionName } ({ subscriptionPrice })</p>
        <p>
            Inserisci i tuoi dati di fatturazione qui
        </p>
        <p>
            Email nome e cognome sono obbligatori per Stripe
        </p>
        <div style={{"display":"flex","justifyContent":"center","marginTop":"16px"}}>
          <DeletableButton disabled={isProcessing} id="submit">
            <span id="button-text">
              { isProcessing ? "Caricamento..." : "Completa il pagamento"}
            </span>
          </DeletableButton>
        </div>
      </>
      )}
      { message }
    </form>
  )
}

