import { DeleteIcon, RoundedButton } from './DeletableButtonElements';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

const DeletableButton = ({ onDelete, color, bgColor = "#9A84BC", display, padding, ...props }) => {
  return (
    <RoundedButton
      $color={color}
      $bgColor={bgColor}
      $display={display}
      $padding={padding}
      {...props}
    >
      {props.children}
      {onDelete && <DeleteIcon icon={faTimesCircle} onClick={onDelete} />}
    </RoundedButton>
  );
};

export default DeletableButton;
