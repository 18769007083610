import React from 'react';
import { InputContainer, TextArea } from './LargeTextInputElements';

const LargeTextInput = React.forwardRef(
  ({ textAreaWidth, maxLength, onChange, margin, ...props }, ref) => {
    return (
      <InputContainer $margin={margin} $width={textAreaWidth} {...props}>
        <TextArea
          maxLength={maxLength}
          onChange={onChange}
          ref={ref}
          {...props}
        />
      </InputContainer>
    );
  }
);

export default LargeTextInput;
