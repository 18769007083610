import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled, { css, keyframes } from 'styled-components';

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
`;

export const RoundedButton = styled.button`
  position: relative;
  padding: ${({ $padding }) => ($padding ? $padding : '12px 20px')};
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : '#37245A')};
  color: ${({ $color }) => ($color ? $color : 'white')};
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-family: Raleway Regular;
  font-weight: bold;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Aggiunta ombra */
  display: ${({ $display }) => ($display ? 'none' : 'block')};
  outline: none;
  
  @media (max-width: 768px) {
    display: block;
  }

  ${(props) =>
    !props.disabled &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${({ $bgColor }) =>
          $bgColor ? $bgColor : '#c2553d'};
      }

      &:active {
        animation: ${pulseAnimation} 0.2s ease-in-out;
      }
    `};

  &:disabled {
    background-color: #ccc; /* Colore di sfondo per il tasto disabilitato */
  }
`;

export const DeleteIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  // margin: 0 10px;
`;

export const RightFixedButton = styled.div`
  position: fixed;
  right: 0;
  top:  ${({ $top }) => ($top ? $top : '290px')};
  padding: 0 20px;
  z-index: 45;
  
  border: none;
  border-radius: 50px 0 0 50px;
  width: ${({$width}) => $width || '150px'};
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
 
  outline: none;
  transition: all 0.3s ease; 
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  
  background: url(${(props) => props.$bkgImg});
  background-size: cover; 
  background-position: center;

  background-color: ${({$bgColor}) => $bgColor || 'transparent'};

  @media (max-width: 768px) {

     top:  600px;
  }
    
  @media (max-width: 769px) {
    display: ${({$displayMobile}) => $displayMobile || 'flex'};
  }

  &:hover {
    transform: scale(1.1); 
  }
`;