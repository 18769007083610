import styled, { css } from 'styled-components';

export const VideoContainer = styled.div`
  display: ${({ $display }) => ($display ? $display : 'block')};
  margin-block: 2rem;

  iframe {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 768px) {
    display: ${({ $displayResponsive }) =>
      $displayResponsive ? $displayResponsive : 'block'};
  }
`;

export const FloatRightButton = styled.div`
  position: fixed;
  right: 20px;
  bottom: ${({ $bottom }) => ($bottom ? $bottom : '20px')};;
  z-index: 45;
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : '#9A84BC')};
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Aggiunta ombra */
  outline: none;

  @media (min-width: 769px) {
    display: none;
  }
`;

export const RightContainer = styled.div`
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  @media (max-width: 768px) {
    align-items: center;
    min-height: auto;
  }
`;

export const MoreInfoContainer = styled.div`
display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-end;
    gap: 12px;
    align-items: center;


  @media (max-width: 768px) {
    align-self: center;
    flex-direction: row;
    gap: 15px;
  }
`;

export const LocationContainer = styled.div`
  margin: 0;
  align-self: end;
  display: flex;
  align-items: center;
  gap: 5px;
`;
