import styled from 'styled-components';

export const Gallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const ImageTile = styled.div`
  width: 135px;
  height: 135px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: lightgray 50%;
  position: relative;
`;

export const ImageFile = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const RemoveImageButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const ImageUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 135px;
  height: 135px;
  margin: 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

export const ImageUploadSpan = styled.span`
  font-size: 64;
`;
