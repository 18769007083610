const eventsList = [
  { id: 0, label: "Al coperto" },
  { id: 1, label: "All'aperto" },
  { id: 2, label: "Pubblici" },
  { id: 3, label: "Privati" },
  { id: 4, label: "Aziendali" },
  { id: 5, label: "Matrimoni" },
];

export default eventsList;
