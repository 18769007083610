import { faPlus } from '@fortawesome/free-solid-svg-icons'; // Sostituisci con l'icona desiderata
import IconButton from '../../buttons/IconButton/IconButton';
import { Image, ImageContainer } from './SquareImageElements';

const SquareImage = ({ src }) => {
  return (
    <ImageContainer>
      {src && <Image src={src} alt='Card Image' />}
      {!src && <IconButton icon={faPlus} />}
    </ImageContainer>
  );
};

export default SquareImage;
