import { Content, HorizontalBarContainer } from './HorizontalBarElements';

const HorizontalBar = ({
  spaceBetween,
  direction,
  margin,
  children,
  bgColor,
  color,
  gap,
  mediaQuery,
  hidden,
  displayResponsive,
  padding
}) => {
  return (
    <HorizontalBarContainer $padding={padding} $hidden={hidden} $bgColor={bgColor} $color={color} $displayResponsive={displayResponsive}>
      <Content
        $gap={gap}
        $spaceBetween={spaceBetween}
        $direction={direction}
        $margin={margin}
        $mediaQuery={mediaQuery}
      >
        {children}
      </Content>
    </HorizontalBarContainer>
  );
};

export default HorizontalBar;
