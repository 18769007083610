import React, { forwardRef, useEffect } from 'react';
import {
  Gallery,
  ImageFile,
  ImageTile,
  ImageUpload,
  RemoveImageButton,
} from './GalleryFormElements';
import { Controller, useForm } from 'react-hook-form';
import { IconButtonContainer } from '../../buttons/IconButton/IconButtonElements';
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons'; // Sostituisci con l'icona desiderata
import { StyledForm } from '../PersonalDataForm/PersonalDataFormElements';
import { ErrorText } from '../../texts/TextsElements';
import { uploadImage } from '../../../../remote/musicians';

const GalleryForm = forwardRef(
  ({ submitForm, tmpUserRef, submitRef, formWidth }, ref) => {
    const {
      handleSubmit,
      formState: { errors },
      control,
      watch,
      setValue,
    } = useForm();

    const handleImageDelete = (index, e) => {
      e.preventDefault();
      const gallery = watch('gallery');
      console.log('gallery', gallery);
      const newGallery = gallery.filter((_, i) => i !== index);
      console.log('newGallery', newGallery);
      watch('gallery')?.map((image, index) => {
        console.log('image', image.imageUrl);
      });
      setValue('gallery', newGallery);
    };

    useEffect(() => {
      if (tmpUserRef?.current) {
        if (tmpUserRef.current.gallery) {
          setValue('gallery', tmpUserRef.current.gallery);
        }
      }
    }, [setValue, tmpUserRef]);

    return (
      <StyledForm
        ref={ref}
        onSubmit={handleSubmit(submitForm)}
        $width={formWidth}
      >
        <Gallery>
          {watch('gallery')?.map((image, index) => (
            <ImageTile key={index}>
              <ImageFile src={image.imageUrl} alt='Gallery' />
              <RemoveImageButton onClick={(e) => handleImageDelete(index, e)}>
                <IconButtonContainer
                  icon={faXmark}
                  color={'black'}
                  size={'xl'}
                />
              </RemoveImageButton>
            </ImageTile>
          ))}
          <ImageUpload>
            <label htmlFor='upload-button'>
              <IconButtonContainer icon={faPlus} color={'black'} size={'xl'} />
            </label>
          </ImageUpload>
          <Controller
            name='gallery'
            control={control}
            defaultValue={[]}
            rules={{
              validate: (value) =>
                (value && value.length > 0) ||
                "Per favore carica almeno un'immagine.",
            }}
            render={({ field }) => (
              <input
                id='upload-button'
                type='file'
                accept='.jpg,.jpeg,.png'
                multiple
                onChange={(event) => {
                  const files = Array.from(event.target.files);

                  const promises = files.map((file) => {
                    return new Promise((resolve, reject) => {
                      const reader = new FileReader();
                      reader.onloadend = function () {
                        resolve({ imageUrl: reader.result, file: file });
                      };
                      reader.onerror = reject;
                      // uploadImage(file,"prova.png")
                      reader.readAsDataURL(file);
                    });
                  });

                  Promise.all(promises).then((imageUrls) => {
                    console.log('imageUrls', imageUrls);
                    field.onChange([...field.value, ...imageUrls]);
                  });
                }}
                style={{ display: 'none' }}
              />
            )}
          />
        </Gallery>
        {errors.gallery && <ErrorText>{errors.gallery.message}</ErrorText>}
        <button ref={submitRef} type='submit' style={{ display: 'none' }} />
      </StyledForm>
    );
  }
);

export default GalleryForm;
