import DeletableButton from "../../presentationals/buttons/DeletableButton/DeletableButton";
import SecondaryButton from "../../presentationals/buttons/SecondaryButton/SecondaryButton";
import { ButtonContainer, Content, GlobalStyle, PopupContainer, PopupOverlay, TitleContent } from "./PopupElements";
import { SmallText, Text, Title1, Title1Home, Title2 } from "../../presentationals/texts/TextsElements";
import { Subheading } from "../../../screens/home/HomeElements";



const Popup = ({
  title,
  subtitle,
  children,
  onClose,
  onConfirm,
  textAlign,
  confirmButtonLabel,
  alignItems,
  loading
}) => {
  return (
    <>
      <GlobalStyle noScroll />
      <PopupOverlay>
        <PopupContainer $alignItems={alignItems}>
          <TitleContent>
            <Title1 $textAlign={textAlign}>{title}</Title1>
          </TitleContent>
          
          <Content>{children}</Content>
          {!loading && <ButtonContainer>
            
            {onConfirm &&  (
              <DeletableButton onClick={onConfirm}>
                {confirmButtonLabel ? confirmButtonLabel : 'Conferma'}
              </DeletableButton>
            )}
            <SecondaryButton onClick={onClose}>
              {onConfirm ? 'Annulla' : 'OK'}
            </SecondaryButton>
            
          </ButtonContainer>}
        </PopupContainer>
      </PopupOverlay>
    </>
  );
};

  export default Popup;
  