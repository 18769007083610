import Input from '../../../inputs/TextInput/TextInput';
import { VerticalLayout } from '../../Containers/layouts/VerticalLayout/VerticalLayout';
import HorizontalBar from '../../bars/HorizontalBar/HorizontalBar';
import DeletableButton from '../../buttons/DeletableButton/DeletableButton';
import SecondaryButton from '../../buttons/SecondaryButton/SecondaryButton';
import PasswordInput from '../../../inputs/PasswordText/PasswordInput';
import { Text } from '../../texts/TextsElements';
import { useForm } from 'react-hook-form';
import { ErrorText } from '../../texts/TextsElements';
import errorMessages from '../../../../utils/ClerkUtilities/ErrorsMessages';
import { emailPattern } from '../../../../utils/String';

const LoginForm = ({ onSignup, onReset, handleSignInSubmit, errorMsg }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onSubmit',
  });


  return (
    <form onSubmit={handleSubmit(handleSignInSubmit)}>
      <div
        style={{
          display: 'flex',
          width:"100%",

          flexDirection: 'column',
        }}
      >
        <VerticalLayout>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '90%',
              height: '100%',
              alignItems: 'flex-start',
            }}
          >
            <Input
              type='email'
              placeholder='Email'
              {...register('email', {
                required: errorMessages.form_email_required,
                pattern: {
                  value: emailPattern,
                  message: errorMessages.form_invalid_email,
                },
              })}
              $active={!!errors?.email?.message}
            />
            {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
            <PasswordInput
              placeholder='Password'
              {...register('password', {
                required: errorMessages.form_pass_required,
                minLength: {
                  value: 8,
                  message: errorMessages.form_password_length_too_short,
                },
              })}
              $active={!!errors?.password?.message}
            />
            {errors.password && (
              <ErrorText>{errors.password.message}</ErrorText>
            )}
            <HorizontalBar spaceBetween={true}>
              <Text> </Text>
              <SecondaryButton
                color='#B0B0B0'
                fontSize={'14px'}
                fontWeight={'normal'}
                onClick={onReset}
              >
                Ho dimenticato la password
              </SecondaryButton>
            </HorizontalBar>
            {errorMsg && <ErrorText>{errorMsg}</ErrorText>}
          </div>
        </VerticalLayout>
        <VerticalLayout>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <DeletableButton type='submit'>Accedi</DeletableButton>
            <SecondaryButton onClick={onSignup}>
              Non ho un account
            </SecondaryButton>
          </div>
        </VerticalLayout>
      </div>
    </form>
  );
};

export default LoginForm;
