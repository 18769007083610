import styled from 'styled-components';

export const InputContainer = styled.div`
  background-color: transparent;
  width: 100%;
  height: 320px;
  font-size: 16px;
  position: relative;
  overflow-wrap: break-word; /* Il testo va a capo quando supera i bordi */
  padding: 0 0 20px 0;
  border: none;
  text-align: left;
  box-sizing: border-box;
  margin: ${({ $margin }) => ($margin || '0')};
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  font-size: 16px;
  
  font-family: Raleway Regular;
  outline: none;
  resize: none;
  overflow: auto;
  box-sizing: border-box;
  background-color: #f0efff;
  text-align: left;
  color: #a7a3ff;
  border-radius: 20px;
  padding: 20px;
  border: 1px solid transparent;
  &::placeholder {
    color: #a7a3ff;
  }
  &:focus {
    border: 1px solid #a7a3ff;
  }
  ${({ $active }) =>
    $active &&
    `
    border: 1px solid #bf1650;
    background: #FFE5E5;
    &::placeholder {
      color: #F00;
    }
  `}
`;
