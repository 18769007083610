import styled from 'styled-components';

export const HorizontalSplitLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
 
  height: calc(100dvh - 40px);
  margin-top: 40px;
`;

export const LeftContent = styled.div`
    flex:1;
    @media (max-width: 768px) {
      display: none;
    }
`;

export const RightContent = styled.div`
    flex:1;
    display:flex;
    justify-content: center;
    width:100%;
    align-items: center;
    flex-direction:column;
`;

export const InnerRightContent = styled.div`
  width: ${(props) => (props.width ? props.width : '50%')};
  
  @media (max-width: 1201px) {
    width: 100%;
  }
`;

export const Img1 = styled.img`
/* Altezza personalizzata */
height: ${(props) => props.height || '200px'}; /* Imposta un'altezza predefinita di 200px se non viene fornita una prop 'height' */

/* Bordo sinistro dritto */
border-left: 1px solid black; /* Cambia 'black' con il colore desiderato */

/* Bordo destro tondeggiante */
border-right: 10px solid transparent; /* Imposta la larghezza del bordo destro tondeggiante */

/* Proprietà per rendere il bordo destro tondeggiante */
border-image: linear-gradient(180deg, transparent, black); /* Imposta il colore del bordo destro */

/* Imposta il ritorno a capo per l'immagine per evitare che il testo adiacente venga disturbato dal bordo */
display: block;
`;

export const Img2 = styled.img`
    flex:1;
`;

export const Img3 = styled.img`
    flex:1;
`;

export const NavBarContainer = styled.div`
  
  width: 95%;
  height: 40px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;
  position: fixed;
`;

export const LogoContainer = styled.div`
  position: absolute;
  left: 20px;
  top: 65%;
  transform: translateY(-50%);
`;