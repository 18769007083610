import Input from '../../../inputs/TextInput/TextInput';
import { VerticalLayout } from '../../Containers/layouts/VerticalLayout/VerticalLayout';
import DeletableButton from '../../buttons/DeletableButton/DeletableButton';
import SecondaryButton from '../../buttons/SecondaryButton/SecondaryButton';
import PasswordInput from '../../../inputs/PasswordText/PasswordInput';
import { ErrorText } from '../../texts/TextsElements';
import { useForm } from 'react-hook-form';
import errorMessages from '../../../../utils/ClerkUtilities/ErrorsMessages';
import TermsCheckbox from '../../../functionals/TermsCheckbox/TermsCheckbox';
import { useState } from 'react';
import { emailPattern } from '../../../../utils/String';


const SignupForm = ({ handleSignUpSubmit, onSignin, errorMsg }) => {

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    mode: "onSubmit"
  });
  const password = watch('password', '');

  const [isChecked,setIsChecked] = useState(false);

  return (
    <form onSubmit={handleSubmit(handleSignUpSubmit)}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <VerticalLayout>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '90%',
              height: '100%',
              alignItems: 'flex-start',
            }}
          >
            <Input
              type='email'
              {...register('email', {
                required: errorMessages.form_email_required,
                pattern: {
                  value: emailPattern,
                  message: errorMessages.form_invalid_email,
                },
              })}
              placeholder='Email'
              $active={!!errors?.email?.message}
            />
            {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
            <PasswordInput
              {...register('password', {
                required: errorMessages.form_pass_required,
                minLength: {
                  value: 8,
                  message: errorMessages.form_password_length_too_short,
                },
              })}
              placeholder='Password'
              $active={!!errors?.password?.message}
            />
            {errors.password && (
              <ErrorText>{errors.password.message}</ErrorText>
            )}
            <PasswordInput
              {...register('confirmPassword', {
                required: errorMessages.form_confirm_pass_required,
                minLength: {
                  value: 8,
                  message: errorMessages.form_password_length_too_short,
                },
                validate: (value) =>
                  value === password || 'The passwords do not match',
              })}
              placeholder='Conferma password'
              $active={!!errors?.confirmPassword?.message}
            />
            {errors.confirmPassword && (
              <ErrorText>{errors.confirmPassword.message}</ErrorText>
            )}
            {errorMsg && <ErrorText>{errorMsg}</ErrorText>}
          </div>
          <TermsCheckbox isChecked={isChecked} onChange={()=>setIsChecked(!isChecked)} />
        </VerticalLayout>
        <VerticalLayout>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <DeletableButton disabled={!isChecked} type='submit'>Registrati</DeletableButton>
            <SecondaryButton onClick={onSignin}>
              Ho già un account
            </SecondaryButton>
          </div>
        </VerticalLayout>
      </div>
    </form>
  );
};

export default SignupForm;
