import { faAngleDoubleDown, faEnvelope, faPersonCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import YouTube from 'react-youtube';
import SearchBar from '../../components/functionals/SearchBar/SearchBar';
import TitledContainer from '../../components/presentationals/Containers/TitledContainer/TitledContainer';
import HalfScreen from '../../components/presentationals/Containers/layouts/HalfScreen/HalfScreen';
import WholeScreen from '../../components/presentationals/Containers/layouts/WholeScreen/WholeScreen';
import { WholeScreenInnerContainer } from '../../components/presentationals/Containers/layouts/WholeScreen/WholeScreenElements';
import Footer from '../../components/presentationals/Footer/Footer';
import Header from '../../components/presentationals/Header/Header';
import PartnersSlider from '../../components/presentationals/PartnersSlider/PartnersSlider';
import DeletableButton from '../../components/presentationals/buttons/DeletableButton/DeletableButton';
import { RightFixedButton } from '../../components/presentationals/buttons/DeletableButton/DeletableButtonElements';
import IconButton from '../../components/presentationals/buttons/IconButton/IconButton';
import { IconButtonContainer } from '../../components/presentationals/buttons/IconButton/IconButtonElements';
import SquareImage from '../../components/presentationals/media/SquareImage/SquareImage';
import { SmallText, TextLink, Title1Home } from '../../components/presentationals/texts/TextsElements';
import Images from '../../utils/Images';
import { FloatRightButton, VideoContainer } from '../musician/publicProfile/PublicProfileElements';
import {
  Banner,
  BottomText,
  CenterTextContainer,
  DoubleDownArrow,
  FirstHalfContainer,
  Heading,
  ImageStack,
  LineClamper,
  Paragraph,
  ProfileImage,
  Row,
  SecondHalfContainer,
  Subheading
} from './ConsultingElements';
import Popup from "../../components/functionals/Popup/Popup";
import { PopupModal } from "react-calendly";
import { SecondaryClickableText } from "../../components/presentationals/buttons/SecondaryButton/SecondaryButtonElements";


function Consulting() {
  const navigate = useNavigate();
  const [consulting,setConsulting] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia("(max-width: 1024px)").matches;
      setIsMobile(isMobile);
    };

    handleResize(); // Controlla le dimensioni iniziali

    window.addEventListener("resize", handleResize); // Aggiungi un listener per rilevare i cambiamenti di dimensione

    return () => {
      window.removeEventListener("resize", handleResize); // Rimuovi il listener quando il componente viene smontato
    };
  }, []);

  return (
    <>
      

      {/* {consulting && <Popup
            title={'Consigliami'}
            content={""}
            onClose={() => {
              setConsulting(false)
            }}
            onConfirm={() => {
              setConsulting(false)
            }}
            textAlign={'center'}
          >
            <div style={{display:'flex',flexDirection:'row'}}>

            <FirstHalfContainer>
            
            <LineClamper>
            <Paragraph $textColor={'#fff'} style={{ margin: '0' }}>
            ### Chi Siamo

            Benvenuti su **Orchestra**, l'agenzia di organizzazione eventi che connette musicisti talentuosi con organizzatori alla ricerca di performance indimenticabili. La nostra missione è trasformare ogni evento in un'esperienza unica grazie al potere della musica.

            Con un ampio network di artisti, dai solisti alle band, offriamo soluzioni musicali su misura per matrimoni, feste, eventi aziendali e molto altro. Collaboriamo strettamente con gli organizzatori per comprendere le loro esigenze e garantire professionalità e qualità in ogni dettaglio.

            Affidati a **Orchestra** per creare l'armonia perfetta per il tuo evento. La musica è la nostra passione, la tua soddisfazione è la nostra missione.
              </Paragraph>
          </LineClamper>
          </FirstHalfContainer>
          <SecondHalfContainer>
            <SquareImage src={Images.tmp.banner} />
          </SecondHalfContainer>

          </div>
        
        </Popup>} */}
      <Header />

      <Banner>
        <WholeScreenInnerContainer style={{ position: 'relative' }}>
          <CenterTextContainer>
            
            <Title1Home $fontSize={"22px"} $bold={true} $textAlign={"center"}>
              Non sai quale sia la musica adatta per la tua occasione?<br/>
              Credi che un musicista classico non sappia suonare i tuoi brani del cuore?<br/>
              Hai dei dubbi sulla logistica per il tuo evento?
            </Title1Home>
            <Title1Home $fontSize={"22px"} $textAlign={"center"}>
              Ti aiutiamo a progettare il tuo evento.<br/>
              Prenota la tua <span style={{fontFamily:'Raleway Bold'}}>consulenza privata di 30 minuti</span>, raccontaci il tuo giorno speciale e noi provvederemo alla colonna sonora dei tuoi sogni. <br/>
              Riceverai la nostra <span style={{fontFamily:'Raleway Bold'}}>proposta dettagliata entro 7 giorni lavorativi</span>.

            </Title1Home>
            
            <PopupModal
                url="https://calendly.com/orchestrasrl"
               
                onModalClose={() => setConsulting(false)}
                open={consulting}
                /*
                * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                */
                rootElement={document.getElementById("root")}
              />
            
            
            <DeletableButton onClick={()=>setConsulting(true)}>Consigliami</DeletableButton>
            <TextLink href={"#"}  $textColor={"#000"} $textAlign='center'> Termini e condizioni</TextLink>
          
          </CenterTextContainer>
          
        </WholeScreenInnerContainer>
        <Footer />
      </Banner>

      

      

      

     
    </>
  );
}

export default Consulting;
