import { Heading } from '../../../../../screens/home/HomeElements';
import {
  WholeScreenInnerContainer,
  WholeScreenMainContainer,
} from './WholeScreenElements';

const WholeScreen = ({ title,color, bgColor, style, children }) => {
  return (
   
    <WholeScreenMainContainer $bgColor={bgColor} color={color} style={style}>
      
      {title && <Heading style={{ margin: '0', color:color, border:'1px solid green' }}>{title}</Heading>}
      <WholeScreenInnerContainer>{children}</WholeScreenInnerContainer>
    </WholeScreenMainContainer>
  );
};

export default WholeScreen;
