import styled from 'styled-components';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  grid-gap: 20px;
  width:100%;  

  @media (max-width:1080px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`;

export const CustomGridContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props)=>"repeat(auto-fill, minmax("+props.perc+"%, 1fr))"};
  grid-gap: 20px;
  margin-top:5%;
  padding-top:5%;
  margin-bottom:5%;
  padding-bottom:5%;
  overflow-x: hidden;
  width:100%;

  

  @media (max-width:1080px) {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
`;