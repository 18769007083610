import { useEffect, useState } from 'react';
import { PopupModal } from "react-calendly";
import { useNavigate } from 'react-router';
import { WholeScreenInnerContainer } from '../../components/presentationals/Containers/layouts/WholeScreen/WholeScreenElements';
import Footer from '../../components/presentationals/Footer/Footer';
import Header from '../../components/presentationals/Header/Header';
import DeletableButton from '../../components/presentationals/buttons/DeletableButton/DeletableButton';
import { SmallText, TextLink } from '../../components/presentationals/texts/TextsElements';
import {
  Banner,
  CenterTextContainer,
  FirstHalfContainer,
  Heading,
  LineClamper,
  Paragraph,
  SecondHalfContainer,
  Subheading
} from './ChiSiamoElements';
import WholeScreen from '../../components/presentationals/Containers/layouts/WholeScreen/WholeScreen';
import Images from '../../utils/Images';
import SquareImage from '../../components/presentationals/media/SquareImage/SquareImage';
import HalfScreen from '../../components/presentationals/Containers/layouts/HalfScreen/HalfScreen';
import SecondaryButton from '../../components/presentationals/buttons/SecondaryButton/SecondaryButton';
import { SecondaryClickableText } from '../../components/presentationals/buttons/SecondaryButton/SecondaryButtonElements';


function ChiSiamo() {
  const navigate = useNavigate();
  const [consulting,setConsulting] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia("(max-width: 1024px)").matches;
      setIsMobile(isMobile);
    };

    handleResize(); // Controlla le dimensioni iniziali

    window.addEventListener("resize", handleResize); // Aggiungi un listener per rilevare i cambiamenti di dimensione

    return () => {
      window.removeEventListener("resize", handleResize); // Rimuovi il listener quando il componente viene smontato
    };
  }, []);

  return (
    <>
      

      {/* {consulting && <Popup
            title={'Consigliami'}
            content={""}
            onClose={() => {
              setConsulting(false)
            }}
            onConfirm={() => {
              setConsulting(false)
            }}
            textAlign={'center'}
          >
            <div style={{display:'flex',flexDirection:'row'}}>

            <FirstHalfContainer>
            
            <LineClamper>
            <Paragraph $textColor={'#fff'} style={{ margin: '0' }}>
            ### Chi Siamo

            Benvenuti su **Orchestra**, l'agenzia di organizzazione eventi che connette musicisti talentuosi con organizzatori alla ricerca di performance indimenticabili. La nostra missione è trasformare ogni evento in un'esperienza unica grazie al potere della musica.

            Con un ampio network di artisti, dai solisti alle band, offriamo soluzioni musicali su misura per matrimoni, feste, eventi aziendali e molto altro. Collaboriamo strettamente con gli organizzatori per comprendere le loro esigenze e garantire professionalità e qualità in ogni dettaglio.

            Affidati a **Orchestra** per creare l'armonia perfetta per il tuo evento. La musica è la nostra passione, la tua soddisfazione è la nostra missione.
              </Paragraph>
          </LineClamper>
          </FirstHalfContainer>
          <SecondHalfContainer>
            <SquareImage src={Images.tmp.banner} />
          </SecondHalfContainer>

          </div>
        
        </Popup>} */}
      <Header />
      <HalfScreen bgColor={"white"} >
      <WholeScreenInnerContainer style={{ position: 'relative' }}>
      <FirstHalfContainer style={{padding:0}}>
            
            
            <Paragraph $textColor={'#000'} style={{ margin: '0', textAlign:'justify' }}>
                Benvenuti su <span style={{fontFamily:'Raleway Bold'}}>Orchestra</span>, la piattaforma digitale concepita per facilitare l'integrazione professionale dei musicisti provenienti dai conservatori musicali nazionali. Fondata con l'obiettivo di rispondere alle sfide incontrate dai neodiplomati nel settore musicale, Orchestra si distingue per il suo impegno verso la promozione della trasparenza e dell'etica professionale.
              </Paragraph>
              <Paragraph $textColor={'#000'} style={{ margin: '0', textAlign:'justify' }}>
                L'idea di Orchestra è nata dalla necessità di fornire un ambiente dove i musicisti possano connettersi direttamente con opportunità di carriera valide e sostenibili. La piattaforma mira a migliorare l'accessibilità al mercato del lavoro musicale, mitigando problemi come il lavoro nero, rincari esorbitanti, e problematiche contrattuali.
                </Paragraph>
                <Paragraph $textColor={'#000'} style={{ margin: '0', textAlign:'justify' }}>
                Con Orchestra, i privati possono trovare talenti musicali di alto livello, mentre i musicisti beneficiano di una piattaforma che promuove la loro crescita professionale e personale attraverso opportunità educative e collaborazioni significative.

              </Paragraph>
            
              
            </FirstHalfContainer>
            <SecondHalfContainer>
              <div style={{marginTop:20}}>
                <SquareImage  src={Images.tmp.chiSiamo} />
              </div>
              <div style={{display:'flex',flexDirection:'row', marginTop:"5px"}}>
                <SmallText><SecondaryClickableText onClick={()=>window.open("https://www.linkedin.com/in/viviana-nebuloni-b51157146/")} >Viviana Nebuloni</SecondaryClickableText> e <SecondaryClickableText onClick={()=>window.open("https://www.linkedin.com/in/marina-serpagli-898b606a/")}>Marina Serpagli</SecondaryClickableText> fondatrici di Orchestra S.R.L.</SmallText>
              
              </div>
              
          </SecondHalfContainer>
          </WholeScreenInnerContainer>

      </HalfScreen>
      
        <Footer />
     


      
        

          
         
          
         

      
     

      

      

      

     
    </>
  );
}

export default ChiSiamo;
