import styled from 'styled-components';

export const LargeTextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  border-radius: 30px;
  background-color: #f0efff;
  color: #333;
  transition: border-color 0.3s ease;
  min-height:300px;
  &:hover {
    border-color: #007bff;
  }
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Aggiunta ombra */
  position: relative;
`;

export const CharCounter = styled.div`
align-self: flex-end;
text-align: right;
font-size: 14px;
color: #a7a3ff;
`;