import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, {keyframes} from 'styled-components';

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
`;

export const IconButtonContainer = styled(FontAwesomeIcon)`
  cursor: pointer;
  
  color: ${({color}) => (color ? color : '#9a84bc')};
  // font-size: 16px; /* Puoi impostare la dimensione del font desiderata qui */
  &:active {
    animation: ${pulseAnimation} 0.2s ease-in-out;
  }
`;