import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import HalfScreen from '../../components/presentationals/Containers/layouts/HalfScreen/HalfScreen';
import { WholeScreenInnerContainer } from '../../components/presentationals/Containers/layouts/WholeScreen/WholeScreenElements';
import Footer from '../../components/presentationals/Footer/Footer';
import Header from '../../components/presentationals/Header/Header';
import { SecondaryClickableText } from '../../components/presentationals/buttons/SecondaryButton/SecondaryButtonElements';
import SquareImage from '../../components/presentationals/media/SquareImage/SquareImage';
import { SmallText } from '../../components/presentationals/texts/TextsElements';
import Images from '../../utils/Images';
import {
  FirstHalfContainer,
  LineClamper,
  Paragraph,
  SecondHalfContainer
} from './MissionElements';


function Mission() {
  const navigate = useNavigate();
  const [consulting,setConsulting] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia("(max-width: 1024px)").matches;
      setIsMobile(isMobile);
    };

    handleResize(); // Controlla le dimensioni iniziali

    window.addEventListener("resize", handleResize); // Aggiungi un listener per rilevare i cambiamenti di dimensione

    return () => {
      window.removeEventListener("resize", handleResize); // Rimuovi il listener quando il componente viene smontato
    };
  }, []);

  return (
    <>
      

      {/* {consulting && <Popup
            title={'Consigliami'}
            content={""}
            onClose={() => {
              setConsulting(false)
            }}
            onConfirm={() => {
              setConsulting(false)
            }}
            textAlign={'center'}
          >
            <div style={{display:'flex',flexDirection:'row'}}>

            <FirstHalfContainer>
            
            <LineClamper>
            <Paragraph $textColor={'#fff'} style={{ margin: '0' }}>
            ### Chi Siamo

            Benvenuti su **Orchestra**, l'agenzia di organizzazione eventi che connette musicisti talentuosi con organizzatori alla ricerca di performance indimenticabili. La nostra missione è trasformare ogni evento in un'esperienza unica grazie al potere della musica.

            Con un ampio network di artisti, dai solisti alle band, offriamo soluzioni musicali su misura per matrimoni, feste, eventi aziendali e molto altro. Collaboriamo strettamente con gli organizzatori per comprendere le loro esigenze e garantire professionalità e qualità in ogni dettaglio.

            Affidati a **Orchestra** per creare l'armonia perfetta per il tuo evento. La musica è la nostra passione, la tua soddisfazione è la nostra missione.
              </Paragraph>
          </LineClamper>
          </FirstHalfContainer>
          <SecondHalfContainer>
            <SquareImage src={Images.tmp.banner} />
          </SecondHalfContainer>

          </div>
        
        </Popup>} */}
      <Header />
      <HalfScreen bgColor={"white"} >
      <WholeScreenInnerContainer style={{ position: 'relative' }}>
      <FirstHalfContainer style={{padding:40}}>
          <SquareImage src={Images.tmp.mission} />
            
              
            </FirstHalfContainer>
            <SecondHalfContainer>
            
            
            <Paragraph $textColor={'#000'} style={{ margin: '0',textAlign:'justify' }}>
                <span style={{fontFamily: 'Raleway Bold'}}>Orchestra</span> ama e sostiene il lavoro dei musicisti, offrendo loro concrete opportunità professionali. La piattaforma pone al centro della sua operatività la trasparenza e la correttezza, garantendo che ogni scambio e comunicazione sia basato su valori di rispetto, merito e dignità. 
                
            </Paragraph>
            <Paragraph $textColor={'#000'} style={{ marginTop: '10px', textAlign:'justify' }}>
                Gli artisti di Orchestra sono selezionati secondo criteri rigorosi di competenza e qualità, eliminando qualsiasi forma di discriminazione o favoritismo. Analogamente, chi cerca musicisti con queste doti trova in Orchestra l’interlocutore privilegiato per soddisfare le proprie esigenze musicali o ricevere consulenze adeguate.
                </Paragraph>
              <Paragraph $textColor={'#000'} style={{ marginTop: '10px',textAlign:'justify' }}>
                Orchestra facilita il passaggio dalla formazione alla professione con un semplice click, fornendo un ambiente dove il talento musicale può fiorire e essere valorizzato attraverso opportunità concrete e supporto continuo.

              </Paragraph>
           
              
          </SecondHalfContainer>
          </WholeScreenInnerContainer>

      </HalfScreen>
      
        <Footer />
     


      
        

          
         
          
         

      
     

      

      

      

     
    </>
  );
}

export default Mission;
