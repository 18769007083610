import {
  useClerk,
  useUser,
} from "@clerk/clerk-react";
import { faCalendar, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faEuroSign, faLocationDot, faMusic, faPhone, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Popup from '../../components/functionals/Popup/Popup';
import { TwoColumnGridContainer } from '../../components/presentationals/Containers/GridContainer/TwoColumnsGridContainer/TwoColumnsGridContainer';
import TitledContainer from "../../components/presentationals/Containers/TitledContainer/TitledContainer";
import HSplitScreen from "../../components/presentationals/Containers/layouts/HSplitScreen/HSplitScreen";
import { HorizontalLayout } from "../../components/presentationals/Containers/layouts/HorizontalLayout/HorizontalLayout";
import HorizontalBar from '../../components/presentationals/bars/HorizontalBar/HorizontalBar';
import DeletableButton from '../../components/presentationals/buttons/DeletableButton/DeletableButton';
import { IconButtonContainer } from "../../components/presentationals/buttons/IconButton/IconButtonElements";
import { HorizontalListContainer } from "../../components/presentationals/cards/AnnouncementCard/AnnouncementCardElements";
import MainCard from '../../components/presentationals/cards/MainCard/MainCard';
import { GridContainer, Tag, TagIcon } from '../../components/presentationals/cards/MainCard/MainCardElements';
import SelectableCard from '../../components/presentationals/cards/SelectableCard/SelectableCard';
import RequestForm from '../../components/presentationals/forms/RequestForm/RequestForm';
import {
  SmallText, Text
} from '../../components/presentationals/texts/TextsElements';
import { AnnouncementStates } from "../../config/AnnouncementConsts";
import { ADMIN, USER } from '../../config/profile/Roles';
import genres from '../../data/genres';
import musicalInstruments from '../../data/instruments';
import { applyToTheAnnouncement, getAnnouncement, getApplications } from "../../remote/announcements";
import { disableMusician, getMusicians, validateMusician } from '../../remote/musicians';
import { useStateValue } from '../../stores/StateProvider';
import SignInPopup from "../../userManagement/signIn/signInPopup";
import getToken from '../../userManagement/utilities';
import { haversineDistance } from '../../utils/Geolocalization';
import Loader from "../../utils/Loader";
import { dateTimeToString } from "../../utils/String";
import { Paragraph } from "../home/HomeElements";
import { MoreInfoContainer } from "../musician/publicProfile/PublicProfileElements";
import SecondaryButton from "../../components/presentationals/buttons/SecondaryButton/SecondaryButton";
import ClipLoader from "react-spinners/ClipLoader";

function EventDetail({onApply}) {
  const [{userData}] = useStateValue();
  const [place, setPlace] = useState('');
  const [reset, setReset] = useState(false);


  let { announcement } = useParams();

  const [loadingMusicians,setLoadingMusicians] = useState(true)
  const [loading,setLoading] = useState(false)

  const [unfilterData,setUnfilterData] = useState();

  const [dateR, setDateR] = useState('');

  const [announcementObj,setAnnouncementObj] = useState(null);

  const [data, setData] = useState([]);


  const [showLoginPopup, setShowLoginPopup] = useState(false);
  

  const dateRRef = useRef();
  dateRRef.current = dateR;
  const placeRef = useRef();
  placeRef.current = place;

  const [notActiveFilter, setNotActiveFilter] = useState(
    userData?.role == ADMIN
  );

  const { session } = useClerk();
  const { isSignedIn } = useUser();
  const requestFormRef = useRef();
  const url = window.location.href; 

  const filterCloseCities = (cityA, cityB,minD,maxD) => {
  
    const distance = haversineDistance(cityA.y, cityA.x, cityB.y, cityB.x);

    // Considera gli utenti entro 10 km dalla città target
    return distance>=minD && distance <= maxD;
  };

  const loadAnnouncement = async()=>{
    let jwt = await getToken(session)
    console.log("Announcement. ",announcement)
    let announcementResponse = await getAnnouncement(announcement,jwt)
    console.log("AnnnouncementResponse: ",announcementResponse)
    setAnnouncementObj(announcementResponse)
   
  }

  useEffect(()=>{
    loadAnnouncement()

  },[])


  

  


  useEffect(() => {
    if (isSignedIn) setShowLoginPopup(false);
  }, [isSignedIn])

  

  const navigate = useNavigate();

  const [limit, setLimit] = useState(6);

  const [requestOpen, setRequestOpen] = useState(null);
  const [validateOpen, setValidateOpen] = useState(null);
  const [disableOpen, setDisableOpen] = useState(null);

  const [contactPopup,setContactPopup] = useState(null);

  const [instrumentPopup, setInstrumentPopup] = useState(false);

  const [genrePopup, setGenrePopup] = useState(false);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedInstruments, setSelectedInstruments] = useState([]);
  const heightMarks = {
    10: '10Km',
    
    20: '20Km',
    30: '30Km',

    40: '40Km',

    50: '50Km',

    60: '60Km',

    70: '70Km',

    80: '80Km'
  };


  const filterDataByGenres = (item) => {
    if (selectedGenres.length > 0) {
      return item.genres.find((x) => selectedGenres.includes(x.id));
    }

    return true;
  };

  const filterDataByInstruments = (item) => {
    if (selectedInstruments.length > 0) {
      return item.instruments.find((x) => selectedInstruments.includes(x.id));
    }

    return true;
  };

  const filterDataByNotActive = (item) => {
    if (!item.isOnboardingCompleted) {
      return false;
    }

    if (userData?.role === ADMIN && notActiveFilter) {
      return !item.valid;
    }

    if (userData?.role !== ADMIN) {
      return item.valid;
    }

    return true;
  };

  const filterData = (item) => {
    return (
      
      filterDataByGenres(item) &&
      filterDataByInstruments(item)
    );
  };

  


  const handleApplyToTheAnnouncement = async(id)=>{
    setLoading(true)
    let t = await getToken(session);
    await applyToTheAnnouncement(id,t)
    await onApply()
    setLoading(false)
  }
  

  console.log("HEEEEERE", announcementObj);

  if (announcementObj && announcementObj.status!=AnnouncementStates.OPEN)
    return <Navigate to="/me/announcements" />

  return (
    <>
      {showLoginPopup && (
        <SignInPopup setShowLoginPopup={() => setShowLoginPopup(false)} />
      )}

      {contactPopup && (
        <Popup
          title={
            'Informazioni di contatto per ' +
            contactPopup.firstName +
            ' ' +
            contactPopup.lastName
          }
          onClose={() => setContactPopup(null)}
          textAlign='center'
          alignItems={'flex-start'}
        >
          <div
            style={{
              gap: '10px',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '20px',
              fontSize: '18px',
              alignItems: 'flex-start',
              margin: 'auto auto 20px',
            }}
          >
            <>
              <div>
                <FontAwesomeIcon
                  icon={faPhone}
                  // color='#DF6951'
                  size='xs'
                ></FontAwesomeIcon>
                {/* <i class="fa-solid fa-phone"></i></i>   */}
                <span
                  style={{
                    marginLeft: '15px',
                    fontWeight: '300',
                  }}
                >
                  Phone
                </span>
                <span
                  style={{
                    fontWeight: '100',
                    marginLeft: '20px',
                  }}
                >
                  {contactPopup.phone}
                </span>
              </div>

              <div>
                <FontAwesomeIcon icon={faEnvelope} size='xs'></FontAwesomeIcon>
                <span style={{ marginLeft: '15px', fontWeight: '300' }}>
                  Email
                </span>
                <span
                  style={{
                    fontWeight: '100',
                    marginLeft: '30px',
                  }}
                >
                  <a href={`mailto:${contactPopup?.email}`}>{contactPopup.email}</a>
                </span>
              </div>
            </>
          </div>
        </Popup>
      )}

      <HSplitScreen
        width='100%'
        outerMargin='20px 0'
       
        left={<></>}
        right={
          <HorizontalLayout
            $justifyContent='flex-end'
            // style={{ paddingLeft: '20px' }}
          >
             {announcementObj?.instruments?.map((instrument, index) => {
                      let obj = musicalInstruments.filter((x) => x.id === instrument)[0]
                      return (
                        <Tag>
                          {/* <TagIcon
                            key={index}
                            src={
                              obj.img
                            }
                            
                            alt={obj.name}
                          /> */}
                           <SmallText style={{color:'#9A84BC'}}>{obj.label}</SmallText>
                      </Tag>
                      );
                    })}
          </HorizontalLayout>
        }
      />
      <HSplitScreen
        outerPadding={'0px 0px 0px 0px'}
        outerMargin={'0 0 0px 0'}
        width={'100%'}
        alignItems={'flex-start'}
        rightSideAlignItems={'flex-end'}
        left={<HorizontalLayout style={{ flexDirection: 'column', gap: '10px' }}>
                <div onClick={()=>{navigate("/announcements")}} style={{cursor:'pointer',display:'flex', gap:10, alignItems:'center',flexDirection:'left',alignSelf:'flex-start'}}>
                  <IconButtonContainer
                            icon={faArrowLeft}
                            color={'#9A84BC'}
                            size={'s'}
                          />
                  <SmallText>Torna agli annunci</SmallText>
                </div>
                <TitledContainer
                    margin={'0 0 10px 0'}
                    titleMargin={'0 0 20px 0'}
                    title={
                      announcementObj?.title}
                    
                    textAlignMobile={'center'}
                    textAlign={'left'}
                    centered={'flex-start'}
                  >
                    <Paragraph $textAlign={'left'}>{announcementObj?.description}</Paragraph>
                  </TitledContainer>
          </HorizontalLayout>}
        right={
         
            <HorizontalLayout style={{ height:"100%", flexDirection: 'column',  gap: '10px' }}>

              {announcementObj && <MoreInfoContainer>
                <div
                  style={{
                    columnGap: '5px',
                    alignSelf: 'end',
                    display: 'flex',
                  }}
                >
                  
                    <>
                      <div style={{paddingRight:"10px"}}>
                        <IconButtonContainer
                          icon={faCalendar}
                          color={'#9A84BC'}
                          size={'s'}
                        />
                      </div>
                      <SmallText>{dateTimeToString(announcementObj?.date)}</SmallText>
                      
                    </>
                    
                  
                 
                </div>
              </MoreInfoContainer>}
              {announcementObj && <MoreInfoContainer>
                
                <div
                  style={{
                    columnGap: '5px',
                    alignSelf: 'end',
                    display: 'flex',
                  }}
                >
                  
                   
                    <>
                      <div style={{paddingRight:"10px"}}>
                        <IconButtonContainer
                          icon={faLocationDot}
                          color={'#9A84BC'}
                          size={'s'}
                        />
                      </div>
                      <SmallText >
                        {announcementObj.location.raw.display_name}
                      </SmallText>
                      
                    </>
                  
                 
                </div>
              </MoreInfoContainer>}
              {announcementObj && <MoreInfoContainer>
                
                <div
                  style={{
                    columnGap: '5px',
                    alignSelf: 'end',
                    display: 'flex',
                  }}
                >
                  
                   
                    <>
                      <div style={{paddingRight:"10px"}}>
                          <IconButtonContainer
                            icon={faEuroSign}
                            color={'#9A84BC'}
                            size={'s'}
                          />
                        </div>
                      <SmallText >
                        {announcementObj.budget}
                      </SmallText>
                      
                    </>
                  
                 
                </div>
              </MoreInfoContainer>}

              {announcementObj && <MoreInfoContainer>
                
                <div
                  style={{
                    columnGap: '5px',
                    alignSelf: 'end',
                    display: 'flex',
                  }}
                >
                  
                   
                    <>
                      <div style={{paddingRight:"10px"}}>
                          <IconButtonContainer
                            icon={faMusic}
                            color={'#9A84BC'}
                            size={'s'}
                          />
                        </div>
                      <SmallText >
                        {announcementObj.genres.map(x=>genres.filter(y=>y.id==x)[0].label).join(", ")}
                      </SmallText>
                      
                    </>
                  
                 
                </div>
              </MoreInfoContainer>}

              
              
              
            </HorizontalLayout>
            
         
        }
      />
      {announcementObj && <div >
                
                {userData?.valid && !userData.applications.some(x=>x.announcement.id==announcementObj.id) && 
                <div style={{display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
                  {!loading && <DeletableButton onClick={()=>handleApplyToTheAnnouncement(announcementObj.id)}>{announcementObj.id.price && announcementObj.price>0?"Paga e candidati":"Candidati"}</DeletableButton>}
                  {loading && <ClipLoader/>}
                </div >
                }

                {userData.applications.some(x=>x.announcement.id==announcementObj.id) && 
                                <div style={{display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
                                  <SmallText>Hai già applicato a questo annuncio!</SmallText>
                                </div >
                                }
                   
                    </div>}
     
      

      

      

      

      
      
      
    </>
  );
}

export default EventDetail;
