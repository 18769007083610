import { forwardRef, useImperativeHandle, useState } from 'react';
import Images from '../../../../utils/Images';
import { HorizontalLayout } from '../../Containers/layouts/HorizontalLayout/HorizontalLayout';
import SelectableSquareButton from '../../buttons/SelectableSquareButton/SelectableSquareButton';
import Input from '../../../inputs/TextInput/TextInput';
import Popup from '../../../functionals/Popup/Popup';
import PaymentMethodCard from '../../cards/PaymentMethodCard/PaymentMethodCard';
import { VerticalLayout } from '../../Containers/layouts/VerticalLayout/VerticalLayout';
import DeletableButton from '../../buttons/DeletableButton/DeletableButton';
import { useForm } from 'react-hook-form';
import { StyledForm } from '../PersonalDataForm/PersonalDataFormElements';
import { Content } from '../../../functionals/Popup/PopupElements';
import { MediaCarouselWrapper } from '../../../functionals/MediaCasousel/MediaCarouselElements';
import { FullSizeContainer } from './PaymentMethodsFormElements';
import { ImageContainer } from '../../media/SquareImage/SquareImageElements';
import { Image } from '../../buttons/SelectableSquareButton/SelectableSquareButtonElements';

const PaymentMethodsForm = forwardRef(
  ({ submitForm, tmpUserRef, submitRef, fullWidth }, ref) => {
    const [bankPopup, setBankPopup] = useState(false);
    const [account, setAccount] = useState(null);

    const closePopup = () => setBankPopup(false);

    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
    } = useForm({
      defaultValues: {
        account: [],
      },
      mode: 'all',
    });

    return (
      <>
        <StyledForm ref={ref} onSubmit={handleSubmit(submitForm)}>
          {!account && (
            <HorizontalLayout $alignItems='start'>
              <MediaCarouselWrapper>
                <ImageContainer>
                  <Image src={Images.icons.bankTransferIcon} alt='Card Image' />
                </ImageContainer>
                <FullSizeContainer $fullWidth={fullWidth}>
                  <Input
                    type='text'
                    placeholder='Nome e cognomme del titolare'
                  />
                  <Input type='text' placeholder='Nome della banca' />
                  <Input type='text' placeholder='IBAN' />
                  <Input type='text' placeholder='Codice SWIFT/BIC' />
                </FullSizeContainer>
              </MediaCarouselWrapper>
              {/* <SelectableSquareButton
                label='Paypal'
                imageSrc={Images.icons.paypalIcon}
                onClick={() => console.log('paypal')}
              /> */}
            </HorizontalLayout>
          )}

          {account && (
            <VerticalLayout>
              <PaymentMethodCard title='Conto bancario aggiunto correttamente' />
              <DeletableButton onClick={() => setAccount(false)}>
                Sosstituisci
              </DeletableButton>
            </VerticalLayout>
          )}
          <button ref={submitRef} type='submit' style={{ display: 'none' }} />
        </StyledForm>

        {bankPopup && (
          <Popup
            title={'Collega il tuo conto'}
            content='Contenuto del Popup'
            onClose={closePopup}
            onConfirm={() => {
              console.log('Azione confermata');
              setAccount(true);
              setBankPopup(false);
            }}
          >
            <Input type='text' placeholder='Nome e cognomme del titolare' />
            <Input type='text' placeholder='Nome della banca' />
            <Input type='text' placeholder='IBAN' />
            <Input type='text' placeholder='Codice SWIFT/BIC' />
          </Popup>
        )}
      </>
    );
  }
);

export default PaymentMethodsForm;
