import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 50;
  width: 100%;
  height: 50px;

  background-color: ${($props) =>
    $props.home == true ? 'transparent': '#fff'};
  padding: 15px 0;
  box-shadow: ${($props) =>
    $props.home == true ? '' : '0px 2px 4px rgba(0, 0, 0, 0.1)'};
`;

export const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 850px) {
    width: 90%;
  }
  @media (max-width: 350px) {
    width: ${({ $width }) => $width ? '100%' : '90%'};
  }
  max-width: 1300px;
`;

export const Box = styled.div`
display: none;
justify-content: center;
align-items: center;
position: fixed;
top: 80px;
z-index: 49;
width: 100%;
height: 30px;

background-color: #FFA3A3;
// padding: 15px 0;
box-shadow: ${($props) =>
  $props.home == true ? '' : '0px 2px 4px rgba(0, 0, 0, 0.1)'};

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const RightElements = styled.div`
  display: flex;
  gap: 15px;
  position: relative;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const HamBurgerMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  background-color: #fff;
  height:40px;
  width:40px;
  border-radius: 20px;
  
`;

export const Menu = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50px;
  right: 0px;
  background: #fff;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
  min-width: 200px;
  width: 200px @media (min-width: 768px) {
    display: none;
  }
  ${(props) =>
    props.$isOpen &&
    css`
      display: flex;
    `}

  > button {
    padding: 0;
  }
`;

export const StyledText = styled.div`
  background-color: #ffa3a3;
  color: #fffefe;
  padding: 8px 20px 8px 30px;
  border-radius: 20px;
  font-family: Raleway Regular;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  text-wrap: nowrap;
`;

export const ProfileAvatar = styled.img`
  width: ${({ $width }) => $width || '32px'};
  height: ${({ $height }) => $height || '32px'};
  border: ${({ $isBorderShown }) => $isBorderShown ? '3px solid #ff2b00' : '3px solid transparent'};
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  object-fit: cover;
  cursor: pointer;
`;

export const Email = styled.div`
  color: #000;
  font-family: Raleway Regular;
  font-size: 15px;
  font-weight: 600;
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
`;

export const StyledLink = styled(RouterLink)`
  position: relative;
  padding: 10px 0px;
  color: ${({ $color }) => ($color ? $color : '#9A84BC')};
  border: none;
  border-radius: 20px;
  font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : '16px')};
  font-weight: ${({ $fontWeight }) => ($fontWeight ? $fontWeight : 'bold')};
  text-decoration: none; /* Aggiunge la sottolineatura */
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')}; /* Imposta il cursore appropriato */

  /* Stili per lo stato normale */
  background-color: transparent;
  transition: color 0.3s; /* Cambia solo il colore del testo */

  /* Stili per lo stato di hover */
  &:not(:disabled):hover {
    text-decoration: underline; /* Cambia il colore del testo durante l'hover */
  }

  /* Stili per lo stato di click */
  &:not(:disabled):active {
    animation: ${({ disabled }) => (disabled ? 'none' : pulseAnimation)} 0.2s ease-in-out;
  }
  @media (max-width: 850px) {
    margin: 5px; 
    margin-top: 0;
    padding: 5px 0px;
  }
`;

export const StyledA = styled.a`
  position: relative;
  padding: 10px 0px;
  color: ${({ $color }) => ($color ? $color : '#df6951')};
  border: none;
  border-radius: 20px;
  font-family: Raleway Regular;
  font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : '16px')};
  font-weight: ${({ $fontWeight }) => ($fontWeight ? $fontWeight : 'bold')};
  text-decoration: none; /* Aggiunge la sottolineatura */
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')}; /* Imposta il cursore appropriato */

  /* Stili per lo stato normale */
  background-color: transparent;
  transition: color 0.3s; /* Cambia solo il colore del testo */

  /* Stili per lo stato di hover */
  &:not(:disabled):hover {
    text-decoration: underline; /* Cambia il colore del testo durante l'hover */
  }

  /* Stili per lo stato di click */
  &:not(:disabled):active {
    animation: ${({ disabled }) => (disabled ? 'none' : pulseAnimation)} 0.2s ease-in-out;
  }
  @media (max-width: 850px) {
    margin: 5px; 
    margin-top: 0;
    padding: 5px 0px;
  }
`;

export const FullScreenMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  z-index: 999;
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
`;

export const HideDesktopDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 325px) {
    gap: ${({ $gap }) => $gap ? '0px' : '10px'};
  }
`;
