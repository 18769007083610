import {
  TwoColumnResponsiveGrid,
  TwoColumnGridWithMargin,
} from '../../Containers/GridContainer/TwoColumnsGridContainer/TwoColumnsGridContainer';
import genresList from '../../../../data/genres';
import SelectableCard from '../../cards/SelectableCard/SelectableCard';
import { useEffect, useState } from 'react';
import { forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorText } from '../../texts/TextsElements';
import Popup from '../../../functionals/Popup/Popup';
import Input from '../../../inputs/TextInput/TextInput';
import { StyledForm } from '../PersonalDataForm/PersonalDataFormElements';

const GenresForm = forwardRef(
  ({ submitForm, tmpUserRef, submitRef }, ref) => {
    const [genres, setGenres] = useState([]);
    const [popupVisible, setPopupVisible] = useState(false);
    const [lastAddedId, setLastAddedId] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    const handleGenreToggle = ({id, label}) => {
      let tmpgenres = [...genres];
      if (genres.some((genre) => genre.id === id)) {
        tmpgenres = tmpgenres.filter((x) => x.id !== id);
      } else if (tmpgenres.length < 5) {
        tmpgenres.push({ id, label, songs: [] });
        setLastAddedId(id);
        setPopupVisible(true);
      }
      setGenres(tmpgenres);
      setValue('genres', tmpgenres);
    };

    const confirmSongsPopup = (e) => {
      e.preventDefault();
      const song1 = document.querySelector(
        'input[placeholder="Brano 1"]'
      ).value;
      const song2 = document.querySelector(
        'input[placeholder="Brano 2"]'
      ).value;
      const song3 = document.querySelector(
        'input[placeholder="Brano 3"]'
      ).value;

      if (song1 && song2 && song3) {
        let tmpgenres = [...genres];
        tmpgenres[tmpgenres.length - 1].songs.push(
          song1,
          song2,
          song3
        );
        setGenres(tmpgenres);
        setValue('genres', tmpgenres);
        setPopupVisible(false);
        setErrorMsg(null);
      } else {
        setErrorMsg('Inserisci tre canzoni');
      }
    };

    const closeSongsPopup = () => {
      if (lastAddedId !== null) {
        setPopupVisible(false);
        handleGenreToggle({id: lastAddedId});
        setLastAddedId(null);
        setErrorMsg(null);
      }
    };

    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
    } = useForm({
      defaultValues: {
        genres: [],
      },
      mode: 'all',
    });

    useEffect(() => {
      if (tmpUserRef?.current) {
        if (tmpUserRef.current.genres) {
          setGenres(tmpUserRef.current.genres);
          setValue('genres', tmpUserRef.current.genres);
        }
      }
    }, [setValue, tmpUserRef]);

    register('genres', {
      validate: (value) =>
        value.length <= 5 || 'puoi selezionare massimo 5 generi',
    });
    const genre = genresList.filter((x) => x.id === lastAddedId);

    return (
      <div style={{ width: '99%' }}>
        {popupVisible && (
          <form>
            <Popup
              title={`Inserisci tre brani appartenenti a questo genere che esegui spesso o che ti vengono richiesti frequentemente`}
              content='Contenuto del Popup'
              confirmButtonLabel='AGGIUNGI'
              onClose={closeSongsPopup}
              textAlign='center'
              onConfirm={confirmSongsPopup}
              disabled={genres.length < 3}
            >
              <Input type='text' placeholder='Brano 1' />
              <Input type='text' placeholder='Brano 2' />
              <Input type='text' placeholder='Brano 3' />
              {errorMsg && <ErrorText>{errorMsg}</ErrorText>}
            </Popup>
          </form>
        )}
        <StyledForm ref={ref} onSubmit={handleSubmit(submitForm)}>
          <TwoColumnGridWithMargin>
            <TwoColumnResponsiveGrid>
              {genresList.map((x, index) => (
                <SelectableCard
                  selected={genres.some(
                    (genre) => genre.id === x.id
                  )}
                  onClick={() => handleGenreToggle(x)}
                  key={index}
                >
                  <p>{x.label}</p>
                </SelectableCard>
              ))}
            </TwoColumnResponsiveGrid>
            {errors.genres && (
              <ErrorText>{errors.genres.message}</ErrorText>
            )}
          </TwoColumnGridWithMargin>
          <button ref={submitRef} type='submit' style={{ display: 'none' }} />
        </StyledForm>
      </div>
    );
  }
);

export default GenresForm;
