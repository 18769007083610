import { useClerk } from '@clerk/clerk-react';
import { faArrowUp, faSave, faXmark } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { ToastContainer, toast } from 'react-toastify';
import Popup from '../../../components/functionals/Popup/Popup';
import Input from '../../../components/inputs/TextInput/TextInput';
import { TwoColumnGridContainer } from '../../../components/presentationals/Containers/GridContainer/TwoColumnsGridContainer/TwoColumnsGridContainer';
import TitledContainer from '../../../components/presentationals/Containers/TitledContainer/TitledContainer';
import { HorizontalLayout } from '../../../components/presentationals/Containers/layouts/HorizontalLayout/HorizontalLayout';
import { IconButtonContainer } from '../../../components/presentationals/buttons/IconButton/IconButtonElements';
import SecondaryButton from '../../../components/presentationals/buttons/SecondaryButton/SecondaryButton';
import ExpandableCards from '../../../components/presentationals/cards/ExpandableCards/ExpandableCards';
import { Container } from '../../../components/presentationals/cards/ExpandableCards/ExpandableCardsElements';
import {
  Icon,
  Tag,
  TagContainer
} from '../../../components/presentationals/cards/MainCard/MainCardElements';
import SelectableCard from '../../../components/presentationals/cards/SelectableCard/SelectableCard';
import CompanyDataForm from '../../../components/presentationals/forms/CompanyDataForm/CompanyDataForm';
import GroupDataForm from '../../../components/presentationals/forms/GroupDataForm/GroupDataForm';
import InvoiceDataForm from '../../../components/presentationals/forms/InvoiceDataForm/InvoiceDataForm';
import MoreInfoForm from '../../../components/presentationals/forms/MoreInfoForm/MoreInfoForm';
import PersonalDataForm from '../../../components/presentationals/forms/PersonalDataForm/PersonalDataForm';
import PremiumMusicianForm from '../../../components/presentationals/forms/PremiumMusicianForm/PremiumMusicianForm';
import { ErrorText, SmallText, Text } from '../../../components/presentationals/texts/TextsElements';
import { MUSICIAN } from '../../../config/profile/Roles';
import eventsList from '../../../data/events';
import { default as allGenres, default as genresList } from '../../../data/genres';
import musicalInstruments from '../../../data/instruments';
import { uploadFile } from '../../../remote/bucket';
import { updateMe } from '../../../remote/me';
import { cancelSubcription, createSubscription } from '../../../remote/payments';
import { useStateValue } from '../../../stores/StateProvider';
import getToken from '../../../userManagement/utilities';
import Loader from '../../../utils/Loader';

function Account({ musician, setUpdatedData }) {
  const [firstShowPopup, setFirstShowPopup] = useState(true);
  const [popupVisible, setPopupVisible] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const { session } = useClerk();
  const [instrumentPopup, setInstrumentPopup] = useState(false);
  const [instruments, setInstruments] = useState([]);
  const [genres, setGenres] = useState([]);
  const [songsPopupVisible, setSongsPopupVisible] = useState(false);
  const [currentSongs, setCurrentSongs] = useState([]);
  const [currentGenre, setCurrentGenre] = useState(null);
  const [genrePopup, setGenrePopup] = useState(false);
  const [lastAddedId, setLastAddedId] = useState(null);
  const [eventPopup, setEventPopup] = useState(false);
  const [events, setEvents] = useState([]);

  const [errorMsg, setErrorMsg] = useState(null);

  const submitProfileRef = useRef();
 
  const submitMoreInfoRef = useRef();
  const submitPremiumRef = useRef();
  const submitGroupRef = useRef();


  const submitInvoiceRef = useRef();

  const ref = useRef();
  const tmpUserRef = useRef(_.cloneDeep(musician));


  const [{ userData: userDataState }] = useStateValue();
  const [userData, setUserData] = useState(userDataState);

  const [message, setMessage] = useState('');

  



  const handleSubscriptionMusician = async() => {

    if(!loading) {
      if(userDataState.premiumPlan) {
        await cancelSubcriptionMusician();
      } else {
        await subcribeMusician();
      }
    }

  }


  const cancelSubcriptionMusician = async() => {
    setLoading(true)


    const jwtToken = await session.getToken();


    const userId = userDataState.id;
    const subscriptionId = userDataState.subscriptionId;


    // cancel subscription
    
    const response = await cancelSubcription(
      jwtToken,
      {
        userId: userId,
        subscriptionId: subscriptionId,
      }
    );

    if(response.ok) {
        response.json().then(json => {
            if(json.message === 'Subscription cancelled') {
                window.location.reload();
            } else {
                console.log("Qui 1")
                toast.error(`Qualcosa è andato storto`);
               
            }
        });
    } else {
      console.log("Qui 2")
      toast.error(`Qualcosa è andato storto`);
    }

    setLoading(false)
  }



  const subcribeMusician = async() => {
    setLoading(true)

    const jwtToken = await session.getToken();


    let subscriptionId = undefined; // from 1 (inclusive) to 5 (inclusive)
    switch(userDataState.group.groupType) {
      case '':
        subscriptionId = 1;
      break;
      case 'duo':
        subscriptionId = 2;
      break;
      case 'trio':
        subscriptionId = 3;
      break;
      case 'quartetto':
        subscriptionId = 4;
      break;
      case 'quintetto':
        subscriptionId = 5;
      break;
      case 'more':
        subscriptionId = 5; // the maximum is 5 people €25
      break;
      default:
        subscriptionId = 1;
        break;
    }

    const userId = userDataState.id;
    

    // pay
    
    const response = await createSubscription(
      jwtToken,
      {
        userId: userId,
        subscriptionId: subscriptionId,
      }
    );

    if(response.ok) {
        response.json().then(json => {            
            if(json.url) {
               window.location.href = json.url;
            } else {
              console.log("Qui 4")
              toast.error(`Qualcosa è andato storto`);
            }
        });
    } else {
      console.log("Qui 5")
      toast.error(`Qualcosa è andato storto`);
    }

    setLoading(false)
  }


  useEffect(() => {
    console.log("musician: ",musician)
    const musicianCopy = _.cloneDeep(musician);
    if(musicianCopy.role === MUSICIAN) {
      setInstruments([ ...musicianCopy.instruments ]);
      setGenres([ ...musicianCopy.genres ]);
    } else {
      musicianCopy.events && setEvents([ ...musicianCopy.events ]);
    }
  }, []);


  const handleSubmit = async (changeValid = true) => {
   

    if (_.isEqual(tmpUserRef.current, musician)) {
      setUpdating(false);
      return;
    }

    let token = await getToken(session);

    delete tmpUserRef.current.file

    updateMe(
      {user: tmpUserRef.current, changeValid:changeValid},
      token,
      (err) => {
        setUpdating(false);
        toast.error(`Error: ${err}`);
      },
      (res) => {
        setUpdating(false);
        const finalTmpUserRef = _.cloneDeep(tmpUserRef.current);
        setUpdatedData(finalTmpUserRef);
        if (res) toast.success('Profilo aggiornato con successo');
      }
    );
  };

  const handleConfirm = async (data,changeValid = true) => {
    console.log('updated data 2', data);

    setUpdating(true);
    console.log("Updating... ",updating)
    
    let newTmpMusician = data;
    if (newTmpMusician == null) return;
    tmpUserRef.current = { ...tmpUserRef.current, ...newTmpMusician };
    if (musician.valid && firstShowPopup && changeValid) {
      setPopupVisible(true);
    } else {
      handleSubmit(changeValid);
    }
  };

  const handleUploadeMoreInfo = async (data,filePdf,changeValid = true) => {
    let token = await getToken(session);
    setUpdating(true);

    console.log("Data: ",data)

    let newTmpMusician = data;
    if (newTmpMusician == null) return;
    tmpUserRef.current = { ...tmpUserRef.current, ...newTmpMusician };

    if (filePdf){
      console.log("updating... ",filePdf)
      uploadFile(filePdf.file,
                 filePdf.name,
                 token, 
                 (error)=>{console.log("error: ",error); setUpdating(false)},
                 ()=>{
                  if (musician.valid && firstShowPopup && changeValid) {
                    setPopupVisible(true);
                  } else {
                    handleSubmit(changeValid);
                  }
                 }
                )
              }
    else{
        
        if (musician.valid && firstShowPopup && changeValid) {
          setPopupVisible(true);
        } else {
          handleSubmit(changeValid);
        }
      }
  };

  const handleUpdatePremiumInfo = async (data) => {
    setUpdating(true);
    console.log('updated data 3', data);
    let newTmpMusician = data;
    if (newTmpMusician == null) return;
    tmpUserRef.current = { ...tmpUserRef.current, ...{premium: {...newTmpMusician}} };
    
    handleSubmit(false);
    
  };

  const [isFileAvailable, setIsFileAvailable] = useState(false);
  const [isCVAvailable, setIsCVAvailable] = useState(false);
  const fileUrl = `https://orchestra-diploma.s3.eu-central-1.amazonaws.com/${musician.id}.pdf`;
  const cvUrl = `https://orchestra-diploma.s3.eu-central-1.amazonaws.com/cv/${musician.id}.pdf`;

  useEffect(() => {
    // Funzione per verificare l'esistenza del file
    const checkFileExists = async () => {
      try {
        await axios.head(fileUrl);
        setIsFileAvailable(true);
      } catch (error) {
        setIsFileAvailable(false);
      }
    };

    const checkCVExists = async () => {
      try {
        await axios.head(cvUrl);
        setIsCVAvailable(true);
      } catch (error) {
        setIsCVAvailable(false);
      }
    };

    checkFileExists();
    checkCVExists();
  }, [fileUrl]);

  

  const handleUpdateGroupInfo = async (data) => {
    setUpdating(true);
    console.log('updated data 4', data);
    let newTmpMusician = data;
    if (newTmpMusician == null) return;
    tmpUserRef.current = { ...tmpUserRef.current, ...newTmpMusician };
    handleSubmit(false);
    
  };

  const handleInstrumentToggle = ({ id, label }) => {
    let tmp = [...instruments];
    if (instruments.some((instrument) => instrument.id === id)) {
      tmp = tmp.filter((x) => x.id !== id);
    } else if (tmp.length < 3) {
      tmp.push({ id, label });
    }
    tmpUserRef.current = { ...tmpUserRef.current, instruments: tmp };
    setInstruments(tmp);
  };

  const handleEventToggle = ({ id, label }) => {
    let tmp = [...events];
    if (events.some((event) => event.id === id)) {
      tmp = tmp.filter((x) => x.id !== id);
    } else if (tmp.length < 3) {
      tmp.push({ id, label });
    }
    tmpUserRef.current = { ...tmpUserRef.current, events: tmp };
    setEvents(tmp);
  };

  const handleGenreToggle = ({id, label}) => {
    let tmpgenres = [...genres];
    if (genres.some((genre) => genre.id === id)) {
      tmpgenres = tmpgenres.filter((x) => x.id !== id);
    } else if (tmpgenres.length < 3) {
      tmpgenres.push({ id, label, songs: [] });
      setLastAddedId(id);
      setSongsPopupVisible(true);
    }
    setGenres(tmpgenres);
  };

  const handleGenreSongDelete = (genreTitle, songId) => {
    console.log('genreTitle', genreTitle);
    console.log('songId', songId);
    let tmp = [...genres];
    tmp = tmp.map((x) => {
      if (x.label === genreTitle) {
        console.log('x.songs', x.songs);
        x.songs = x.songs.filter((y) => y !== songId);
      }
      return x;
    });
    tmpUserRef.current = { ...tmpUserRef.current, genres: tmp };
    console.log('tmp', tmp);
    setGenres(tmp);
  };

  const handleGenreDelete = (genreTitle) => {
    let tmp = [...genres];
    tmp = tmp.filter((x) => x.label !== genreTitle);
    console.log('tmp genre', tmp);

    tmpUserRef.current = { ...tmpUserRef.current, genres: tmp };
    setGenres(tmp);
  };

  const closeSongsPopup = () => {
    if (lastAddedId !== null) {
      setSongsPopupVisible(false);
      handleGenreToggle({id: lastAddedId});
      setLastAddedId(null);
      setErrorMsg(null);
    }
  };

  useEffect(()=>{
    console.log("Updating: ",updating)
  },[updating])

  const confirmSongsPopup = (e) => {
    e.preventDefault();
    const song1 = document.querySelector('input[placeholder="Brano 1"]').value;
    const song2 = document.querySelector('input[placeholder="Brano 2"]').value;
    const song3 = document.querySelector('input[placeholder="Brano 3"]').value;

    if (song1 && song2 && song3) {
      let tmpgenres = [...genres];
      tmpgenres = tmpgenres.map((x) => {
        if (x.label === currentGenre) {
          console.log('x.songs', x.songs);
          x.songs = [];
          x.songs.push(song1, song2, song3);
        }
        return x;
      });
      setGenres(tmpgenres);
      tmpUserRef.current = { ...tmpUserRef.current, genres: tmpgenres };
      setCurrentSongs([]);
      setSongsPopupVisible(false);
      setErrorMsg(null);
    } else {
      setErrorMsg('Inserisci tre canzoni');
    }
  };
// console.log('currentGenre', currentGenre);
// console.log('currentSongs', currentSongs);

  const genre = genresList.filter((x) => x.id === lastAddedId);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia("(max-width: 767px)").matches;
      setIsMobile(isMobile);
    };

    handleResize(); // Controlla le dimensioni iniziali

    window.addEventListener("resize", handleResize); // Aggiungi un listener per rilevare i cambiamenti di dimensione

    return () => {
      window.removeEventListener("resize", handleResize); // Rimuovi il listener quando il componente viene smontato
    };
  }, []);

  return (
    <>
      {loading && <Loader />}
      <ToastContainer pauseOnHover={false} />
      {instrumentPopup && (
        <Popup
          title={'Scegli fino a 5 strumenti'}
          onClose={() => setInstrumentPopup(false)}
          onConfirm={() => {
            setInstrumentPopup(false);
          }}
        >
          <TwoColumnGridContainer>
            {musicalInstruments.map((x) => (
              <SelectableCard
                selected={instruments.some(
                  (instrument) => instrument.id === x.id
                )}
                onClick={() => handleInstrumentToggle(x)}
                key={x.id}
              >
                {/* <TagIcon src={x.img} alt={x.name} $pt={'0'} /> */}
                <p>{x.label}</p>
              </SelectableCard>
            ))}
          </TwoColumnGridContainer>
        </Popup>
      )}
      {eventPopup && (
        <Popup
          title={'Scegli fino a 5 strumenti'}
          onClose={() => setEventPopup(false)}
          onConfirm={() => {
            setEventPopup(false);
          }}
        >
          <TwoColumnGridContainer>
            {eventsList.map((x) => (
              <SelectableCard
                selected={events.some((event) => event.id === x.id)}
                onClick={() => handleEventToggle(x)}
                key={x.id}
              >
                <p>{x.label}</p>
              </SelectableCard>
            ))}
          </TwoColumnGridContainer>
        </Popup>
      )}
      {genrePopup && (
        <Popup
          title={'Scegli il genere'}
          content='Contenuto '
          onClose={() => setGenrePopup(false)}
          onConfirm={() => {
            setGenrePopup(false);
          }}
        >
          <TwoColumnGridContainer>
            {genresList.map((x) => (
              <SelectableCard
                selected={genres.some((genre) => genre.id === x.id)}
                disabled={genres.some((genre) => genre.id === x.id)}
                onClick={() => {
                  setCurrentGenre(x.label);
                  handleGenreToggle(x)
                }}
                key={x.id}
              >
                <p>{x.label}</p>
              </SelectableCard>
            ))}
          </TwoColumnGridContainer>
        </Popup>
      )}

      {songsPopupVisible && (
        <form>
          <Popup
            title={`Inserisci tre brani appartenenti a questo genere che esegui spesso o che ti vengono richiesti frequentemente`}
            content='Contenuto del Popup'
            confirmButtonLabel='AGGIUNGI'
            onClose={closeSongsPopup}
            textAlign='center'
            onConfirm={confirmSongsPopup}
            disabled={genres.length < 3}
          >
            <Input
              type='text'
              placeholder='Brano 1'
              defaultValue={'' || currentSongs[0]}
            />
            <Input
              type='text'
              placeholder='Brano 2'
              defaultValue={'' || currentSongs[1]}
            />
            <Input
              type='text'
              placeholder='Brano 3'
              defaultValue={'' || currentSongs[2]}
            />
            {errorMsg && <ErrorText>{errorMsg}</ErrorText>}
          </Popup>
        </form>
      )}
      {popupVisible && (
        <Popup
          title={
            'Sei sicuro di voler aggiornare il tuo profilo? Questo disabiliterà il tuo profilo fino a che l\'amministratore non convalidi le modifiche'
        
          }
          content='Contenuto del Popup'
          onClose={() => {
            setLoading(false);
            tmpUserRef.current = { ...musician };
            setPopupVisible(false);
            setResetForm(!resetForm);
          }}
          onConfirm={() => {
            handleSubmit(true);
            setPopupVisible(false);
            setFirstShowPopup(false);
          }}
        ></Popup>
      )}
      <TitledContainer
        title={'Dati personali'}
        sideComponent={
          updating?<ClipLoader loading={true} />:
          isMobile?<Icon
          icon={faSave}
          onClick={() => {submitProfileRef.current.click();
          }}
        />:<SecondaryButton
            // disabled={!currentRef || !currentRef.current}
            onClick={() => {
              submitProfileRef.current.click();
            }}
         
          >
            Aggiorna
          </SecondaryButton>

        }
        mainPadding={'20px'}
        margin={'20px 0px'}
        boxShadow={'0px 0px 5px rgba(0, 0, 0, 0.3)'}
      >
        <PersonalDataForm
          ref={ref}
          submitForm={(data)=>handleConfirm(data,false)}
          tmpUserRef={tmpUserRef}
          submitRef={submitProfileRef}
          formWidth={'100%'}
          resetForm={resetForm}
        />
      </TitledContainer>
      {musician?.role==MUSICIAN && <TitledContainer
        title={'Dati della formazione'}
        sideComponent={
          updating?<ClipLoader loading={true} />:
          isMobile?<Icon
          icon={faSave}
          onClick={() => {submitGroupRef.current.click();
          }}
        />:<SecondaryButton
            // disabled={!currentRef || !currentRef.current}
            onClick={() => {
              submitGroupRef.current.click();
            }}
   
          >
            Aggiorna
          </SecondaryButton>

        }
        mainPadding={'20px'}
        margin={'20px 0px'}
        boxShadow={'0px 0px 5px rgba(0, 0, 0, 0.3)'}
      >
        <GroupDataForm
          ref={ref}
          submitForm={handleUpdateGroupInfo}
          tmpUserRef={tmpUserRef}
          submitRef={submitGroupRef}
          formWidth={'100%'}
          resetForm={resetForm}
        />
      </TitledContainer>}
      {musician?.role!=MUSICIAN && <TitledContainer
        title={'Dati aziendali'}
        sideComponent={
          updating?<ClipLoader loading={true} />:
          isMobile?<Icon
          icon={faSave}
          onClick={() => {submitGroupRef.current.click();
          }}
        />:<SecondaryButton
            // disabled={!currentRef || !currentRef.current}
            onClick={() => {
              submitGroupRef.current.click();
            }}

          >
            Aggiorna
          </SecondaryButton>

        }
        mainPadding={'20px'}
        margin={'20px 0px'}
        boxShadow={'0px 0px 5px rgba(0, 0, 0, 0.3)'}
      >
        <CompanyDataForm
          ref={ref}
          submitForm={handleUpdateGroupInfo}
          tmpUserRef={tmpUserRef}
          submitRef={submitGroupRef}
          formWidth={'100%'}
          resetForm={resetForm}
        />
      </TitledContainer>}
     
      {musician?.role==MUSICIAN && <TitledContainer
        title={'Ulteriori informazioni'}
        sideComponent={
          updating?<ClipLoader loading={true} />:
          isMobile?<Icon
          icon={faSave}
          onClick={() => {
            submitMoreInfoRef.current.click();
          }}
        />:<SecondaryButton
            // disabled={!currentRef || !currentRef.current}
            onClick={() => {
              submitMoreInfoRef.current.click();
            }}
            
          >
            Aggiorna
          </SecondaryButton>

        }
       
        mainPadding={'20px'}
        margin={'20px 0px'}
        boxShadow={'0px 0px 5px rgba(0, 0, 0, 0.3)'}
      >
        <MoreInfoForm
          ref={ref}
          fileUrl = {isFileAvailable?fileUrl:null}
          submitForm={(data,file)=>handleUploadeMoreInfo(data,file,false)}
          tmpUserRef={tmpUserRef}
          submitRef={submitMoreInfoRef}
          fullWidth={'100%'}
          isMusicianRole={musician?.role === MUSICIAN}
          formWidth={'100%'}
          resetForm={resetForm}
        />
      </TitledContainer>
      }
    

      {musician.role === MUSICIAN && (
        <div
          style={{
            margin: '20px 0px',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
            borderRadius: '10px',
            padding: '20px',
          }}
        >
          <TitledContainer
            title={'I miei strumenti'}
            sideComponent={
              updating?<ClipLoader loading={true} />:
              isMobile?<Icon
              icon={faSave}
              onClick={() => {
                const invalidSongCount = genres.some(
                  (genre) => genre.songs.length !== 3
                );
                if (invalidSongCount) {
                  alert('Each genre must have at least 3 songs.');
                } else {
                  handleConfirm(tmpUserRef.current,false);
                }
              }}
            />:<SecondaryButton
                // disabled={!currentRef || !currentRef.current}
                onClick={() => {
                  const invalidSongCount = genres.some(
                    (genre) => genre.songs.length !== 3
                  );
                  if (invalidSongCount) {
                    alert('Each genre must have at least 3 songs.');
                  } else {
                    handleConfirm(tmpUserRef.current,false);
                  }
                }}
               
              >
                Aggiorna
              </SecondaryButton>
    
            }
          
            margin={'20px 0px'}
          >
            <HorizontalLayout
              $justifyContent='flex-start'
              style={{ marginTop: '10px' }}
            >
              <TagContainer $display='flex'>
                {instruments &&
                  instruments.map((x, index) => {
                    const currentInstrument = x.label;
                    return (
                      <Tag key={index}>
                        {/* <TagIcon
                          key={index}
                          src={
                            musicalInstruments.filter(
                              (instrument) => instrument.id == x.id
                            )[0].img
                          }
                          alt={x.name}
                        /> */}
                        {currentInstrument}
                        <IconButtonContainer
                          icon={faXmark}
                          color={'black'}
                          style={{ paddingLeft: '5px' }}
                          onClick={() => {
                            handleInstrumentToggle(x);
                          }}
                        />
                      </Tag>
                    );
                  })}
                {instruments && instruments.length < 5 && (
                  <Tag
                    $color={'white'}
                    $bgColor={'#a7a3ff'}
                    onClick={() => {
                      console.log('clicked');
                      setInstrumentPopup(true);
                    }}
                  >
                    {'Aggiungi'}
                  </Tag>
                )}
              </TagContainer>
            </HorizontalLayout>
          </TitledContainer>
          <TitledContainer
            title={'I miei cavalli di battaglia'}
            titleMargin={'20px 0'}
            sideComponent={<></>}
            margin={'20px 0px'}
          >
            <>
              {genres &&
                genres.map((x) => (
                  <ExpandableCards
                    modify
                    key={x.id}
                    isNotExpandible={false}
                    title={allGenres.filter((y) => y.id === x.id)[0].label}
                    secondaryItems={x.songs}
                    onSongsDelete={handleGenreSongDelete}
                    onGenreDeleteClick={() => handleGenreDelete(x.label)}
                    setSongsPopupVisible={setSongsPopupVisible}
                    onAddSongsClick={(songs, title) => {
                      setLastAddedId(x.id);
                      setCurrentSongs(songs);
                      setCurrentGenre(title);
                      setSongsPopupVisible(true);
                    }}
                  />
                ))}
              {genres && genres.length < 3 && (
                <>
                  <Container
                    onClick={() => {
                      setGenrePopup(true);
                    }}
                  >
                    <Text>{'Aggiungi'}</Text>
                  </Container>
                </>
              )}
            </>
          </TitledContainer>
        </div>
      )}
      {musician?.role==MUSICIAN && <TitledContainer
        title={'Piano premium'}
        bkgColor={"#F6F8ED"}
        titleMargin={'20px 0'}
        sideComponent={
          userData.premiumPlan?(
          isMobile?<Icon
          icon={faSave}
          onClick={() => {submitPremiumRef.current.click();
          }}
        />:<SecondaryButton
            // disabled={!currentRef || !currentRef.current}
            onClick={() => {
              submitPremiumRef.current.click();
            }}
           
          >
            Aggiorna
          </SecondaryButton>):(isMobile?<Icon
          icon={faArrowUp}
          onClick={() => {handleSubscriptionMusician();
          }}
        />:<SecondaryButton
            // disabled={!currentRef || !currentRef.current}
            onClick={() => {
              handleSubscriptionMusician();
            }}
          >
            {!loading && (
              <>
                {userData && (
                  <>
                    {!userData.premiumPlan && (
                      <>
                        Upgrade
                      </>
                    )}
                    {/* {userData.premiumPlan && (
                      <>
                        Cancella abbonamento
                      </>
                    )} */}
                  </>
                )}
                {/* {(message && message.length > 0) && (
                  <>
                    {message}
                  </>
                )} */}
              </>
            )}
            {loading && (
              <ClipLoader loading={true} />
            )}
          </SecondaryButton>)


        }
       
        mainPadding={'20px'}
        margin={'20px 0px'}
        boxShadow={'0px 0px 5px rgba(0, 0, 0, 0.3)'}
      >

        {musician.premiumPlan && <PremiumMusicianForm 
          ref={ref}
          submitForm={handleUpdatePremiumInfo}
          tmpUserRef={tmpUserRef}
          submitRef={submitPremiumRef}
          fullWidth={'100%'}
          isMusicianRole={musician?.role === MUSICIAN}
          formWidth={'100%'}
          resetForm={resetForm} />}
        {!musician.premiumPlan && <SmallText>Il piano premium ti consente di aggiungere informazioni al tuo profilo. Puoi acquistarlo direttamente premendo sul pulsante "upgrade"</SmallText>}
        
      
      </TitledContainer>}
      <TitledContainer
        title={'Dati di fatturazione'}
        titleMargin={'20px 0'}
        sideComponent={
          updating?<ClipLoader/>:
          isMobile?<Icon
          icon={faSave}
          onClick={() => {submitInvoiceRef.current.click();
          }}
        />:<SecondaryButton
            // disabled={!currentRef || !currentRef.current}
            onClick={() => {
              submitInvoiceRef.current.click();
            }}
           
          >
            Aggiorna
          </SecondaryButton>

        }
       
        mainPadding={'20px'}
        margin={'20px 0px'}
        boxShadow={'0px 0px 5px rgba(0, 0, 0, 0.3)'}
      >

        <InvoiceDataForm
          ref={ref}
          submitForm={(data)=>handleConfirm(data,true)}
          tmpUserRef={tmpUserRef}
          submitRef={submitInvoiceRef}
          fullWidth={'100%'}
          isMusicianRole={musician?.role === MUSICIAN}
          formWidth={'100%'}
          resetForm={resetForm} />
        
       
      </TitledContainer>
     
    </>
  );
}

export default Account;
