import { SET_MUSICIANS } from "../actions/MusiciansActions";

const musiciansReducer = (state, action) => {
  switch (action.subtype) {
    case SET_MUSICIANS:
      return { ...state, musicians: action.musicians };
    default:
      return state;
  }
};

export default musiciansReducer;
