import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PhoneInput from 'react-phone-number-input';

export const InputWithError = styled.div`
  display: flex;
  flex-direction: ${({ $direction }) => $direction || 'column'};
  align-items: ${({ $alignItems }) => $alignItems || 'flex-start'};
`;

export const StyledPhoneInput = styled(PhoneInput)`
  background-color: #f0efff;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: normal;
  text-align: left;
  color: #a7a3ff;
  outline: none;

  &::placeholder {
    color: #a7a3ff;
  }

  &:focus {
    border: 1px solid #a7a3ff;
  }

  ${({ $active }) =>
    $active &&
    `
      border: 1px solid #bf1650;
      background: #FFE5E5;

      &::placeholder {
        color: #F00;
      }
    `}
`;

export const StyledDatePicker = styled(DatePicker)`
  background-color: ${({ $backgroundColor }) => ($backgroundColor ? $backgroundColor : 'transparent')};
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: normal;
  text-align: left;
  color: ${({ $color }) => ($color ? $color : '#a7a3ff')};
  
  outline: none;
  &::placeholder {
    color: ${({ $color }) => ($color ? $color : '#a7a3ff')};
  }
  &:focus {
    border: ${({ $border }) => ($border ? $border : '1px solid #a7a3ff')};
  }
  ${({ $active }) =>
    $active &&
    `
  border: 1px solid #bf1650;
  background: transparent;
  &::placeholder {
    color: #F00;
  }
`}
`;

export const StyledSelect = styled.select`
    background-color: #f0efff;
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: normal;
    text-align: left;
    color: #a7a3ff;
    outline: none;
    &::placeholder {
      color: #a7a3ff;
    }
    &:focus {
      border: 1px solid #a7a3ff;
    }
    ${({ $active }) =>
      $active &&
      `
    border: 1px solid #bf1650;
    background: #FFE5E5;
    color: #F00;
    &::placeholder {
    }
  `}
`;

export const StyledOption = styled.option`
  font-size: 1rem;
`;

export const StyledForm = styled.form`
  height: ${({ $width }) => ($width ? '100%' : 'calc(100dvh - 240px)')};
  min-height: ${({ $width }) => ($width ? '100%' : 'calc(100dvh - 240px)')};
  width: ${({ $width }) => $width || 'auto'};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    height: ${({ $width }) => ($width ? '100%' : 'calc(100dvh - 250px)')};
    min-height: ${({ $width }) => ($width ? '100%' : 'calc(100dvh - 250px)')};
    display: block;
  }
`;