import {
  LeftContent,
  RightContent,
  HorizontalSplitLayout,
  Img1,
  InnerRightContent,
  LogoContainer,
  NavBarContainer,
} from './HorizontalSplitScreenElements';
import IntroImage from '../../../media/IntroImage/IntroImage';
import { LeftText } from '../../../texts/TextsElements';
import Images from '../../../../../utils/Images';
import { useClerk, useUser } from '@clerk/clerk-react';
import SecondaryButton from '../../../buttons/SecondaryButton/SecondaryButton';
import Popup from '../../../../functionals/Popup/Popup';
import { useState } from 'react';
import Header from '../../../Header/Header';
import { useStateValue } from '../../../../../stores/StateProvider';
import { GENERAL } from '../../../../../stores/actions/GeneralActions';
import { RESET } from '../../../../../config/URLs';

const HorizontalSplitScreen = ({ children, innerWidth,header=true, }) => {

  const { signOut } = useClerk();
  const { isSignedIn } = useUser();
  const [showLogoutPopup, setShowLogoutPopup] = useState(false)
  const [state, dispatch] = useStateValue()

  

  return (
    <>

      {header && <Header />}
      <HorizontalSplitLayout>
        {/* <LeftContent>
          <IntroImage imageSrc={Images.tmp.guitar1} />
          <IntroImage
            width={'80%'}
            imageSrc={Images.tmp.piano}
          />
          <IntroImage
            width={'60%'}
            imageSrc={Images.tmp.canto}
          />
        </LeftContent>
        <RightContent>
          <InnerRightContent width={innerWidth}>{children}</InnerRightContent>
        </RightContent> */}
        <InnerRightContent width={innerWidth}>{children}</InnerRightContent>
      </HorizontalSplitLayout>
      {showLogoutPopup && (
          <Popup
            title={'Sei sicuro di volere uscire dall\'applicazione?'}
            content='Contenuto del Popup'
            onClose={() => {
              setShowLogoutPopup(false);
            }}
            onConfirm={() => {
              setShowLogoutPopup(false);
              signOut();
              dispatch({type:GENERAL,subtype:RESET})
              
            }}
            textAlign={'center'}
          ></Popup>
        )}
    </>
  );
};

export default HorizontalSplitScreen;
