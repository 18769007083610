import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { MainContainer, WholeScreenInnerContainer } from '../../components/presentationals/Containers/layouts/WholeScreen/WholeScreenElements';
import Footer from '../../components/presentationals/Footer/Footer';
import Header from '../../components/presentationals/Header/Header';
import SquareImage from '../../components/presentationals/media/SquareImage/SquareImage';
import { Title1 } from '../../components/presentationals/texts/TextsElements';
import Images from '../../utils/Images';
import {
  FirstHalfContainer,
  Paragraph,
  SecondHalfContainer
} from './ContactsElements';


function Contacts() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia("(max-width: 1024px)").matches;
      setIsMobile(isMobile);
    };

    handleResize(); // Controlla le dimensioni iniziali

    window.addEventListener("resize", handleResize); // Aggiungi un listener per rilevare i cambiamenti di dimensione

    return () => {
      window.removeEventListener("resize", handleResize); // Rimuovi il listener quando il componente viene smontato
    };
  }, []);

  return (
    <>
      

      {/* {consulting && <Popup
            title={'Consigliami'}
            content={""}
            onClose={() => {
              setConsulting(false)
            }}
            onConfirm={() => {
              setConsulting(false)
            }}
            textAlign={'center'}
          >
            <div style={{display:'flex',flexDirection:'row'}}>

            <FirstHalfContainer>
            
            <LineClamper>
            <Paragraph $textColor={'#fff'} style={{ margin: '0' }}>
            ### Chi Siamo

            Benvenuti su **Orchestra**, l'agenzia di organizzazione eventi che connette musicisti talentuosi con organizzatori alla ricerca di performance indimenticabili. La nostra missione è trasformare ogni evento in un'esperienza unica grazie al potere della musica.

            Con un ampio network di artisti, dai solisti alle band, offriamo soluzioni musicali su misura per matrimoni, feste, eventi aziendali e molto altro. Collaboriamo strettamente con gli organizzatori per comprendere le loro esigenze e garantire professionalità e qualità in ogni dettaglio.

            Affidati a **Orchestra** per creare l'armonia perfetta per il tuo evento. La musica è la nostra passione, la tua soddisfazione è la nostra missione.
              </Paragraph>
          </LineClamper>
          </FirstHalfContainer>
          <SecondHalfContainer>
            <SquareImage src={Images.tmp.banner} />
          </SecondHalfContainer>

          </div>
        
        </Popup>} */}
      <Header />
      <MainContainer $bgColor={"white"} >
      <WholeScreenInnerContainer style={{ position: 'relative' }}>
        <FirstHalfContainer>
              <div style={{display:'flex',gap:10, flexDirection:'column'}}>
            
            <Paragraph $textColor={'#000'} style={{ margin: '0' }}>
                <div style={{padding:"10px", paddingTop:"20px"}}>
                 <Title1 style={{color:'#37245A'}}>ORCHESTRA S.R.L.</Title1>
                </div>
               
                </Paragraph>
                <div style={{ padding:10, borderRadius:10}}>
                  <Paragraph $textColor={'#000'} style={{ margin: '0' }}>
                  Via Uberto Visconti di Modrone 7 - 20122 Milano (MI - IT)
                  </Paragraph>
                  <Paragraph $textColor={'#000'} style={{ margin: '0' }}>
                  PEC: <a style={{fontSize:17, color:'#37245A'}} href={`mailto:orchestrasrl1@pec.it`}>orchestrasrl1@pec.it </a>
                  
                  </Paragraph>
                  <Paragraph $textColor={'#000'} style={{ margin: '0' }}>
                  Email: <a style={{fontSize:17, color:'#37245A'}} href={`mailto:info@orchestra.srl`}>info@orchestra.srl </a>
                 
                  
                  </Paragraph>
                </div>
                <div style={{padding:10, borderRadius:10}}>
                  <Paragraph $textColor={'#000'} style={{ marginTop: '10px' }}>
                  <span style={{fontWeight:'bold'}}>Direzione artistica</span>
                  </Paragraph>
                  <Paragraph $textColor={'#000'} style={{ margin: '0' }}>
                  Tel +39 3487320111
                  </Paragraph>
                  <Paragraph $textColor={'#000'} style={{ margin: '0' }}>
                  Email: <a style={{fontSize:17, color:'#37245A'}} href={`mailto:direzione.artistica@orchestra.srl`}>direzione.artistica@orchestra.srl </a>
                 
                  
                  </Paragraph>
                </div>
                <div style={{ padding:10, borderRadius:10}}>
                <Paragraph $textColor={'#000'} style={{ marginTop: '10px' }}>

                <span style={{fontWeight:'bold'}}>Amministrazione</span>
                </Paragraph>
                <Paragraph $textColor={'#000'} style={{ margin: '0' }}>
                Tel: + 39 3481144877
                </Paragraph>
                <Paragraph $textColor={'#000'} style={{ margin: '0' }}>
                Email: <a style={{fontSize:17, color:'#37245A'}} href={`mailto:admin@orchestra.srl`}>admin@orchestra.srl </a>
               
              </Paragraph>
              </div>
              </div>
        </FirstHalfContainer>
        <SecondHalfContainer>
          <SquareImage src={Images.tmp.contatti}  />
        </SecondHalfContainer>

            
      
          </WholeScreenInnerContainer>

      </MainContainer>
      
        <Footer />
     


      
        

          
         
          
         

      
     

      

      

      

     
    </>
  );
}

export default Contacts;
