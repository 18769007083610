import { AWS } from "../config/URLs";
import axios from 'axios';

const uploadFile = (file, fileName,jwtToken, errF: (error) => void, succF: () => void, cv = false) => {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwtToken,
      }),
      body: JSON.stringify({
        fileName: fileName,
        fileType: file.type,
      }),
    };
  
    console.log("Uploading...")
  
   
    fetch(AWS + "/presign-request", requestObject)
      .then((response) => {
        if (response.ok) return response.json();
        else {
          const error = response.status;
          return Promise.reject(error);
        }
      })
      .then((res) => {
        let url = res.signedUrl

        

        fetch(url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type
          },
        })
          .then((response) => {
            if (response.ok) {succF(); return; }
            else {
              
              const error = response.status;
              return Promise.reject(error);
            }
          })
          .catch((err) => errF("Error on fetch PUT: " + JSON.stringify(err)));
  
        
        // UTILIZZO AXIOS PER LA RICHIESTA PUT


  
        
  
          // console.log("Heeere...")
          // // Utilizzando fetch per la richiesta PUT
          // axios
          //   .put(url, file, {
          //     headers: { "Content-Type": file.type },
          //     timeout: 600000,
          //   })
          //   .then((data) => {
          //     succF();
          //   })
          //   .catch((err) => errF("Error on axios put: " + JSON.stringify(err)));
          
        
        
        
      
      })
      .catch((err) => errF("Error while uploading the video: " + err));
  };

  export {uploadFile}