export default class Announcement {



  
    constructor(id, title, creationDate, location, date, genres,instruments,budget,description,price, group, groupType,nMusicians, limit = 5, admin = false) {
        this.id = id;
        this.title = title;
        this.creationDate = creationDate;
        this.location = location;
        this.date = date;
        this.genres = genres;
        this.instruments = instruments;
        this.budget = budget; 
        this.description = description;
        this.price = price;
        this.group = group;
        this.groupType = groupType;
        this.nMusicians = nMusicians;
        this.limit = limit;
        this.admin = admin;
        

    }

   

    

  }