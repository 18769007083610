import LargeTextInput from '../../../inputs/LargeTextInput/LargeTextInput';
import { forwardRef, useEffect, useState} from 'react';
import {
  InputWithError,
  StyledForm,
} from '../PersonalDataForm/PersonalDataFormElements';
import { useForm } from 'react-hook-form';
import { ErrorText, } from '../../texts/TextsElements';
import { FullSizeContainer } from '../PaymentMethods/PaymentMethodsFormElements';
import { CharCounter } from './AboutYouFormElements';
const MAX_CHARS = 500;

const AboutYouForm = forwardRef(
  (
    {
      tmpUserRef,
      submitForm,
      submitRef,
      isMusicianRole,
      formWidth,
      resetForm,
      fullWidth,
    },
    ref
  ) => {
    const [charCount, setCharCount] = useState(0);

    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
    } = useForm({
      defaultValues: {
        aboutYou: '',
      },
      mode: 'all',
    });

    useEffect(() => {
      if (tmpUserRef?.current) {
        if (tmpUserRef.current.aboutYou) {
          setValue('aboutYou', tmpUserRef.current.aboutYou);
          setCharCount(tmpUserRef.current.aboutYou.length);
        }
      }
    }, [setValue, tmpUserRef, resetForm]);

    const handleInputChange = (event) => {
      console.log("event",event.target.value);
      const charCount = event.target.value.length;
      if (charCount <= MAX_CHARS) {
        setCharCount(charCount);
      }
    };

    return (
      <StyledForm
        ref={ref}
        onSubmit={handleSubmit(submitForm)}
        $width={formWidth}
      >
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <FullSizeContainer $fullWidth={fullWidth}>
              <InputWithError>
                <LargeTextInput
                  placeholder='Dicci qualcosa su di te...'
                  {...register('aboutYou', {
                    required: 'La descrizione è obbligatoria',
                  })}
                  $active={!!errors?.aboutYou?.message}
                  textAreaWidth={formWidth}
                  maxLength={MAX_CHARS}
                  onChange={handleInputChange}
                />
                <CharCounter>
                  {charCount}/{MAX_CHARS}
                </CharCounter>

                {errors.aboutYou && (
                  <ErrorText>{errors.aboutYou.message}</ErrorText>
                )}
              </InputWithError>
            </FullSizeContainer>
            <button ref={submitRef} type='submit' style={{ display: 'none' }} />
          </div>
        </>
      </StyledForm>
    );
  }
);

export default AboutYouForm;
