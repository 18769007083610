import { useClerk, useSignUp } from "@clerk/clerk-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../components/inputs/TextInput/TextInput";
import TitledContainer from "../../components/presentationals/Containers/TitledContainer/TitledContainer";
import DeletableButton from "../../components/presentationals/buttons/DeletableButton/DeletableButton";
import { ErrorText } from "../../components/presentationals/texts/TextsElements";
import { MUSICIAN } from "../../config/profile/Roles";
import Musician from "../../models/Musician";
import Planner from "../../models/Planner";
import { setCookie } from "../../utils/Cookies";
import Loader from "../../utils/Loader";
import SecondaryButton from "../../components/presentationals/buttons/SecondaryButton/SecondaryButton";

export default function VerifyEmail() {
  const [code, setCode] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { isLoaded, signUp, setActive } = useSignUp();

  
  const searchParams = window.location.search;
  const params = new URLSearchParams(searchParams);
  // Ottieni il valore del parametro 'email' dalla query string.
  let type = params.get("type");

  const onPressSendCode = async (e) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }

    try {
      setIsLoading(true);
      const completeSignUp = await signUp.prepareVerification({
        strategy:'email_code'
      });

      console.log("Complete signup")
      console.log(completeSignUp)
      setIsLoading(false);

      // if (completeSignUp.status !== "complete") {
      //   /*  investigate the response, to see if there was an error
      //        or if the user needs to complete more steps.*/
      //   console.log(JSON.stringify(completeSignUp, null, 2));
      // }
      // if (completeSignUp.status === "complete") {
      //   await setActive({ session: completeSignUp.createdSessionId });

      //   if (type === MUSICIAN) {
      //     let u = new Musician(
      //       completeSignUp.createdUserId,
      //       completeSignUp.emailAddress
      //     );
      //     u.id = completeSignUp.createdUserId;

      //     setCookie("tmpMusician", JSON.stringify(u));

      //     // await newMusician(
      //     //   u,
      //     //   t,
      //     //   (err) => {
      //     //     setIsLoading(false);
      //     //     setError(err?.errors?.[0]?.message);
      //     //   },
      //     //   (succ) => {
      //     //     console.log("Succ in creating user: ", succ);
      //     //     setIsLoading(false);

      //     //     //navigate("/onboarding");
      //     //   }
      //     // );
      //   } else {
      //     let u = new Planner(
      //       completeSignUp.createdUserId,
      //       completeSignUp.emailAddress
      //     );
      //     u.id = completeSignUp.createdUserId;

      //     setCookie("tmpPlanner", JSON.stringify(u));
      //     // await newPlanner(
      //     //   u,
      //     //   t,
      //     //   (err) => {
      //     //     setIsLoading(false);
      //     //     setError(err?.errors?.[0]?.message);
      //     //   },
      //     //   (succ) => {
      //     //     console.log("Succ in creating user: ", succ);
      //     //     setIsLoading(false);

      //     //     //navigate("/onboarding");
      //     //   }
      //     // );
      //   }
      // }
    } catch (err) {
      setIsLoading(false);
      setError(err?.errors?.[0]?.longMessage);
    }
  };

  const onPressVerify = async (e) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }

    try {
      setIsLoading(true);
      const completeSignUp = await signUp.attemptEmailAddressVerification({
        code,
      });

      if (completeSignUp.status !== "complete") {
        /*  investigate the response, to see if there was an error
             or if the user needs to complete more steps.*/
        console.log(JSON.stringify(completeSignUp, null, 2));
      }
      if (completeSignUp.status === "complete") {
        await setActive({ session: completeSignUp.createdSessionId });

        if (type === MUSICIAN) {
          let u = new Musician(
            completeSignUp.createdUserId,
            completeSignUp.emailAddress
          );
          u.id = completeSignUp.createdUserId;

          setCookie("tmpMusician", JSON.stringify(u));

          // await newMusician(
          //   u,
          //   t,
          //   (err) => {
          //     setIsLoading(false);
          //     setError(err?.errors?.[0]?.message);
          //   },
          //   (succ) => {
          //     console.log("Succ in creating user: ", succ);
          //     setIsLoading(false);

          //     //navigate("/onboarding");
          //   }
          // );
        } else {
          let u = new Planner(
            completeSignUp.createdUserId,
            completeSignUp.emailAddress
          );
          u.id = completeSignUp.createdUserId;

          setCookie("tmpPlanner", JSON.stringify(u));
          // await newPlanner(
          //   u,
          //   t,
          //   (err) => {
          //     setIsLoading(false);
          //     setError(err?.errors?.[0]?.message);
          //   },
          //   (succ) => {
          //     console.log("Succ in creating user: ", succ);
          //     setIsLoading(false);

          //     //navigate("/onboarding");
          //   }
          // );
        }
      }
    } catch (err) {
      setIsLoading(false);
      setError(err?.errors?.[0]?.longMessage);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <form onSubmit={onPressVerify}>
      <TitledContainer
        title="Verifica la tua email"
        subtitle={signUp.emailAddress?"Una mail con il codice è stata mandata alla tua mail "+signUp.emailAddress:""}
        centered="center"
        fullWidth
        textAlign={"center"}
      >
        <Input
          value={code}
          placeholder="Codice..."
          onChange={(e) => setCode(e.target.value)}
        />

        {error && <ErrorText style={{ textAlign: "left" }}>{error}</ErrorText>}
        <div style={{flexDirection:'column', display:'flex', justifyContent:'center', alignItems:'center'}}>
          <DeletableButton type="submit">Verifica</DeletableButton>
          <SecondaryButton onClick={onPressSendCode}>Invia di nuovo il codice</SecondaryButton>
        </div>
      </TitledContainer>
    </form>
  );
}
