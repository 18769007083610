import styled from 'styled-components';

export const InputContainer = styled.div`
  display: ${({ $display }) => ($display ? $display : 'flex')};
  align-items: center;
  border-radius: 20px;
  background-color: #f0efff;
  width: 100%;
  min-width: 200px;
  height: 50px;
  font-size: 16px;
  position: relative;
  margin: 10px 0;
`;

export const InputBox = styled.input`
  background-color: #f0efff;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: normal;
  text-align: left;
  color: ${({ $color }) => ($color ? $color : '#a7a3ff')};
  outline: none;
  border: ${({ $border }) => ($border ? $border : '1px solid transparent')};
  &::placeholder {
    color: ${({ $color }) => ($color ? $color : '#a7a3ff')};
  }
  &:focus {
    border: ${({ $border }) => ($border ? $border : '1px solid #a7a3ff')};
  }
  ${({ $active }) => $active && `
    border: 1px solid #bf1650;
    background: #FFE5E5;
    &::placeholder {
      color: #F00;
    }
  `}
`;
