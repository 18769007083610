import terminiCondizioni from "../../assets/terminiCondizioni.pdf";


function TerminiCondizioni() {

 

  return (
    <div style={{display:'flex',width:"100%", height:"100vh",}}>
      <object data={terminiCondizioni} type="application/pdf" width="100%" height="100%">
          <p>Alternative text - include a link <a href="https://orchestra.srl/static/media/orchestra.3d1aa8b01a009a8756a2.pdf">to the PDF!</a></p>
      </object>
      
    </div>
  );
}

export default TerminiCondizioni;
